import React from 'react'
import MenuBar from './MenuBar'
import Sidebar from './Sidebar'
import "../css/dnMedia.css"
function LayoutWrapper({ layoutIdentifier = "", ...props }) {
    switch (layoutIdentifier) {
        case "DASHBOARD":
            return (

                <div className="row g-0">
                    <div className={`col-lg-2 smNone`}>
                        <Sidebar />
                    </div>
                    <div className='col-lg-10'>
                        <MenuBar />
                        {props.children}
                    </div>
                </div>

            )

        default:
            return (
                <div> {props.children}</div>
            )
    }
}

export default LayoutWrapper