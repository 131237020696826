import React from 'react'

function LinkdinIcon() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.201 0.76001H2.79727C1.67102 0.76001 0.759766 1.67126 0.759766 2.79751V21.2025C0.759766 22.3275 1.67102 23.2413 2.79727 23.2413H21.2023C22.3273 23.2413 23.241 22.3288 23.241 21.2025V2.79751C23.2398 1.67001 22.3273 0.76001 21.201 0.76001ZM8.27227 5.13126C9.01852 5.13126 9.64477 5.72126 9.64477 6.47876C9.64477 7.24876 9.01852 7.82876 8.27227 7.82876V7.85626C7.51727 7.85626 6.90977 7.24876 6.90977 6.49626C6.90977 5.74001 7.51727 5.13126 8.27227 5.13126ZM17.4648 18.0713C16.0748 18.0713 15.401 17.2438 15.401 16.1138C15.401 15.7888 15.4423 15.4475 15.5435 15.0988L16.2035 12.7163C16.2885 12.4563 16.311 12.21 16.311 11.99C16.311 11.2213 15.8423 10.76 15.0973 10.76C14.146 10.76 13.5223 11.4388 13.1973 12.7575L11.906 17.94H9.64227L10.051 16.3113C9.38352 17.4025 8.46227 18.0763 7.32227 18.0763C5.94477 18.0763 5.30102 17.2863 5.30102 16.0963C5.30852 15.7563 5.34477 15.4138 5.42102 15.0825L6.45352 10.875H4.85602L5.34102 9.08751H9.18477L7.66477 15.0925C7.56477 15.4788 7.52727 15.7913 7.52727 16.015C7.52727 16.3988 7.71227 16.51 8.00852 16.58C8.19102 16.6175 9.62727 16.5938 10.406 14.86L11.4035 10.875H9.78602L10.2748 9.12001H13.7585L13.3098 11.1413C13.9148 10.0175 15.1248 8.93876 16.3173 8.93876C17.5823 8.93876 18.636 9.84876 18.636 11.5775C18.636 12.0775 18.5573 12.5725 18.411 13.0525L17.761 15.375C17.7073 15.61 17.6673 15.8088 17.6673 15.99C17.6673 16.3975 17.8373 16.595 18.1273 16.595C18.4248 16.595 18.8185 16.3688 19.251 15.13L20.136 15.47C19.6185 17.3113 18.6585 18.0713 17.4648 18.0713Z" fill="#395061" />
        </svg>
    )
}

export default LinkdinIcon