import React from 'react'

function AttachIcon({ width, height, color }) {
    return (
        <svg width="20" height="20" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9502 3.05568C23.5679 1.67793 21.6959 0.904297 19.7443 0.904297C17.7926 0.904297 15.9206 1.67793 14.5383 3.05568L2.42303 15.1701C1.93178 15.6534 1.54108 16.2292 1.27347 16.8643C1.00586 17.4994 0.866632 18.1812 0.863813 18.8703C0.860995 19.5595 0.994644 20.2424 1.25705 20.8796C1.51946 21.5169 1.90543 22.0959 2.39271 22.5832C2.87999 23.0705 3.45893 23.4566 4.09615 23.7191C4.73336 23.9816 5.41624 24.1153 6.10539 24.1126C6.79455 24.1098 7.47634 23.9707 8.11146 23.7032C8.74657 23.4356 9.32244 23.045 9.80584 22.5538L21.1646 11.196C21.7343 10.6144 22.0533 9.83275 22.0533 9.01865C22.0533 8.20456 21.7343 7.42288 21.1646 6.84131C20.5778 6.28166 19.7981 5.96943 18.9872 5.96943C18.1764 5.96943 17.3967 6.28166 16.8099 6.84131L6.20865 17.4426C6.04687 17.6207 5.9598 17.8543 5.96547 18.0949C5.97113 18.3355 6.0691 18.5647 6.23909 18.735C6.40908 18.9054 6.63807 19.0038 6.87864 19.01C7.11922 19.0162 7.35296 18.9296 7.53146 18.7682L18.1365 8.16787C18.3655 7.94831 18.6705 7.82572 18.9877 7.82572C19.305 7.82572 19.61 7.94831 19.839 8.16787C20.0611 8.39552 20.1855 8.70102 20.1855 9.01912C20.1855 9.33723 20.0611 9.64272 19.839 9.87037L8.48021 21.2282C7.85264 21.8556 7.00153 22.2081 6.1141 22.208C5.22667 22.2079 4.37563 21.8553 3.74818 21.2277C3.12074 20.6002 2.76829 19.749 2.76838 18.8616C2.76847 17.9742 3.12108 17.1231 3.74865 16.4957L15.864 4.38131C16.8979 3.3758 18.2862 2.81772 19.7284 2.82779C21.1706 2.83785 22.551 3.41524 23.5708 4.43509C24.5906 5.45493 25.168 6.83524 25.1781 8.27748C25.1882 9.71972 24.6301 11.108 23.6246 12.1419L12.2668 23.4998C12.096 23.6766 12.0015 23.9134 12.0036 24.1592C12.0058 24.405 12.1044 24.6401 12.2782 24.814C12.452 24.9878 12.6872 25.0864 12.933 25.0885C13.1788 25.0906 13.4156 24.9962 13.5924 24.8254L24.9502 13.4685C26.3289 12.0866 27.1032 10.2142 27.1032 8.26209C27.1032 6.31001 26.3289 4.43762 24.9502 3.05568Z" fill="#395061" />
        </svg>
    )
}

export default AttachIcon