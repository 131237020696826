
import axiosFetch from '../../../axios/AxiosInterceptors.js';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { categoryFilterRecoilState, courseFilterRecoilState, dateFilterRecoilState, searchCourseRecoilState, subCategoryFilterRecoilState } from 'recoil/atoms/atomsStore';
import LiveCourseActiveLayout from '../inReview/InreviewLayoutWrapper/LiveCourseActiveLayout';
import RecordedCourseActiveLayout from '../inReview/InreviewLayoutWrapper/RecordedCourseActiveLayout'
import ReactPaginate from 'react-paginate';
import Paginate from 'components/Paginate.js';

function ActiveList() {
    const [courseData, setCourseData] = useState(null);
    const courseTypeParam = useRecoilValue(courseFilterRecoilState);
    const searchCourseParam = useRecoilValue(searchCourseRecoilState);
    const categoryParam = useRecoilValue(categoryFilterRecoilState);
    const subCategoryParam = useRecoilValue(subCategoryFilterRecoilState);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const dateFilter = useRecoilValue(dateFilterRecoilState);
    useEffect(() => {
        fetchCourse();
    }, [courseTypeParam.triggerChangeKey, searchCourseParam.value, categoryParam.triggerChangeKey, subCategoryParam.triggerChangeKey, dateFilter.triggerChangeKey])
    const fetchCourse = async () => {
        try {
            const resp = await axiosFetch(API.ADMIN_SEARCH + `?courseStatus=ACTIVE&courseType=${courseTypeParam?.value}&courseName=${searchCourseParam?.value}&catogory=${categoryParam.value}&subCategory=${subCategoryParam.value}&fromDate=${dateFilter.fromDate}&toDate=${dateFilter.toDate}`);
            setCourseData(resp.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        if (courseData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = courseData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(courseData.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [courseData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % courseData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    if (courseTypeParam.value == "ALL") {
        return (<>
            <JsonViewer data={courseData} />

            {
                !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                    <>
                        {
                            currentItems.map((cData, i) => {
                                return cData?.isLive
                                    ? <LiveCourseActiveLayout fetchCourse={fetchCourse} data={cData} key={cData?.courseCode + i} />
                                    : <RecordedCourseActiveLayout fetchCourse={fetchCourse} courseData={cData} key={cData?.courseCode + i} />


                            })
                        }
                    </>
                    : <h6 className='text-muted text-center'>No course available </h6>
            }
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
        </>)
    }
    if (courseTypeParam.value == "LIVECOURSE") {
        return (<>
            <JsonViewer data={courseData} />
            {
                !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                    <>
                        {
                            currentItems.map((cData, i) => (
                                <LiveCourseActiveLayout data={cData} key={cData?.courseCode + i} />
                            ))
                        }
                    </>
                    : <h6 className='text-muted text-center'>No course available </h6>
            }
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
        </>)
    }
    if (courseTypeParam.value == "COURSE") {
        return (
            <>
                <JsonViewer data={courseData} />
                {
                    !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                        <>
                            {
                                currentItems.map((cData, i) => (
                                    <RecordedCourseActiveLayout courseData={cData} key={cData?.courseCode + i} />
                                ))
                            }
                        </>
                        : <h6 className='text-muted text-center'>No course available </h6>
                }
                <div className="row">
                    <div className="col-lg-8">
                        <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                    </div>
                    <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                        Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select> courses per page
                    </div>
                </div>
            </>
        )
    }
}

export default ActiveList

