import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import { useState } from 'react';
import { useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import { unstable_HistoryRouter, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowLeft from 'svgIcons/ArrowLeft';
import AssessmentIcon from 'svgIcons/AssessmentIcon';
import PlayIcon from 'svgIcons/PlayIcon';
import RightArrowIcon from 'svgIcons/RightArrowIcon';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';
import RejectApproveHeader from './RejectApproveHeader';

function ClikedChapter() {
    const [lessonData, setLessonData] = useState(null)
    const lsnData = getLocalStorage("uploadedVideoClickedChapter");

    useEffect(() => {
        setLessonData(lsnData?.lessonList)
    }, [])
    const navigate = useNavigate();
    // const params = "active=uploadTab";
    const back = () => {
        navigate(-1)
    }
    const navigateLsn = (Ldata) => {
        setLocalStorage({ key: "uploadedVideoClickedLesson", value: Ldata })
        if (Ldata?.isAssesment) {
            navigate("/all-courses/in-review/uploaded/chapter/assessment")
        } else {
            navigate({
                pathname: "/all-courses/in-review/uploaded/chapter/lesson",
                // search: `?${createSearchParams(params)}`
            })
        }
    }
    return (
        <div className="px-4">
            <RejectApproveHeader />
            <div className="py-3"></div>
            <div className="bg-white rounded-2 py-5 px-5 mb-4">
                <JsonViewer data={lsnData} />
                <h6 className='text-dark d-inline-block' role={"button"} onClick={back}><ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} /> {lsnData?.chapterName}</h6>
                <div className="py-3"></div>
                <ul className={`${lessonStyles.list} list-unstyled`}>
                    {
                        !lessonData ? <Loader className="text-prime my-3" center={true} /> : lessonData?.length ?
                            <>
                                {
                                    lessonData?.map((cData, i) => {
                                        return cData?.isAssesment ?
                                            //Assessment View
                                            <li key={cData._id} className='fz-15px fw-500 text-dark' onClick={() => navigateLsn(cData)} role="button">
                                                <Stack direction="horizontal" gap={1}>
                                                    <div className='text-capitalize'> <AssessmentIcon width={29} /> {cData?.lessonName}</div>
                                                    <div className="ms-auto"><RightArrowIcon width={8} /></div>
                                                </Stack>
                                            </li> :
                                            //Lesson View
                                            <li className='fz-15px fw-500 text-dark' role={"button"} key={cData._id + i} onClick={() => navigateLsn(cData)}>
                                                <Stack direction="horizontal" gap={1}>
                                                    <div className='text-capitalize'><PlayIcon width={29} /> {cData?.lessonName}</div>
                                                    <div className="ms-auto"><RightArrowIcon width={8} /></div>
                                                </Stack>
                                            </li>
                                    })

                                }
                            </>
                            : <h6 className='text-muted text-center'>No data available </h6>
                    }
                </ul>
            </div>
        </div>
    )
}

export default ClikedChapter