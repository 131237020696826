import JsonViewer from 'components/JsonViewer';
import Paginate from 'components/Paginate';
import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { confirmAlertSuccess } from 'utils/confirmAlert';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import teacherStyles from "../../../../moduleCss/teacher.module.css";

function DeactiveTable({ onSearch }) {
    const [deActiveTeachersData, setDeActiveTeachersData] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [searchData, setSearchData] = useState("");

    const onActive = async (studentId) => {
        const isTrue = await confirmAlertSuccess({ title: "Are you sure you want to Activate?", cancelText: "Cancel", confirmText: "Activate" });
        if (isTrue.isConfirmed) {
            activateStudent(studentId);
        }
    }

    const activateStudent = async (studentId) => {
        try {
            const jsonData = {
                userType: "ADMIN",
                studentId: studentId
            }
            const resp = await axiosFetch.post(API.ACTIVATE_STUDENT, jsonData);

            if (resp.message === "Student Activated") {
                const updatedData = deActiveTeachersData.filter(learner => learner._id != studentId);
                setDeActiveTeachersData(updatedData)
            }
        } catch (error) {


        }
    }

    //search effect
    useEffect(() => {
        if (onSearch) {
            const dataArry = searchData
            const filteredData = dataArry.filter((item) =>
                item.fullName.toLowerCase().includes(onSearch.toLowerCase())
            );
            setDeActiveTeachersData(filteredData)
        } else {
            setDeActiveTeachersData(searchData)
        }
    }, [onSearch])
    useEffect(() => {
        getDeActiveTeachers();

    }, [])
    const getDeActiveTeachers = async () => {
        try {

            const jsonData = {
                userType: "ADMIN"
            }
            const resp = await axiosFetch.post(API.GET_DEACTIVE_LEARNERS, jsonData);

            // const resp = await axiosFetch(API.GET_DEACTIVE_TEACHERS);
            setDeActiveTeachersData(resp.data);
            setSearchData(resp.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        if (deActiveTeachersData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = deActiveTeachersData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(deActiveTeachersData.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [deActiveTeachersData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % deActiveTeachersData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (


        <div className="table-responsive">
            <table className="table table-borderless align-middle">
                <thead>
                    <tr>
                        <th className='fw-500 text-muted fz-15px'>Joined Date</th>
                        <th className='fw-500 text-muted fz-15px'>Student Name</th>
                        <th className='fw-500 text-muted fz-15px'>Email</th>
                        <th className='fw-500 text-muted fz-15px'>Total Course</th>
                        <th className='fw-500 text-muted fz-15px'>Total Buy</th>
                        <th className='fw-500 text-muted fz-15px'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !currentItems ? <tr className='text-center'>
                            <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                        </tr> : currentItems?.length ?

                            currentItems?.map((tData, i) => (
                                <tr key={tData?._id}>


                                    <td className='fz-15px'>{moment(tData?.joinedDate).format('DD MMMM , YYYY')}</td>
                                    {/* <td><img src="https://www.efilecabinet.com/wp-content/uploads/2019/05/g2-testimonial-male.jpg" className={`${teacherStyles.tImg}`} />{fullName}</td> */}
                                    <td className='fz-15px'>{tData?.fullName}</td>
                                    <td className='fz-15px'>{tData?.email}</td>
                                    <td className='fz-15px'>{tData?.totalCourses}</td>
                                    <td className='fz-15px'>₹{tData?.totalBuy}</td>
                                    {/* tData?.noOfCourses.toString().padStart(2, "0") */}
                                    {/* <td className='fz-15px'>333</td> */}
                                    <td><span className={`${teacherStyles.bgGreen} ${teacherStyles.status} text-white`} onClick={() => onActive(tData?._id)}>Activate</span></td>
                                    <td><Link to={`/learners/view-Detail/${tData?._id}`}><span className={`${teacherStyles.status} fz-15px bg-prime text-white`}>View </span></Link></td>

                                </tr>
                            ))

                            :
                            <tr className='text-center'>
                                <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                            </tr>
                    }

                </tbody>
            </table>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
            <JsonViewer data={deActiveTeachersData} />
        </div>

    )
}

export default DeactiveTable