import axiosFetch from '../../../../../../axios/AxiosInterceptors'
import Loader from 'components/Loader'
import VideoPlayer from 'components/VideoPlayer'
import { API } from 'constants/apiEndPoints'
import React, { useState } from 'react'
import { Stack } from 'react-bootstrap'
import { toast, Toaster } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import { liveCourseOverviewRecoilState } from 'recoil/atoms/atomsStore'
import { confirmAlert } from 'utils/confirmAlert'
import { getLocalStorage,setLocalStorage } from 'utils/localStorage'
import liveCourseOverviewStyle from '../../../../../../moduleCss/duration.module.css'
function LiveCourseDuration() {
    const liveCourseOverviewRecoilAtom = useRecoilValue(liveCourseOverviewRecoilState);
    let clickedCourse = getLocalStorage("clickedCourse");   
    const [activedisable,setActive]=useState(clickedCourse.inReviewCourseDurationCount==0 ?true:false)
    const onRejectLiveCourse = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to reject this course duration?", cancelText: "Cancel", confirmText: "Reject" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    courseCode: clickedCourse?.courseCode,
                }
                const resp = await axiosFetch.post(API.LIVE_COURSE_DURATION_REJECT, jsonData);
                toast.success('Course duration rejected successfully!');


            } catch (error) {

            }

        }
    }    
    const fetchCourse = async () => {
        try {
            const resp = await axiosFetch(API.ADMIN_SEARCH + `?courseStatus=INREVIEW&courseCode=${clickedCourse.courseCode}`);
            setLocalStorage({ key: "clickedCourse", value: resp?.data[0] })
            clickedCourse = getLocalStorage("clickedCourse");
            console.log('clickedCourseclickedCourse',clickedCourse)
            if(clickedCourse.inReviewAssessmentCount != 0||clickedCourse.inReviewLessonCount != 0||clickedCourse.inReviewLiveAssessmentCount != 0||clickedCourse.inReviewLessonFAQCount!=0||clickedCourse.inReviewCourseDurationCount!=0){
            return {status:500,message:" error"}
            }
            else{
                return {status:200,message:"no error"}
            }
        } catch (error) {

        }
    }
    const onActivateLiveCourse = async () => {
        try {
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
            }
            const resp1 = await axiosFetch(API.ADMIN_SEARCH + `?courseStatus=INREVIEW&courseCode=${clickedCourse.courseCode}`);
            setLocalStorage({ key: "clickedCourse", value: resp1?.data[0] })
            clickedCourse = getLocalStorage("clickedCourse");
            if(clickedCourse.inReviewCourseDurationCount==0){
                setActive(true)
            }
            const resp = await axiosFetch.post(API.LIVE_COURSE_DURATION_APPROVE, jsonData);
            toast.success('Course duration approved successfully!');

        } catch (error) {

        }

    }
    return (
        <div className='row pt-3'>
            <Stack direction="horizontal" gap={4} className="px-5">
                <div className="textTomato fz-14px fw-500 ms-auto" onClick={onRejectLiveCourse} role={"button"}>Reject</div>
                <div
                   className={`bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1 ${activedisable ? 'disabled' : ''}`}
                    style={{ opacity: activedisable ? 0.6 : 1, cursor: activedisable ? 'not-allowed' : 'pointer' }}
                    onClick={!activedisable ? onActivateLiveCourse : undefined}
                    role="button"
                >
                Approve
                </div>   
            </Stack>
            <p className='fw-500 fz-15px mb-3'><span className=' text-muted'>Course Duration:</span> {liveCourseOverviewRecoilAtom[0]?.courseDuration}</p>
            <div className=' d-flex mb-3'>
                <p className='fw-500 pe-4 fz-15px'><span className=' text-muted'>From:</span>  {liveCourseOverviewRecoilAtom[0]?.fromTime}</p>
                <p className='fw-500 fz-15px'><span className=' text-muted'>To:</span> {liveCourseOverviewRecoilAtom[0]?.toTime}</p>
            </div>
            <p className='fw-500 fz-15px mb-3 text-muted'>Class Time</p>


            <div className="col-lg-6">
                {
                    !liveCourseOverviewRecoilAtom ? <Loader className="text-prime my-3" center={true} /> : liveCourseOverviewRecoilAtom?.length ?
                        liveCourseOverviewRecoilAtom[0]?.classTime.slice(0, 4).map((item, index, array) => (
                            <div key={item?._id} className="row mb-3">
                                <div className='col-lg-3 text-capitalize my-auto fw-500 fz-15px'>{item?.day}</div>
                                <div className={`${liveCourseOverviewStyle.sTime} text-center fw-500 fz-15px col-lg-3`}>{item?.startTime}</div>
                                <div className='col-lg-2 text-center fw-500 fz-15px my-auto'>to</div>
                                <div className={`${liveCourseOverviewStyle.sTime} text-center fw-500 fz-15px col-lg-3`}>{item?.endTime}</div>
                            </div>
                        ))
                        : "No data"

                }
            </div>
            <div className="col-lg-6">
                <Toaster position="top-right"
                    toastOptions={{
                        className: 'fz-13px fw-500'
                    }} />
                {
                    !liveCourseOverviewRecoilAtom ? <Loader className="text-prime my-3" center={true} /> : liveCourseOverviewRecoilAtom?.length ?
                        liveCourseOverviewRecoilAtom[0]?.classTime.slice(4, 7).map((item, index, array) => (
                            <div key={item?._id} className="row mb-3">
                                <div className='col-lg-3 text-capitalize my-auto fw-500 fz-15px'>{item?.day}</div>
                                <div className={`${liveCourseOverviewStyle.sTime} text-center fw-500 fz-15px col-lg-3`}>{item?.startTime}</div>
                                <div className='col-lg-2 text-center fw-500 fz-15px my-auto'>to</div>
                                <div className={`${liveCourseOverviewStyle.sTime} text-center fw-500 fz-15px col-lg-3`}>{item?.endTime}</div>
                            </div>
                        ))
                        : "No data"

                }
            </div>



        </div>
    )
}

export default LiveCourseDuration