import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation, useParams } from 'react-router-dom';
import SidebarLinks from './SidebarLinks';
const brkPoint = "lg";
function MenuBar() {
    const location = useLocation();
    const [title, setTitle] = useState("Admin Dashboard");

    useEffect(() => {
        changeTitle(location.pathname)
    }, [location])
    const params = useParams();

    const changeTitle = (path) => {
        if (path.startsWith("/learners-list/")) {
            setTitle("Student Courses");
            return;
        }
        switch (path) {

            case "/transaction-ticket-messages":
                setTitle("Manage Withdraw")
                break;
            case "/ticket-message":
                setTitle("Course")
                break;
            case "/ticket-message/assessment":
                setTitle("Assessment")
                break;
            case "/teachers":
                setTitle("Instructor")
                break;
            case "/all-courses":
                setTitle("All Courses")
                break;
            case "/independent-assessment":
                setTitle("Assessment")
                break;
            case "/independent-assessment/view-approve":
                setTitle("Assessment")
                break;
            case `/independent-assessment/view-approve/${params.assessmentCode}`:
                setTitle("Assessment")
                break;
            case "/manage-categories":
                setTitle("Manage Category")
                break;
            case "/manage-fees":
                setTitle("Manage Fees")
                break;
            case "/all-courses/in-review/view-approve":
                setTitle("All Course")
                break;
            case "/learners":
                setTitle("Student")
                break;
            case "/ticket-management":
                setTitle("Ticket Management")
                break;
            case `/teacher/earn-history/${params.teacherId}`:
                setTitle("Earn History")
                break;
            case `/teachers/view-Detail/${params.teacherId}`:
                setTitle("Instructor")
                break;
            case `/teacher/instructor-payout-history/${params.teacherId}`:
                setTitle("Payout History")
                break;
            case `/learners/view-Detail/${params.teacherId}`:
                setTitle("Student")
                break;
            case `/manage-withdraw`:
                setTitle("Manage Withdraw")
                break;
            case `/manage-discount`:
                setTitle("Manage Discounts")
                break;
            case `/discount-ticket-messages`:
                setTitle("Manage Discounts")
                break;  

            case "/view-buying-history":
                setTitle("Buying History")
                break;
            case "/learners-list":
                setTitle("Learners List")
                break;
            case "/manage-categories/create-categories":
                setTitle("Create Category")
                break;
            case "/edit-category":
                setTitle("Edit Category")
                break;
            default:
                setTitle("Admin Dashboard")
                break;


        }
    }

    return (
        <>
            <div className="container-lg">
                <Navbar key={"lg"} bg="transparent" expand={brkPoint} className="mb-3">
                    <Container fluid>
                        <Navbar.Brand href="#" className='fw-500'>{title}</Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${brkPoint}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${brkPoint}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${brkPoint}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${brkPoint}`}>

                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3 lgNone">
                                    {/* ---Mobile menu------ */}
                                    <SidebarLinks />
                                </Nav>

                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default MenuBar