import moment from 'moment'
import React from 'react'
import teacherStyles from "../../../moduleCss/teacher.module.css"

function LearnersTable({ data }) {
    return (
        <>
            <div className="py-3"></div>
            <div className="table-responsive">
                <table className="table table-borderless align-middle">
                    <thead>
                        <tr>
                            <th className='fw-500 text-muted fz-15px'>Joined Date</th>
                            <th className='fw-500 text-muted fz-15px'>Learners Name</th>
                            <th className='fw-500 text-muted fz-15px'>Email</th>
                            <th className='fw-500 text-muted fz-15px'>Total Course</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((learners, i) => (
                            <tr key={learners?.courseCode + i}>
                                <td className='fz-14px fw-500'>{moment(learners?.joinedDate).format('dddd, MMMM Do YYYY')}</td>
                                <td className='fz-14px fw-500'><img src="https://www.efilecabinet.com/wp-content/uploads/2019/05/g2-testimonial-male.jpg" className={`${teacherStyles.tImg}`} />{learners?.learnersName}</td>
                                <td className='fz-14px fw-500'>{learners?.learnersEmail}</td>
                                <td className='fz-14px fw-500'>50</td>
                            </tr>
                        ))

                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LearnersTable