import { API } from 'constants/apiEndPoints';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { instructorDataRecoilState } from 'recoil/atoms/atomsStore';
import { confirmAlert, confirmAlertSuccess } from 'utils/confirmAlert';
import axiosFetch from '../../../../../../axios/AxiosInterceptors';
import cardStyle from "../../../../../../moduleCss/cards.module.css";
import MyS3Component from 'components/S3';

function RecordedCourseLayout({ courseData, fetchCourse, activeTab = "", instructorId }) {

    const [reason, setReason] = useState("");
    const [instructorDataRecoilAtom] = useRecoilState(instructorDataRecoilState)

    const onDeactivate = async (data) => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to deactivate this course?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    courseCode: data?.courseCode,
                    "userType": "ADMIN",
                    comment: reason
                }
                const resp = await axiosFetch.post(API.ADMIN_TEACHER_DEACTIVATE_COURSE + `?instructorId=${data.instructorId}`, jsonData);
                const trigFetch = await fetchCourse();
                toast.success('Course deactivated successfully');

            } catch (error) {
            }
        }

    }
    const onActivateRecordedCourse = async () => {
        const isTrue = await confirmAlertSuccess({ title: "Are you sure you want to activate this course?", cancelText: "Cancel", confirmText: "Activate" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    courseCode: courseData?.courseCode,
                    "userType": "ADMIN",
                }
                const resp = await axiosFetch.post(API.ADMIN_TEACHER_ACTIVATE_COURSE + `?instructorId=${instructorId}`, jsonData);
                const trigFetch = await fetchCourse();
                toast.success('Course activated successfully');

            } catch (error) {
            }
        }
    }
    return (
        <div>
            <div className={`${cardStyle.crdHover} bg-white box-sd p-4 rounded-3 mb-4`}>
                <div className="row">
                    <div className="col-lg-3 m-auto">
                        <MyS3Component src={courseData?.thumbNailImagePath} className={`${cardStyle.crdImg} rounded-3`} />
                    </div>
                    <div className="col-lg-7 m-auto">
                        <h6 className='mb-3'>{courseData?.courseName}</h6>
                        <div className="d-flex mb-3">
                            <p><span className='fz-14px fw-500 text-muted'>By  </span> <span className='fz-14px fw-600 text-prime'>{instructorDataRecoilAtom?.fullName}</span></p>
                        </div>

                        <div className="d-flex mb-3">
                            <p className='me-3'><span className='fz-14px fw-500 text-muted'>Category</span> <span className='fz-14px fw-600 text-prime'>{courseData?.categoryName}</span></p>
                            <p><span className='fz-14px fw-500 text-muted'>Sub - Category</span> <span className='fz-14px fw-600 text-prime'>{courseData?.subCategoryName}</span></p>
                        </div>
                        <h6 className='fw-600 text-prime'>₹{courseData?.fee}</h6>


                    </div>
                    {activeTab == "DEACTIVE" ?
                        <div className={`col-lg-2  mt-auto `}>
                            <button className={`textGreen bgGreenLight  border-0 w-100 py-2 rounded-1 fz-14px fw-500`} onClick={onActivateRecordedCourse}>Activate This Course</button>
                        </div> :
                        <div className="col-lg-2 mt-auto">
                            <textarea className='input mb-5 p-2 fz-13px w-100' placeholder='Deactivate reason' onChange={(e) => setReason(e.target.value)} />
                            <button className={`bgTomatoLight textTomato border-0 w-100 py-2 rounded fz-14px fw-500`} onClick={() => onDeactivate(courseData)}>Deactivate Course</button>
                        </div>}
                </div>
            </div >

        </div>
    )
}

export default RecordedCourseLayout