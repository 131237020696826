import React from 'react'

function BellOutlineIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.228 16.875L21.5196 7.11354C20.9413 5.02543 19.6812 3.19077 17.9397 1.90155C16.1983 0.612324 14.0757 -0.0573721 11.9097 -0.00095579C9.74373 0.0554605 7.65886 0.834747 5.98692 2.21288C4.31497 3.59101 3.15203 5.48878 2.68318 7.60416L0.588393 17.0312C0.487031 17.488 0.489503 17.9616 0.595627 18.4172C0.701751 18.8728 0.908819 19.2988 1.20155 19.6638C1.49429 20.0287 1.86522 20.3232 2.28698 20.5256C2.70874 20.7281 3.17057 20.8332 3.63839 20.8333H7.39568C7.63476 22.0107 8.27353 23.0692 9.20375 23.8295C10.134 24.5899 11.2984 25.0052 12.4998 25.0052C13.7013 25.0052 14.8657 24.5899 15.7959 23.8295C16.7262 23.0692 17.3649 22.0107 17.604 20.8333H21.2186C21.7 20.8331 22.1748 20.7217 22.606 20.5078C23.0372 20.2938 23.4132 19.9832 23.7046 19.6C23.9959 19.2169 24.1949 18.7716 24.2859 18.2989C24.3768 17.8262 24.3574 17.3389 24.229 16.875H24.228ZM12.4998 22.9167C11.8558 22.914 11.2284 22.7124 10.7033 22.3395C10.1782 21.9666 9.78119 21.4405 9.56652 20.8333H15.4332C15.2185 21.4405 14.8215 21.9666 14.2964 22.3395C13.7713 22.7124 13.1439 22.914 12.4998 22.9167ZM22.0467 18.3385C21.9495 18.4674 21.8236 18.5717 21.6789 18.6431C21.5342 18.7146 21.3748 18.7512 21.2134 18.75H3.63839C3.48241 18.75 3.32843 18.7149 3.18782 18.6474C3.0472 18.5799 2.92353 18.4817 2.82595 18.36C2.72836 18.2383 2.65935 18.0963 2.624 17.9444C2.58866 17.7925 2.58788 17.6345 2.62173 17.4823L4.71652 8.0552C5.08563 6.395 5.99913 4.90583 7.31178 3.82442C8.62444 2.74301 10.2609 2.13143 11.9611 2.08689C13.6612 2.04235 15.3275 2.56741 16.695 3.57861C18.0624 4.58981 19.0526 6.02911 19.5082 7.6677L22.2165 17.4292C22.2607 17.5835 22.2684 17.746 22.2389 17.9039C22.2095 18.0617 22.1437 18.2105 22.0467 18.3385Z" fill="#395061" />
        </svg>

    )
}

export default BellOutlineIcon