import React from 'react'

function SendBtnChat({ width, height, color }) {
    return (
        <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08215 0.0760864L24.1147 11.4251C24.2403 11.487 24.346 11.5828 24.42 11.7017C24.4939 11.8206 24.5331 11.9578 24.5331 12.0978C24.5331 12.2379 24.4939 12.3751 24.42 12.494C24.346 12.6129 24.2403 12.7087 24.1147 12.7706L1.08065 24.1196C0.951611 24.183 0.807068 24.2079 0.664238 24.1915C0.521409 24.1751 0.386313 24.1179 0.275039 24.0269C0.163766 23.9359 0.0810051 23.8147 0.0366135 23.678C-0.00777811 23.5413 -0.0119295 23.3946 0.0246533 23.2556L2.30615 14.6381C2.34314 14.4986 2.41955 14.3728 2.52624 14.2757C2.63293 14.1786 2.76536 14.1143 2.90765 14.0906L13.2292 12.3701C13.2907 12.3599 13.3487 12.3345 13.3979 12.2963C13.4471 12.2581 13.4861 12.2082 13.5112 12.1511L13.5382 12.0611C13.5522 11.9777 13.5376 11.892 13.4967 11.8179C13.4559 11.7438 13.3912 11.6858 13.3132 11.6531L13.2292 11.6291L2.85065 9.89959C2.70863 9.87558 2.57652 9.81118 2.47012 9.71409C2.36372 9.617 2.28753 9.49133 2.25065 9.35209L0.0246533 0.941586C-0.0123101 0.802446 -0.00844583 0.655606 0.0357849 0.518603C0.0800156 0.381599 0.162744 0.26022 0.27409 0.16896C0.385437 0.0777003 0.520696 0.0204157 0.663718 0.00394709C0.80674 -0.0125215 0.951479 0.0125219 1.08065 0.0760864H1.08215Z" fill="#395061" />
        </svg>
    )
}

export default SendBtnChat