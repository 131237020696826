import sidebarAndDashboardStyles from '../../../moduleCss/sidebarAndDashboard.module.css'
import axiosFetch from '../../../axios/AxiosInterceptors';
import { useEffect, useRef, useState } from 'react';
import { API } from 'constants/apiEndPoints';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import moment from 'moment';
function TransactionTable() {

    const [transactionData, setTransactionData] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [restoreList, setRestoreList] = useState(null);
    const tableRef = useRef(null);

    useEffect(() => {
        fetchTransactions();
    }, [])
    const fetchTransactions = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_ALL_TRANSACTIONS, jsonData);
            setTransactionData(resp.data)

        } catch (error) {

        }
    }
//after fetch
useEffect(() => {
    if (transactionData) {
        const sortedData = [...transactionData].sort((a, b) => {
            const dateA = a?.requestedDate ? new Date(a.requestedDate) : null;
            const dateB = b?.requestedDate ? new Date(b.requestedDate) : null;
            return dateB - dateA;
        });
        const endOffset = itemOffset + Number(itemsPerPage);
        let sliceItem = sortedData.slice(itemOffset, endOffset);
        setCurrentItems(sliceItem)

        let pageCountResult = Math.ceil(sortedData.length / itemsPerPage);
        setPageCount(pageCountResult)
    }
}, [transactionData?.length, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % transactionData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };


    const handlePrint = () => {
        const tableContent = tableRef.current.innerHTML;
        const printWindow = window.document.createElement('iframe');
        printWindow.style.position = 'absolute';
        printWindow.style.width = '0';
        printWindow.style.height = '0';
        printWindow.style.visibility = 'hidden';
        window.document.body.appendChild(printWindow);
        printWindow.contentDocument.write(`
          <html>
            <head>
              <title>Latest Transactions</title>
              <style>
                /* Add your table styling here */
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                
                th, td {
                  border: 1px solid #000;
                  padding: 8px;
                }
              </style>
            </head>
            <body>
              ${tableContent}
            </body>
          </html>
        `);
        printWindow.contentDocument.close();
        printWindow.focus();
        printWindow.contentWindow.print();
        window.document.body.removeChild(printWindow);
    };

    return (
        <div className='py-5'>

            <div className="pt-5 pb-4 bg-white rounded-3">
                <div className="px-lg-4">
                    <div className="d-flex align-items-lg-center justify-content-lg-between">
                        <h6>Latest Transactions</h6>
                        <div className="bg-pink px-4 py-2 rounded-2 d-flex align-items-center" role={"button"}>

                            <p className="ms-2 my-0 fz-14px fw-500 text-prime" onClick={handlePrint}>Print</p>
                        </div>
                    </div>
                    <div className="py-2"></div>
                    {/* ------- */}
                    <div className="table-responsive" ref={tableRef}>
                        <table className="table table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th className='fw-500 text-muted fz-15px'>Requested Date</th>
                                    <th className='fw-500 text-muted fz-15px'>Instructor Name</th>
                                    <th className='fw-500 text-muted fz-15px'>Instructor Email</th>
                                    <th className='fw-500 text-muted fz-15px'>Requested Amount</th>
                                    <th className='fw-500 text-muted fz-15px'>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {!currentItems ? <tr className='text-center'>
                                    <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">Loading...</td>
                                </tr> : currentItems.length ?
                                    currentItems.map((tData, index, array) => (
                                        <tr key={tData?._id}>
                                            <td>
                                            {tData?.requestedDate ? moment(tData.requestedDate).format("DD MMM, YYYY") : " "}
                                            </td>
                                            <td>{tData?.fullName}</td>
                                            {/* <td><img src="https://www.efilecabinet.com/wp-content/uploads/2019/05/g2-testimonial-male.jpg" className={`${sidebarAndDashboardStyles.tImg}`} />MN Nahid</td> */}
                                            <td>{tData?.instructorEmail}</td>
                                            <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR", minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(tData?.requestedAmount)}</td>
                                            <td style={{ width: '120px' }}>
                                            {tData?.requestedstatus == "PAID" ?
                                                            <span className={`${tData?.requestedstatus} d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1`}>{tData?.requestedstatus}</span>
                                                            :
                                                            <span role={"button"} className={`${tData?.requestedstatus} d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1`}>{tData?.requestedstatus}</span>
                                                        }
                                            </td>
                                        </tr>
                                    )) : <tr className='text-center'>
                                        <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    {/* ------- */}
                    <div className="py-1"></div>
                    <div className="row">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> transactions per page
                        </div>
                    </div>
                </div>
            </div>

            <JsonViewer data={transactionData} />
        </div>
    )
}

export default TransactionTable