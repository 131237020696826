import axiosFetch from '../../../../../../axios/AxiosInterceptors'
import Loader from 'components/Loader'
import { API } from 'constants/apiEndPoints'
import { useEffect, useState } from 'react'
import RightArrowIcon from 'svgIcons/RightArrowIcon'
import { getLocalStorage } from 'utils/localStorage'
import lessonStyles from '../../../../../../moduleCss/chapter.module.css'
import LiveCourseClickedAssessment from './LiveCourseClickedAssessment'
import JsonViewer from 'components/JsonViewer'
function LiveCourseAssessment() {

    const [clickedAssessmentView, setClickedAssessmentView] = useState(null);
    const [assessment, setAssessment] = useState(null);
    useEffect(() => {
        fetchInreviewAssessment();
    }, [])
    const fetchInreviewAssessment = async () => {
        const clickedCourse = getLocalStorage("clickedCourse")
        try {
            const resp = await axiosFetch.post(API.GET_INREVIEW_ASSESSMENT_BY_LIVECOURSE_CODE + `?courseCode=${clickedCourse?.courseCode}`);
            setAssessment(resp.data.assesmentList)
        } catch (error) {

        }
    }
    const onViewAssessment = (data) => {
        setClickedAssessmentView(<LiveCourseClickedAssessment setClickedAssessmentView={setClickedAssessmentView} data={data} />)
    }
    return (
        <>
            <JsonViewer data={assessment} />
            {clickedAssessmentView ?
                clickedAssessmentView
                : <ul className={`${lessonStyles.chapterList} list-unstyled`}>
                    {!assessment ? <Loader className="text-prime my-3" center={true} /> : assessment?.length ?
                        <>  {
                            assessment?.map((item, index, array) => (
                                <li role={"button"} onClick={() => onViewAssessment(item)} key={index * 2}>
                                    <div className="row">
                                        <div className="col-10">
                                            <div className='fz-15px fw-500 text-dark'>{item?.assessmentTitle}</div>
                                            <p className='fz-13px mt-1 fw-500 text-muted'>{item?.assessmentDetails?.length} Question</p>
                                        </div>
                                        <div className="col-2 text-end m-auto"  >
                                            <RightArrowIcon width={8} />
                                        </div>
                                    </div>
                                </li>
                            ))
                        }</>
                        : <h6 className='text-muted'>No data available </h6>}
                </ul>}
        </>
    )
}

export default LiveCourseAssessment