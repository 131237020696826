import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Paginate from 'components/Paginate';
import PrinterSVG from '../../svgs/Printer';
import FileDownload from '../../svgs/FileDownload';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';

function ViewBuyingHistoryHistory() {
    const [buyingHistory, setBuyingHistory] = useState([]);
    const [activeTeachersData, setActiveTeachersData] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const params = useParams();

    const getStudentBuyingHistory = async (studentId) => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.STUDENT_BUYING_HISTORY + `?studentId=${studentId}`, jsonData);
            setBuyingHistory(resp.data[0]);
        } catch (error) {

        }
    }

    useEffect(() => {
        if (params.student_id) {
            getStudentBuyingHistory(params.student_id);
        }
    }, [params.student_id])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % activeTeachersData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <div className="px-4">
            <div className="p-3 bg-white rounded-3">
                <div className="px-lg-4 theme-tab"></div>
                {/* <button
                    className='btn'
                    style={{
                        marginLeft: 'auto',
                        display: 'block',
                        backgroundColor: '#F0E1EB'
                    }}
                >
                    <PrinterSVG /> Print
                </button> */}
                <div className="table-responsive mt-4">
                    <table className="table table-borderless align-middle">
                        <thead>
                            <tr>
                                <th className='fw-500 text-muted fz-15px'>Course Name</th>
                                <th className='fw-500 text-muted fz-15px'>Timestamp</th>
                                <th className='fw-500 text-muted fz-15px'>Amount</th>
                                <th className='fw-500 text-muted fz-15px'>Status</th>
                                {/* <th className='fw-500 text-muted fz-15px'>Invoice</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {buyingHistory.sort((a, b) => {
                         const dateComparison = new Date(b.date) - new Date(a.date);
                          if (dateComparison !== 0) {
                          return dateComparison;
                           }
                          if (a.status === 'confirmed' && b.status !== 'pending') {
                          return 1; 
                           } else if (a.status !== 'confirmed' && b.status === 'pending') {
                          return -1; 
                           }
                          return 0;
                          })
                          .map(course => (
                           <tr key={course.date}>
                                    <td className='fz-15px'>
                                        {course.courseName}
                                    </td>
                                    <td className='fz-15px'>{new Date(course.date).toLocaleDateString('en-IN', {
                                                   day: 'numeric',
                                                   month: 'long',
                                                   year: 'numeric'
                                                }).replace(/(\s)(\d{4})$/, ',$2')}</td>
                                    <td className='fz-15px'>₹{course.fee}</td>
                                    <td className='fz-15px'>
                                        {course.status === "PAYMENT_COMPLETED" && (
                                            <span style={{ backgroundColor: '#29D363', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                                Confirmed
                                            </span>
                                        )}

                                        {course.status === "PAYMENT_INPROGRESS" && (
                                            <span style={{ backgroundColor: '#FFBE40', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                                Pending
                                            </span>
                                        )}

                                        {course.status === "PAYMENT_CANCELED" && (
                                            <span style={{ backgroundColor: '#F65656', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                                Cancelled
                                            </span>
                                        )}

                                        {/* PAYMENT_INPROGRESS */}

                                    </td>
                                    {/* <td className='fz-15px'>
                                        <button className="btn rounded-circle" style={{ backgroundColor: 'rgba(240, 225, 235, 0.3)', width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px' }}>
                                            <FileDownload />
                                        </button>
                                    </td> */}
                                </tr>
                            ))}

                            {/* <tr>
                                <td className='fz-15px'>Foundation Of User Experience Design</td>
                                <td className='fz-15px'>14 February , 2022</td>
                                <td className='fz-15px'>$120.00</td>
                                <td className='fz-15px'>
                                    <span style={{ backgroundColor: '#FFBE40', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                        Pending
                                    </span>
                                </td>
                                <td className='fz-15px'>
                                    <button className="btn rounded-circle" style={{ backgroundColor: 'rgba(240, 225, 235, 0.3)', width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px' }}>
                                        <FileDownload />
                                    </button>
                                </td>
                            </tr> */}
                            {/* <tr>
                                <td className='fz-15px'>Foundation Of User Experience Design</td>
                                <td className='fz-15px'>14 February , 2022</td>
                                <td className='fz-15px'>$120.00</td>
                                <td className='fz-15px'>
                                    <span style={{ backgroundColor: '#29D363', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                        Confirmed
                                    </span>
                                </td>
                                <td className='fz-15px'>
                                    <button className="btn rounded-circle" style={{ backgroundColor: 'rgba(240, 225, 235, 0.3)', width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px' }}>
                                        <FileDownload />
                                    </button>
                                </td>
                            </tr> */}
                            {/* <tr>
                                <td className='fz-15px'>Foundation Of User Experience Design</td>
                                <td className='fz-15px'>14 February , 2022</td>
                                <td className='fz-15px'>$120.00</td>
                                <td className='fz-15px'>
                                    <span style={{ backgroundColor: '#F65656', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontSize: '0.9rem' }}>
                                        Cancelled
                                    </span>
                                </td>
                                <td className='fz-15px'>
                                    <button className="btn rounded-circle" style={{ backgroundColor: 'rgba(240, 225, 235, 0.3)', width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px' }}>
                                        <FileDownload />
                                    </button>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> courses per page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewBuyingHistoryHistory