import { atom } from "recoil";
import { io } from "socket.io-client";

// =====categoryFilterRecoilState
export const categoryFilterRecoilState = atom({
    key: '896506e3-955c-43d5-a888-50c009bafad4', // unique ID (with respect to other atoms/selectors)
    default: {
        triggerChangeKey: "",
        value: ""
    }, // default value (aka initial value)
});
// =====subCategoryFilterRecoilState
export const subCategoryFilterRecoilState = atom({
    key: '2a742d45-b7b8-4b15-ba45-44f5669be992',
    default: {
        triggerChangeKey: "",
        value: ""
    },
});
// =====searchCourseRecoilState
export const searchCourseRecoilState = atom({
    key: '38f4046a-9619-4d04-a41d-100db771870b',
    default: {
        triggerChangeKey: "",
        value: ""
    },
});
// =====courseFilterRecoilState
export const courseFilterRecoilState = atom({
    key: '1fa54456-7b6c-4f17-915d-27b3ac0ffada',
    default: {
        triggerChangeKey: "",
        value: "ALL"
    },
});

// =====LiveCourse Overview
export const liveCourseOverviewRecoilState = atom({
    key: '5b45ac47-ad91-4c54-a2a6-b6a3aae7f089',
    default: ""
});
// =====RangeFilterRecoilState
export const dateFilterRecoilState = atom({
    key: '6f0cd3bf-6af8-4a31-b1c4-9f4079f06e07',
    default: {
        triggerChangeKey: "",
        fromDate: "",
        toDate: ""
    },
});
// =====Manage Teacher Detail Atom
export const instructorDataRecoilState = atom({
    key: '01e932ea-1201-4a59-bff6-1f7ef4205578',
    default: "",
});


