import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import VideoPlayer from 'components/VideoPlayer';
import { API } from 'constants/apiEndPoints';
import { Stack } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'svgIcons/ArrowLeft';
import OutlinePointIcon from 'svgIcons/OutlinePointIcon';
import Swal from 'sweetalert2';
import { confirmAlert } from 'utils/confirmAlert';
import { getLocalStorage } from 'utils/localStorage';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';
import RejectApproveHeader from './RejectApproveHeader';

function ClickedAssessmentUploaded() {
    const clickedLsn = getLocalStorage("uploadedVideoClickedLesson")
    const clickedChapter = getLocalStorage("uploadedVideoClickedChapter");
    const clickedCourse = getLocalStorage("clickedCourse");
console.log('clickedLsn',clickedLsn)
console.log('clickedChapter',clickedChapter)
console.log('clickedCourse',clickedCourse)
    const navigate = useNavigate();
    // const params = "active=uploadTab";
    const back = () => {
        navigate(-1)
    }

    const onReject = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to reject this Assessment?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    courseCode: clickedCourse?.courseCode,
                    chapterOrder: clickedChapter?.chapterOrder,
                    assesmentOrder: clickedLsn?.lessonOrder
                }
                const resp = await axiosFetch.post(API.REJECT_ASSESSMENT, jsonData);
                toast.success('Assessment Rejected Successfully!');
                back()


            } catch (error) {

            }

        }
    }
    const onApprove = async () => {
        try {
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
                chapterOrder: clickedChapter?.chapterOrder,
                assesmentOrder: clickedLsn?.lessonOrder
            }
            const resp = await axiosFetch.post(API.APPROVE_ASSESSMENT, jsonData);
            toast.success('Assessment Approved Successfully!');
            back()

        } catch (error) {

        }

    }
    return (
        <div className="px-4">

            <RejectApproveHeader />
            <div className="py-3"></div>
            <div className="bg-white rounded-2 py-4 px-5 mb-4">
                <JsonViewer data={clickedLsn} />
                <Stack direction="horizontal" gap={4}>
                    <h6 className='text-dark d-inline-block text-capitalize' role={"button"} onClick={back}><ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} /> {clickedLsn?.lessonName}</h6>
                    {/* <div className="textTomato fz-14px fw-500 ms-auto" onClick={onReject} role={"button"}>Reject</div> */}
                    {clickedLsn.lessonStatus != 'APPROVED' && (
                    <div className={`textTomato fz-14px fw-500 ms-auto`} 
                        role="button" 
                        onClick={onReject} 
                        style={{
                            opacity: 1,
                            pointerEvents: 'auto'
                        }}
                    >
                        Reject
                    </div>
                    )}
                    {/* <div className="bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1" role={"button"} onClick={onApprove}>Approve</div> */}
                    {/* <div className={`bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1 ${clickedLsn.lessonStatus === 'APPROVED' ? 'disabled' : ''}`} role="button" onClick={clickedLsn.lessonStatus !== 'APPROVED' ? onApprove : null} style={{opacity: clickedLsn.lessonStatus === 'APPROVED' ? 0.5 : 1, pointerEvents: clickedLsn.lessonStatus === 'APPROVED' ? 'none' : 'auto'}}>Approve</div> */}
                    {clickedLsn.lessonStatus != 'APPROVED' && (
                    <div className={`bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1`} 
                        role="button" 
                        onClick={onApprove} 
                        style={{
                            opacity: 1,
                            pointerEvents: 'auto'
                        }}
                    >
                        Approve
                    </div>
                    )}

                    {/* <div className="bgTomatoLight textTomato fz-14px fw-500 px-4 py-2 rounded-1" onClick={onDeactivate}>Deactive This Assessments</div> */}
                </Stack>
                <div className="py-3"></div>
                {/* <h6 className='text-muted fz-14px py-3'>Total Points 10</h6> */}
                {clickedLsn?.assessmentDetails ?
                    clickedLsn?.assessmentDetails?.map((assessmentData, assesQuestionIndex) => {
                        return (
                            <div key={assessmentData._id}>
                                <Stack direction="horizontal" gap={4} className="py-2">
                                    <h6>  {assesQuestionIndex + 1 + "."} {assessmentData.assessmentQuestion}</h6>
                                    <div className="bg-light fz-12px fw-500 px-3 ms-auto py-1 rounded-1">{assessmentData?.point} Point</div>
                                </Stack>
                                {
                                    assessmentData.assessmentChoice.map((assessmentOptions, assessmentOptionKey) => {
                                        return (
                                            <div className='py-2' key={assessmentOptionKey + assessmentOptions}>
                                                <p><OutlinePointIcon width={13} /> {assessmentOptions}</p>

                                                {/* option{assessmentOptionKey + 1} -{assessmentOptions} */}

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }) : "Loading..."}



            </div>
        </div>
    )
}

export default ClickedAssessmentUploaded