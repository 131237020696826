import React from 'react'

function ManageCategoryIcon({ width, height, color }) {
  return (
    <svg width={width} height={height} fill={color} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.29168 11.198H3.12501C2.64171 11.1974 2.17837 11.0052 1.83662 10.6635C1.49488 10.3217 1.30264 9.85838 1.30209 9.37508V5.20841C1.30264 4.72512 1.49488 4.26177 1.83662 3.92003C2.17837 3.57828 2.64171 3.38605 3.12501 3.3855H7.29168C7.77498 3.38605 8.23833 3.57828 8.58007 3.92003C8.92181 4.26177 9.11405 4.72512 9.1146 5.20841V9.37508C9.11405 9.85838 8.92181 10.3217 8.58007 10.6635C8.23833 11.0052 7.77498 11.1974 7.29168 11.198ZM3.12501 4.948C3.05594 4.948 2.98971 4.97543 2.94087 5.02427C2.89203 5.07311 2.86459 5.13935 2.86459 5.20841V9.37508C2.86459 9.44415 2.89203 9.51039 2.94087 9.55922C2.98971 9.60806 3.05594 9.6355 3.12501 9.6355H7.29168C7.36075 9.6355 7.42699 9.60806 7.47582 9.55922C7.52466 9.51039 7.5521 9.44415 7.5521 9.37508V5.20841C7.5521 5.13935 7.52466 5.07311 7.47582 5.02427C7.42699 4.97543 7.36075 4.948 7.29168 4.948H3.12501Z" />
      <path d="M7.29168 21.6145H3.12501C2.64171 21.6139 2.17837 21.4217 1.83662 21.08C1.49488 20.7382 1.30264 20.2749 1.30209 19.7916V15.6249C1.30264 15.1416 1.49488 14.6783 1.83662 14.3365C2.17837 13.9948 2.64171 13.8026 3.12501 13.802H7.29168C7.77498 13.8026 8.23833 13.9948 8.58007 14.3365C8.92181 14.6783 9.11405 15.1416 9.1146 15.6249V19.7916C9.11405 20.2749 8.92181 20.7382 8.58007 21.08C8.23833 21.4217 7.77498 21.6139 7.29168 21.6145ZM3.12501 15.3645C3.05594 15.3645 2.98971 15.3919 2.94087 15.4408C2.89203 15.4896 2.86459 15.5559 2.86459 15.6249V19.7916C2.86459 19.8607 2.89203 19.9269 2.94087 19.9757C2.98971 20.0246 3.05594 20.052 3.12501 20.052H7.29168C7.36075 20.052 7.42699 20.0246 7.47582 19.9757C7.52466 19.9269 7.5521 19.8607 7.5521 19.7916V15.6249C7.5521 15.5559 7.52466 15.4896 7.47582 15.4408C7.42699 15.3919 7.36075 15.3645 7.29168 15.3645H3.12501Z" />
      <path d="M22.9167 5.9895H12.5C12.2928 5.9895 12.0941 5.90719 11.9476 5.76068C11.8011 5.61417 11.7188 5.41545 11.7188 5.20825C11.7188 5.00105 11.8011 4.80234 11.9476 4.65582C12.0941 4.50931 12.2928 4.427 12.5 4.427H22.9167C23.1239 4.427 23.3226 4.50931 23.4691 4.65582C23.6156 4.80234 23.6979 5.00105 23.6979 5.20825C23.6979 5.41545 23.6156 5.61417 23.4691 5.76068C23.3226 5.90719 23.1239 5.9895 22.9167 5.9895Z" />
      <path d="M18.75 10.1562H12.5C12.2928 10.1562 12.0941 10.0739 11.9476 9.92743C11.8011 9.78091 11.7188 9.5822 11.7188 9.375C11.7188 9.1678 11.8011 8.96909 11.9476 8.82257C12.0941 8.67606 12.2928 8.59375 12.5 8.59375H18.75C18.9572 8.59375 19.1559 8.67606 19.3024 8.82257C19.4489 8.96909 19.5312 9.1678 19.5312 9.375C19.5312 9.5822 19.4489 9.78091 19.3024 9.92743C19.1559 10.0739 18.9572 10.1562 18.75 10.1562Z" />
      <path d="M22.9167 16.4062H12.5C12.2928 16.4062 12.0941 16.3239 11.9476 16.1774C11.8011 16.0309 11.7188 15.8322 11.7188 15.625C11.7188 15.4178 11.8011 15.2191 11.9476 15.0726C12.0941 14.9261 12.2928 14.8438 12.5 14.8438H22.9167C23.1239 14.8438 23.3226 14.9261 23.4691 15.0726C23.6156 15.2191 23.6979 15.4178 23.6979 15.625C23.6979 15.8322 23.6156 16.0309 23.4691 16.1774C23.3226 16.3239 23.1239 16.4062 22.9167 16.4062Z" />
      <path d="M18.75 20.573H12.5C12.2928 20.573 12.0941 20.4907 11.9476 20.3442C11.8011 20.1977 11.7188 19.9989 11.7188 19.7917C11.7188 19.5845 11.8011 19.3858 11.9476 19.2393C12.0941 19.0928 12.2928 19.0105 12.5 19.0105H18.75C18.9572 19.0105 19.1559 19.0928 19.3024 19.2393C19.4489 19.3858 19.5312 19.5845 19.5312 19.7917C19.5312 19.9989 19.4489 20.1977 19.3024 20.3442C19.1559 20.4907 18.9572 20.573 18.75 20.573Z" />
    </svg>

  )
}

export default ManageCategoryIcon