import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AssessmentTicketList from '../assessment/AssessmentTicketList';
import CourseTicketList from '../courses/CourseTicketList';


function TicketManagement() {
    const [courseType, setCourseType] = useState("ALL");
    const [isShowFilter, setIsShowFilter] = useState(true)
    const [selectedDate, setSelectedDate] = useState({ from: "", to: "" });
    const [search, setSearch] = useState("")


    const handleDateChange = (e) => {
        setSelectedDate({ ...selectedDate, [e.target.name]: e.target.value });
    };
    return (
        <div className="px-4">
            <div className=" px-5 py-5 mb-4">
                <div className="px-lg-3  theme-tab">
                    <Tab.Container defaultActiveKey="course_tab" id="hr-tabs-example" animation={true} mountOnEnter unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <div className="row">
                                    <div className="col-lg">
                                        <div className="d-inline-block">
                                            <Nav variant="pills" className='bg-light rounded-1'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="course_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setIsShowFilter(true)}>Courses</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="assessment_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setIsShowFilter(false)}>Assessment</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                        <div className="col-lg-7 ">
                                        <div className="row g-0">
                                                <>
                                                    <div className="col-lg-4 text-end">
                                                    {!isShowFilter?<input type='search' placeholder='search by email' onChange={(e) => setSearch(e.target.value)} className='py-2 input w-100' />: <select className='py-2 w-100' onChange={(e) => setCourseType(e.target.value)}>
                                                            <option value="ALL">All</option>
                                                            <option value="LIVE">LiveCourse</option>
                                                            <option value="RECORDED">Recorded Course</option>
                                                        </select>}
                                                    </div>
                                                    <div className="col-lg-4 text-end">
                                                        <label className=' text-muted fz-13px me-1 '>From</label>
                                                        <input type={"date"}
                                                            name="from"
                                                            value={selectedDate?.from}
                                                            onChange={handleDateChange}
                                                            className="py-2 fz-13px text-prime input" />
                                                    </div>
                                                    <div className="col-lg-4 text-end">
                                                        <label className=' me-1 text-muted fz-13px'>To</label>
                                                        <input type={"date"}
                                                            name="to"
                                                            value={selectedDate?.to}
                                                            onChange={handleDateChange}
                                                            className="py-2 fz-13px text-prime input" />
                                                    </div>
                                                </>
                                        </div>
                                    </div>
                                 
                                </div>

                            </Col>
                            <div className="py-2"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="course_tab">
                                        <CourseTicketList
                                            selectedDate={selectedDate}
                                            courseType={courseType} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="assessment_tab">
                                        <AssessmentTicketList  selectedDate={selectedDate}
                                            search={search}/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default TicketManagement
