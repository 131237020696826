import React, { useRef, useState } from 'react'
import VideoPlayIcon from 'svgIcons/VideoPlayIcon';
import lessonStyles from '../moduleCss/chapter.module.css';

function VideoPlayer({ url }) {
    const [play, setPlay] = useState(false)

    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        // vidRef.current.play();
        setPlay(!play)
    }
    return (
        <>
            <div className="position-relative d-inline-block col-12" onClick={handlePlayVideo} role="button">
                <VideoPlayIcon playBtnColor={play ? "transparent" : "#395061"} color={play ? "transparent" : "white"} width={55} className={`${lessonStyles.playWrap}`} />
                <video width="100%" height="450px" className=' rounded-4' controls>
                    <source src={url} type="video/mp4" />
                </video>
            </div>
        </>
    )
}

export default VideoPlayer