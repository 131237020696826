import axiosFetch from '../../../axios/AxiosInterceptors'
import { API } from 'constants/apiEndPoints'
import React, { useState } from 'react'
import { useEffect } from 'react'
import JsonViewer from 'components/JsonViewer'
import { cAlert, cLog } from 'utils/logger'
import PriceTable from '../table/PriceTable'
import { toast, Toaster } from 'react-hot-toast'
import Paginate from 'components/Paginate'

function ManageFees() {
    const [catList, setCatList] = useState([])
    const [subCatList, setSubCatList] = useState([])
    const [clickedCategory, setClickedCategory] = useState("")
    const [clickedSubCategory, setClickedSubCategory] = useState("")
    const [videoCount, setVideoCount] = useState("")
    const [price, setPrice] = useState("")
    const [validationMessage, setValidationMessage] = useState('');
    const [currency, setCurrency] = useState("")
    const [id, setID] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [oldVideoCount, setOldVideoCount] = useState("")
    // ---Paginate states--
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);

    const resetField = () => {
        setClickedCategory("")
        setClickedSubCategory("")
        setVideoCount("")
        setPrice("")
        setCurrency("")
    }


    useEffect(() => {
        getAllCategory();
    }, [])
    const getAllCategory = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_CATEGORY);
            setCatList(resp.data)
        } catch (error) {
        }
    }
    const onCategoryChange = (e) => {
        setClickedCategory(e.target.value);
        setClickedSubCategory("");
        let catCode = e.target.value;
        fetchSubCategory(catCode)
    }
    const fetchSubCategory = async (catCode) => {
        try {
            const resp = await axiosFetch(API.GET_SUB_CATEGORY_BY_CATEGORY_CODE + `?categoryCode=${catCode}`);
            setSubCatList(resp.data)
        } catch (error) {
        }
    }

    const handlePriceChange = (e) => {
        const enteredValue = e.target.value;
        if ( enteredValue < 0) {
            setValidationMessage('Please enter a positive number');
        } else {
            setPrice(enteredValue);
            setValidationMessage('');
        }
    };

    const handelSubmit = async (e) => {
        e.preventDefault();
        const jsonData = {
            "videoCount": videoCount,
            "price": Number(price),
            "categoryCode": clickedCategory,
            "subCategoryCode": clickedSubCategory,
            "currency": currency
        }
        if (isEdit) {
            try {

                const resp = await axiosFetch.put(API.EDIT_PAYMENT_FEES_DATA + `?_id=${id}&videoCount=${jsonData.videoCount}&price=${jsonData.price}&categoryCode=${jsonData.categoryCode}&currency=${jsonData.currency}&subCategoryCode=${jsonData.subCategoryCode}&oldVideoCount=${oldVideoCount}&newVideoCount=${jsonData.videoCount}`);
                const fetchUpdatedData = await getAllPriceTableData();
                resetField();
                setIsEdit(false);
                toast.success("Updated Successfully!")
            } catch (error) {
            }
        } else {
            try {
                const resp = await axiosFetch.post(API.SUBMIT_FEES, jsonData);
                resetField();
                getAllPriceTableData();
                toast.success("Created Successfully!")
            } catch (error) {

            }
        }
    }

    // --Table functions----
    const [tableData, setTableData] = useState(null);

    const getAllPriceTableData = async () => {
        try {
            const resp = await axiosFetch(API.GET_PAYMENT_FEES_DATA);
            setTableData(resp.data)
        } catch (error) {
        }
    }

    const onEdit = async (data) => {
        setIsEdit(true);
        const fetchSubCat = await fetchSubCategory(data?.categoryCode);
        setID(data?._id)
        setClickedCategory(data?.categoryCode)
        setClickedSubCategory(data?.subCategoryCode)
        setVideoCount(data?.videoCount);
        setOldVideoCount(data?.videoCount);
        setPrice(data?.price)
        setCurrency(data?.currency);
    }
    useEffect(() => {
        if (tableData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = tableData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(tableData.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [tableData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % tableData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <>

            <div className='px-4'>
                <div className="bg-white p-4 rounded-3">
                    <form onSubmit={handelSubmit}>
                        <div className="row g-2 mt-4">
                            <div className="col-lg">

                                <label className='fw-500   text-muted fz-14px mb-2   d-block'>Category</label>
                                <select className='px-3 py-10px w-100' value={clickedCategory} onChange={onCategoryChange} required>
                                    <option value={""}>Select Category</option>
                                    {catList ? catList.map((item, i) => (
                                        <option value={item.categoryCode} key={i * 2}>{item?.categoryName}</option>
                                    ))
                                        : ""
                                    }

                                </select>
                            </div>
                            <div className="col-lg">

                                <label className='fw-500   text-muted fz-14px mb-2   d-block'>Sub- Category</label>
                                <select className='px-3 py-10px w-100' value={clickedSubCategory} onChange={(e) => setClickedSubCategory(e.target.value)} required>
                                    <option value={""}>Select Sub-Category</option>
                                    {subCatList ? subCatList.map((item) => (
                                        <option value={item.subCategoryCode} key={item._id}>{item?.subCategoryName}</option>
                                    ))
                                        : ""
                                    }

                                </select>
                            </div>
                            <div className="col-lg">

                                <label className='fw-500 mb-2  fz-14px text-muted d-block'>Videos</label>
                                <select className='px-3 py-10px w-100' value={videoCount} onChange={(e) => setVideoCount(e.target.value)} required>
                                    <option value={""}>Select Count</option>
                                    <option value={"0-10"}>0-10</option>
                                    <option value={"10-20"}>10-20</option>
                                    <option value={"20-30"}>20-30</option>
                                    <option value={"30-40"}>30-40</option>
                                    <option value={"40-50"}>40-50</option>
                                    <option value={"50-60"}>50-60</option>
                                    <option value={"60-70"}>60-70</option>
                                    <option value={"70-80"}>70-80</option>
                                </select>
                            </div>
                            <div className="col-lg">
                                <label className='fw-500 mb-2  fz-14px d-block text-muted'>Currency</label>
                                <select className='px-3 py-10px w-100' value={currency} onChange={(e) => setCurrency(e.target.value)} required>
                                    <option value={""}>Select Currency</option>
                                    <option value={"INR"}>₹</option>
                                    {/* <option value={"USD"}>$</option> */}
                                </select>
                            </div>
                            <div className="col-lg">
                                <label className='fw-500 mb-2  fz-14px d-block text-muted'>Price</label>
                                <input type="number" onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength)
                                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                                }}
                                    onWheel={(e) => e.target.blur()} maxLength={6} value={price} className='input w-100 py-10px' placeholder='Enter the Price' onChange={handlePriceChange} required />
                                    {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
                            </div>

                            <div className="col-lg">
                                <label className='mb-2 fz-14px d-block opacity-0'>submit dummy</label>
                                <button type='submit' className=' fz-14px border-0 bg-prime text-white px-5 py-10px rounded-1 w-100' disabled={currency && clickedCategory && clickedSubCategory && videoCount && price ? false : true}>{isEdit ? "Save" : "+ Create"}</button>
                            </div>
                        </div >
                        <div className="pb-5"></div>

                    </form >
                    {/* ---Functions are passing as props------ */}
                    <PriceTable onEdit={onEdit} getAllPriceTableData={getAllPriceTableData} tableData={currentItems} />
                    <div className="py-2"></div>
                    <div className="row">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> category per page
                        </div>
                    </div>
                </div >
            </div>

        </>
    )
}

export default ManageFees