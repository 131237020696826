import React from 'react'

function AssessmentIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F0E1EB" />
            <g clipPath="url(#clip0_796_4559)">
                <path d="M13.9605 18.6089C14.0214 18.673 14.1071 18.7099 14.1955 18.7101H14.196C14.2843 18.7101 14.3698 18.6735 14.4309 18.6096L14.8489 18.1717C14.9467 18.0693 14.9429 17.907 14.8405 17.8092C14.738 17.7115 14.5758 17.7152 14.478 17.8176L14.1963 18.1128L14.1483 18.0622C14.0507 17.9595 13.8885 17.9554 13.7858 18.0529C13.6832 18.1504 13.679 18.3127 13.7765 18.4154L13.9605 18.6089Z" fill="#395061" />
                <path d="M15.788 17.1772C15.788 16.9416 15.5963 16.75 15.3607 16.75H13.2661C13.0305 16.75 12.8389 16.9416 12.8389 17.1772V19.2718C12.8389 19.5074 13.0305 19.6991 13.2661 19.6991H15.3607C15.5963 19.6991 15.788 19.5074 15.788 19.2718V17.1772ZM15.2753 19.1864H13.3516V17.2627H15.2753V19.1864Z" fill="#395061" />
                <path d="M16.7866 19.6992H20.4247C20.5663 19.6992 20.6811 19.5845 20.6811 19.4429C20.6811 19.3013 20.5663 19.1865 20.4247 19.1865H16.7866C16.645 19.1865 16.5303 19.3013 16.5303 19.4429C16.5303 19.5845 16.645 19.6992 16.7866 19.6992Z" fill="#395061" />
                <path d="M16.7866 18.6523H20.4247C20.5663 18.6523 20.6811 18.5376 20.6811 18.396C20.6811 18.2544 20.5663 18.1396 20.4247 18.1396H16.7866C16.645 18.1396 16.5303 18.2544 16.5303 18.396C16.5303 18.5376 16.645 18.6523 16.7866 18.6523Z" fill="#395061" />
                <path d="M13.9606 15.0094C14.0216 15.0735 14.1072 15.1104 14.1957 15.1104H14.196C14.2843 15.1104 14.3699 15.0738 14.4309 15.0099L14.8489 14.572C14.9466 14.4696 14.9429 14.3074 14.8405 14.2096C14.7381 14.1119 14.5758 14.1156 14.478 14.218L14.1963 14.5131L14.1483 14.4626C14.0507 14.3599 13.8885 14.3558 13.7858 14.4533C13.6832 14.5508 13.679 14.7131 13.7766 14.8157L13.9606 15.0094Z" fill="#395061" />
                <path d="M15.788 13.5767C15.788 13.3411 15.5963 13.1494 15.3607 13.1494H13.2661C13.0305 13.1494 12.8389 13.3411 12.8389 13.5767V15.6713C12.8389 15.9069 13.0305 16.0985 13.2661 16.0985H15.3607C15.5963 16.0985 15.788 15.9069 15.788 15.6713V13.5767ZM15.2753 15.5858H13.3516V13.6621H15.2753V15.5858Z" fill="#395061" />
                <path d="M16.7866 16.0986H20.4247C20.5663 16.0986 20.6811 15.9839 20.6811 15.8423C20.6811 15.7007 20.5663 15.5859 20.4247 15.5859H16.7866C16.645 15.5859 16.5303 15.7007 16.5303 15.8423C16.5303 15.9839 16.645 16.0986 16.7866 16.0986Z" fill="#395061" />
                <path d="M16.7866 15.0518H20.4247C20.5663 15.0518 20.6811 14.937 20.6811 14.7954C20.6811 14.6538 20.5663 14.5391 20.4247 14.5391H16.7866C16.645 14.5391 16.5303 14.6538 16.5303 14.7954C16.5303 14.937 16.645 15.0518 16.7866 15.0518Z" fill="#395061" />
                <path d="M13.9606 22.2155C14.0216 22.2796 14.1072 22.3164 14.1957 22.3165H14.196C14.2843 22.3165 14.3699 22.2799 14.4309 22.216L14.8489 21.7781C14.9466 21.6757 14.9429 21.5135 14.8405 21.4157C14.7381 21.3179 14.5758 21.3216 14.478 21.4241L14.1963 21.7192L14.1483 21.6686C14.0507 21.566 13.8885 21.5619 13.7858 21.6594C13.6832 21.7569 13.679 21.9192 13.7766 22.0218L13.9606 22.2155Z" fill="#395061" />
                <path d="M15.3607 20.3564H13.2661C13.0305 20.3564 12.8389 20.5481 12.8389 20.7837V22.8783C12.8389 23.1139 13.0305 23.3055 13.2661 23.3055H15.3607C15.5963 23.3055 15.788 23.1139 15.788 22.8783V20.7837C15.788 20.5481 15.5963 20.3564 15.3607 20.3564ZM15.2753 22.7928H13.3516V20.8691H15.2753V22.7928Z" fill="#395061" />
                <path d="M21.1425 22.793H16.7866C16.645 22.793 16.5303 22.9077 16.5303 23.0493C16.5303 23.1909 16.645 23.3057 16.7866 23.3057H21.1425C21.2841 23.3057 21.3988 23.1909 21.3988 23.0493C21.3988 22.9077 21.2841 22.793 21.1425 22.793Z" fill="#395061" />
                <path d="M16.5303 22.0024C16.5303 22.144 16.645 22.2588 16.7866 22.2588H21.1425C21.2841 22.2588 21.3988 22.144 21.3988 22.0024C21.3988 21.8608 21.2841 21.7461 21.1425 21.7461H16.7866C16.645 21.7461 16.5303 21.8609 16.5303 22.0024Z" fill="#395061" />
                <path d="M22.6784 23.0044C22.5369 23.0044 22.4221 23.1191 22.4221 23.2607V24.8784C22.4221 25.0305 22.2983 25.1543 22.1461 25.1543H12.0913C11.9392 25.1543 11.8154 25.0305 11.8154 24.8784V11.1284C11.8154 10.9762 11.9392 10.8525 12.0913 10.8525H14.2579V11.4808C14.2579 11.8582 14.5649 12.1652 14.9423 12.1652H19.2952C19.6726 12.1652 19.9796 11.8582 19.9796 11.4808V10.8524H20.6538C20.7954 10.8524 20.9101 10.7377 20.9101 10.5961C20.9101 10.4545 20.7954 10.3397 20.6538 10.3397H19.9796V9.8757C19.9796 9.49833 19.6726 9.19129 19.2952 9.19129H18.4374V8.95244C18.4374 8.51932 18.085 8.16699 17.652 8.16699H16.5856C16.1525 8.16699 15.8001 8.51935 15.8001 8.95244V9.19125H14.9423C14.5649 9.19125 14.2579 9.49829 14.2579 9.87567V10.3397H12.0913C11.6565 10.3397 11.3027 10.6935 11.3027 11.1283V24.8783C11.3027 25.3132 11.6565 25.6669 12.0913 25.6669H22.1462C22.581 25.6669 22.9348 25.3131 22.9348 24.8783V23.2607C22.9348 23.1191 22.82 23.0044 22.6784 23.0044ZM14.7706 9.8757C14.7706 9.78099 14.8476 9.70398 14.9423 9.70398H15.9257C16.1392 9.70398 16.3128 9.53039 16.3128 9.31697V8.95244C16.3128 8.80205 16.4352 8.67969 16.5856 8.67969H17.652C17.8023 8.67969 17.9247 8.80205 17.9247 8.95244V9.31693C17.9247 9.53035 18.0983 9.70395 18.3118 9.70395H19.2952C19.3899 9.70395 19.4669 9.78099 19.4669 9.87567V11.4807C19.4669 11.5754 19.3899 11.6524 19.2952 11.6524H14.9423C14.8476 11.6524 14.7706 11.5754 14.7706 11.4807V9.8757Z" fill="#395061" />
                <path d="M24.3345 11.8662H23.8415V9.92986C23.8415 9.45965 23.459 9.07715 22.9888 9.07715H22.3781C21.9079 9.07715 21.5254 9.45965 21.5254 9.92986C21.5254 9.92986 21.5256 19.1793 21.5257 19.1811C21.5256 19.1828 21.5254 19.1846 21.5254 19.1864V20.1337C21.5254 20.2979 21.559 20.4573 21.6254 20.6075L22.2671 22.0607C22.341 22.2281 22.5005 22.3321 22.6834 22.3321C22.8664 22.3321 23.0259 22.2281 23.0998 22.0607L23.7415 20.6076C23.8078 20.4573 23.8415 20.2979 23.8415 20.1337V19.1864C23.8415 19.1846 23.8412 19.1828 23.8412 19.1811C23.8412 19.1793 23.8415 17.5093 23.8415 17.5093C23.8415 17.3677 23.7267 17.2529 23.5851 17.2529C23.4436 17.2529 23.3288 17.3677 23.3288 17.5093V18.9194H22.0381V11.493H23.3288V16.4429C23.3288 16.5845 23.4436 16.6992 23.5851 16.6992C23.7267 16.6992 23.8415 16.5845 23.8415 16.4429V12.3789H24.3345C24.3439 12.3789 24.3516 12.3866 24.3516 12.396V15.2683C24.3516 15.4099 24.4664 15.5246 24.608 15.5246C24.7495 15.5246 24.8643 15.4099 24.8643 15.2683V12.396C24.8643 12.1039 24.6267 11.8662 24.3345 11.8662ZM23.3288 20.1337C23.3288 20.2262 23.3098 20.3159 23.2725 20.4005L22.6835 21.7344L22.0944 20.4005C22.0571 20.3159 22.0382 20.2262 22.0382 20.1338V19.4428H23.3288V20.1337H23.3288ZM22.0381 10.9803V9.92986C22.0381 9.74239 22.1906 9.58984 22.3781 9.58984H22.9888C23.1763 9.58984 23.3288 9.74239 23.3288 9.92986V10.9803H22.0381V10.9803Z" fill="#395061" />
            </g>
            <defs>
                <clipPath id="clip0_796_4559">
                    <rect width="17.5" height="17.5" fill="white" transform="translate(9.33301 8.16699)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AssessmentIcon