import React from 'react'

function ArrowLeft({ width, height, color, className }) {
    return (
        <svg width={width} height={height} fill={color} className={className} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_795_4645)">
                <path d="M22.6854 1.76719L10.3366 14.1159C10.1085 14.3536 9.98111 14.6703 9.98111 14.9997C9.98111 15.3291 10.1085 15.6458 10.3366 15.8834L22.6779 28.2247L20.9104 29.9922L8.5691 17.6509C7.88726 16.9364 7.50684 15.9867 7.50684 14.9991C7.50684 14.0114 7.88726 13.0617 8.5691 12.3472L20.9179 -0.000313391L22.6854 1.76719Z" />
            </g>
            <defs>
                <clipPath id="clip0_795_4645">
                    <rect width="30" height="30" fill="white" transform="translate(0 30) rotate(-90)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowLeft