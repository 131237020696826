import { PDFDownloadLink } from '@react-pdf/renderer';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import JsonViewer from 'components/JsonViewer';
import moment from 'moment';
import Paginate from 'components/Paginate';
import InvoiceIcon from 'svgIcons/InvoiceIcon';
import InvoicePDF from '../../../../components/InvoicePDF'

function ViewEarnHistory() {
    const params = useParams();
    const [earnHistoryList, setEarnHistoryList] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);

    useEffect(() => {
        getEarnHistory();
    }, [])

    const getEarnHistory = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_EARNINGS_HISTORY + `?instructorId=${params.teacherId}`, jsonData);
            setEarnHistoryList(resp.data)
        } catch (error) {

        }

    }
    useEffect(() => {
        if (earnHistoryList) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = earnHistoryList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(earnHistoryList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [earnHistoryList, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % earnHistoryList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };


    const generateTeacherInvoice = async (instructorId, courseName, studentId, purchaseDate, amount) => {
        try {
            const jsonData = { "userType": "ADMIN" }

            const pdfStreamString = await axiosFetch.post(API.GENERATE_TEACHER_INVOICE + `?instructorId=${instructorId}&courseName=${courseName}&studentId=${studentId}&purchaseDate=${purchaseDate}&amount=${amount}`, jsonData);

            const linkSource = `data:application/pdf;base64,${pdfStreamString}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = 'invoice.pdf';
            downloadLink.click();
        } catch (error) {

        }
    }

    return (
        <div className='py-5 px-4'>
            <div className="py-5 bg-white rounded-3">
                <div className="px-lg-4">
                    <div className="table-responsive">
                        <table className="table table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th className='fw-500 text-muted fz-15px'>Course Name</th>
                                    <th className='fw-500 text-muted fz-15px'>Timestamp</th>
                                    <th className='fw-500 text-muted fz-15px'>Amount</th>
                                    <th className='fw-500 text-muted fz-15px'>Status</th>
                                    <th className='fw-500 text-muted fz-15px'>Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!earnHistoryList ? <tr className='text-center'>
                                        <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>                             
                                    </tr> : earnHistoryList.length ? (
                                      earnHistoryList.slice().sort((a, b) => new Date(b[0]?.studentDetails?.purchaseDate) - new Date(a[0]?.studentDetails?.purchaseDate)).slice(itemOffset, itemOffset + Number(itemsPerPage)).map((value, index, array) => (

                                        <tr key={value[1]?.instructorId + index}>
                                            <td>{value[1]?.courseName}</td>
                                            <td>{moment(value[0]?.studentDetails?.purchaseDate)?.format("DD MMMM, YYYY")}</td>
                                            <td> ₹{value[1]?.amount}</td>
                                            <td>{value[0]?.studentDetails?.orderStatus}</td>
                                            <td>
                                                {value[0]?.studentDetails?.orderStatus === "PAYMENT_COMPLETED" && (
                                                    <PDFDownloadLink className="btn text-white py-2 rounded-3 flex-grow-1" style={{ background: '#fff', flexBasis: 0 }} document={<InvoicePDF
                                                        data={{
                                                            courseName: value[1].courseName,
                                                            amount: value[1].amount,
                                                            taxPersentage: 5,
                                                            discountPercentage: value[0]?.studentDetails?.discountPercentage,
                                                            discountValue: value[0]?.studentDetails?.discountValue
                                                        }}
                                                    />}
                                                        fileName="invoice.pdf">
                                                        {({ loading, error }) => (loading ? <InvoiceIcon /> : <InvoiceIcon />)}
                                                    </PDFDownloadLink>
                                                )}
                                            </td>
                                        </tr>
                                    ))) : <tr className='text-center'>
                                        <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    {/* ------- */}
                </div>
            </div>
            <div className="py-2"></div>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
            <JsonViewer data={earnHistoryList} />

        </div>
    )
}

export default ViewEarnHistory