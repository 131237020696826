import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer'
import Loader from 'components/Loader'
import { API } from 'constants/apiEndPoints'
import { useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AssessmentIcon from 'svgIcons/AssessmentIcon'
import PlayIcon from 'svgIcons/PlayIcon'
import RightArrowIcon from 'svgIcons/RightArrowIcon'
import { getLocalStorage, setLocalStorage } from 'utils/localStorage'
import lessonStyles from '../../../../../moduleCss/chapter.module.css'
function NewVideos() {

    const [newVideos, setNewVideos] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchNewVideos();
    }, [])
    const fetchNewVideos = async () => {
        const clickedCourse = getLocalStorage("clickedCourse")
        try {
            const resp = await axiosFetch(API.NEW_VIDEOS + `?courseStatus=${clickedCourse?.courseStatus}&courseCode=${clickedCourse?.courseCode}`);
            setNewVideos(resp.data);

        } catch (error) {

        }
    }
    const navigateLsn = (cData) => {
        setLocalStorage({ key: "uploadedVideoClickedLesson", value: cData })
        setLocalStorage({ key: "uploadedVideoClickedChapter", value: cData })
        navigate('/all-courses/in-review/uploaded/chapter/lesson')
    }
    const navigateAsm = (cData) => {
        setLocalStorage({ key: "uploadedVideoClickedLesson", value: cData })
        setLocalStorage({ key: "uploadedVideoClickedChapter", value: cData })
        navigate('/all-courses/in-review/uploaded/chapter/assessment')
    }

    return (
        <div>
            <JsonViewer data={newVideos} />
            <ul className={`${lessonStyles.list} list-unstyled`}>
                {
                    !newVideos ? <Loader className="text-prime my-3" center={true} /> : newVideos?.length ?
                        <>
                            {
                                newVideos?.map((cData, i) => {
                                    return cData?.isAssesment ?
                                        //Assessment
                                        <li key={cData.courseCode + i} className='fz-15px fw-500 text-dark' role={"button"} onClick={() => navigateAsm(cData)}>
                                            <Stack direction="horizontal" gap={1}>
                                                <div><AssessmentIcon width={29} /> {cData?.lessonName}</div>
                                                <div className="ms-auto"><RightArrowIcon width={8} /></div>
                                            </Stack>
                                        </li> :
                                        //Lesson
                                        <li key={cData.courseCode + i} className='fz-15px fw-500 text-dark' role={"button"} onClick={() => navigateLsn(cData)}>
                                            <Stack direction="horizontal" gap={1}>
                                                <div><PlayIcon width={29} /> {cData?.lessonName}</div>
                                                <div className="ms-auto"><RightArrowIcon width={8} /></div>
                                            </Stack>
                                        </li>


                                })
                            }
                        </>
                        : <h6 className='text-muted text-center'>No data available </h6>
                }

            </ul>
        </div>
    )
}

export default NewVideos