import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import React from 'react'
import { Stack } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'utils/confirmAlert';
import { getLocalStorage,setLocalStorage } from 'utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function RejectApproveHeader({ showBtn = true }) {
    let clickedCourse = getLocalStorage("clickedCourse");
    const [courseData, setCourseData] = useState(getLocalStorage("clickedCourse"));
    const [reason, setReason] = useState("");
    const navigate = useNavigate()
    const fetchCourse = async () => {
        try {
            const resp = await axiosFetch(API.ADMIN_SEARCH + `?courseStatus=INREVIEW&courseCode=${clickedCourse.courseCode}`);
            setLocalStorage({ key: "clickedCourse", value: resp?.data[0] })
            clickedCourse = getLocalStorage("clickedCourse");
            if(clickedCourse.inReviewAssessmentCount != 0)
            toast.error('Please approve Assessment/Lesson')
            else if(clickedCourse.inReviewLessonCount != 0)
            toast.error('Please approve Lesson')
            else if(clickedCourse.inReviewLiveAssessmentCount != 0)
            toast.error('Please approve live Assessment')
            else if(clickedCourse.inReviewLessonFAQCount!=0)
            toast.error('Please approve FAQ')
            else{
                return {status:200,message:"no error"}
            }
        } catch (error) {

        }
    }

    // ------------Recorded Course------------
    const onReject = async () => {

        const isTrue = await confirmAlert({ title: "Are you sure you want to deactivate this course?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    courseCode: courseData?.courseCode,
                    commonRejectionComments: reason
                }
                const resp = await axiosFetch.post(API.DEACTIVATE_COURSE, jsonData);
                toast.success('Course deactivated successfully');
                navigate('/all-courses')

            } catch (error) {

            }

        }
    }


    const onActivate = async () => {
        try {
            let courseCodeResponse=await fetchCourse();
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
            }
            if(courseCodeResponse.status==200){
            const resp = await axiosFetch.post(API.ACTIVATE_COURSE, jsonData);
            toast.success('Course activated successfully');
            navigate('/all-courses')
            }
        } catch (error) {

        }

    }



    return (
        <>
            <Stack direction="horizontal" gap={4}>

                <JsonViewer data={courseData} />
                <h6>{courseData?.courseName}</h6>

                <div className="ms-auto" />

                {showBtn ?
                    <>
                        <textarea rows={"2"} placeholder="Deactivate reason" className={`input py-2 fz-13px w-25 px-2`} onInput={(e) => { if (e.target.value.trim() == "") { e.target.value = ""; } }} onChange={(e) => setReason(e.target.value)} />
                        <button className="textTomato fz-14px fw-500  border-0 bg-transparent " onClick={onReject} >Deactivate</button>
                        <button className="bgGreenLight textGreen fz-14px fw-500 px-5 border-0 py-2 rounded-1" onClick={onActivate}>Activate</button>

                    </>
                    : ""
                }

            </Stack>

        </>
    )
}

export default RejectApproveHeader