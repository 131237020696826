import { PDFDownloadLink } from '@react-pdf/renderer';
import JsonViewer from 'components/JsonViewer';
import Paginate from 'components/Paginate';
import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InvoiceIcon from 'svgIcons/InvoiceIcon';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import PdfDownloadFormat from './PdfDownloadFormat';

function ViewPayoutHistory() {
    const params = useParams();
    const [payoutHistoryList, setPayoutHistoryList] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    useEffect(() => {
        getPayoutHistory();
    }, [])
    const getPayoutHistory = async () => {
        try {
            const jsonData = { "userType": "ADMIN", "instructorId": params.teacherId }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_PAYOUT_HISTORY, jsonData);
            setPayoutHistoryList(resp.data)
        } catch (error) {

        }

    }
    useEffect(() => {
        if (payoutHistoryList) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = payoutHistoryList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(payoutHistoryList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [payoutHistoryList, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % payoutHistoryList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div className='py-5 px-4'>
            <div className="py-5 bg-white rounded-3">
                <div className="px-lg-4">

                    {/* <div className="d-flex align-items-lg-center justify-content-lg-between">

                        <div className="bg-pink px-4 py-2 rounded-2 d-flex align-items-center" role={"button"}>

                            <p className="ms-2 my-0 fz-14px fw-500 text-prime">Print</p>
                        </div>
                    </div> */}
                    {/* <div className="py-2"></div> */}
                    {/* ------- */}
                    <div className="table-responsive">
                        <table className="table table-borderless align-middle">
                            <thead>
                                <tr>
                                    <th className='fw-500 text-muted fz-15px'>Timestamp</th>
                                    <th className='fw-500 text-muted fz-15px'>Amount</th>
                                    <th className='fw-500 text-muted fz-15px'>Payment Method</th>
                                    <th className='fw-500 text-muted fz-15px'>Status</th>
                                    <th className='fw-500 text-muted fz-15px'>Invoice</th>


                                </tr>
                            </thead>

                            <tbody>

                                {
                                    !currentItems ? <tr className='text-center'>
                                        <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                                    </tr> : currentItems?.length ? (currentItems.sort((a, b) => new Date(b?.requestedDate) - new Date(a?.requestedDate)).map((pyData, index, array) => (

                                        <tr key={pyData?._id}>
                                            <td>{moment(pyData?.requestedDate).format("DD MMMM, YYYY")}</td>
                                            <td>₹{pyData?.requestedAmount}</td>
                                            <td>{"Bank Transaction"}</td>
                                            <td>{pyData?.requestedstatus}</td>

                                            <td role={"button"}>

                                                {pyData?.requestedstatus === "PAID" && (
                                                    <PDFDownloadLink className="btn text-white py-2 rounded-3 flex-grow-1"
                                                        style={{ background: '#fff', flexBasis: 0 }}
                                                        document={<PdfDownloadFormat
                                                            data={pyData}
                                                        />}
                                                        fileName="invoice.pdf">
                                                        {({ loading, error }) => (loading ? <InvoiceIcon /> : <InvoiceIcon />)}
                                                    </PDFDownloadLink>
                                                )}
                                            </td>

                                        </tr>


                                    ))) : <tr className='text-center'>
                                        <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    {/* ------- */}
                </div>
            </div>
            <div className="py-2"></div>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
            <JsonViewer data={payoutHistoryList} />

        </div>
    )
}

export default ViewPayoutHistory
