import React from 'react'

function DashboardIcon({ width, height, color }) {
    return (
        <svg viewBox="0 0 25 25" width={width} height={height} fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M3.64591 8.33329H8.85425C9.54492 8.33329 10.2073 8.05893 10.6957 7.57055C11.184 7.08218 11.4584 6.41979 11.4584 5.72913V3.64579C11.4584 2.95513 11.184 2.29274 10.6957 1.80437C10.2073 1.31599 9.54492 1.04163 8.85425 1.04163H3.64591C2.95525 1.04163 2.29287 1.31599 1.80449 1.80437C1.31611 2.29274 1.04175 2.95513 1.04175 3.64579V5.72913C1.04175 6.41979 1.31611 7.08218 1.80449 7.57055C2.29287 8.05893 2.95525 8.33329 3.64591 8.33329V8.33329ZM2.08341 3.64579C2.08341 3.23139 2.24803 2.83396 2.54106 2.54094C2.83409 2.24791 3.23151 2.08329 3.64591 2.08329H8.85425C9.26865 2.08329 9.66608 2.24791 9.9591 2.54094C10.2521 2.83396 10.4167 3.23139 10.4167 3.64579V5.72913C10.4167 6.14353 10.2521 6.54096 9.9591 6.83398C9.66608 7.12701 9.26865 7.29163 8.85425 7.29163H3.64591C3.23151 7.29163 2.83409 7.12701 2.54106 6.83398C2.24803 6.54096 2.08341 6.14353 2.08341 5.72913V3.64579Z" />
            <path d="M21.3542 16.6666H16.1459C15.4552 16.6666 14.7929 16.941 14.3045 17.4294C13.8161 17.9177 13.5417 18.5801 13.5417 19.2708V21.3541C13.5417 22.0448 13.8161 22.7072 14.3045 23.1956C14.7929 23.6839 15.4552 23.9583 16.1459 23.9583H21.3542C22.0449 23.9583 22.7073 23.6839 23.1957 23.1956C23.684 22.7072 23.9584 22.0448 23.9584 21.3541V19.2708C23.9584 18.5801 23.684 17.9177 23.1957 17.4294C22.7073 16.941 22.0449 16.6666 21.3542 16.6666V16.6666ZM22.9167 21.3541C22.9167 21.7685 22.7521 22.166 22.4591 22.459C22.1661 22.752 21.7686 22.9166 21.3542 22.9166H16.1459C15.7315 22.9166 15.3341 22.752 15.0411 22.459C14.748 22.166 14.5834 21.7685 14.5834 21.3541V19.2708C14.5834 18.8564 14.748 18.459 15.0411 18.1659C15.3341 17.8729 15.7315 17.7083 16.1459 17.7083H21.3542C21.7686 17.7083 22.1661 17.8729 22.4591 18.1659C22.7521 18.459 22.9167 18.8564 22.9167 19.2708V21.3541Z" />
            <path d="M3.64591 23.9583H8.85425C9.54492 23.9583 10.2073 23.684 10.6957 23.1956C11.184 22.7072 11.4584 22.0448 11.4584 21.3542V11.9792C11.4584 11.2885 11.184 10.6261 10.6957 10.1377C10.2073 9.64937 9.54492 9.375 8.85425 9.375H3.64591C2.95525 9.375 2.29287 9.64937 1.80449 10.1377C1.31611 10.6261 1.04175 11.2885 1.04175 11.9792V21.3542C1.04175 22.0448 1.31611 22.7072 1.80449 23.1956C2.29287 23.684 2.95525 23.9583 3.64591 23.9583V23.9583ZM2.08341 11.9792C2.08341 11.5648 2.24803 11.1673 2.54106 10.8743C2.83409 10.5813 3.23151 10.4167 3.64591 10.4167H8.85425C9.26865 10.4167 9.66608 10.5813 9.9591 10.8743C10.2521 11.1673 10.4167 11.5648 10.4167 11.9792V21.3542C10.4167 21.7686 10.2521 22.166 9.9591 22.459C9.66608 22.752 9.26865 22.9167 8.85425 22.9167H3.64591C3.23151 22.9167 2.83409 22.752 2.54106 22.459C2.24803 22.166 2.08341 21.7686 2.08341 21.3542V11.9792Z" />
            <path d="M21.3542 1.04163H16.1459C15.4552 1.04163 14.7929 1.31599 14.3045 1.80437C13.8161 2.29274 13.5417 2.95513 13.5417 3.64579V13.0208C13.5417 13.7115 13.8161 14.3738 14.3045 14.8622C14.7929 15.3506 15.4552 15.625 16.1459 15.625H21.3542C22.0449 15.625 22.7073 15.3506 23.1957 14.8622C23.684 14.3738 23.9584 13.7115 23.9584 13.0208V3.64579C23.9584 2.95513 23.684 2.29274 23.1957 1.80437C22.7073 1.31599 22.0449 1.04163 21.3542 1.04163V1.04163ZM22.9167 13.0208C22.9167 13.4352 22.7521 13.8326 22.4591 14.1256C22.1661 14.4187 21.7686 14.5833 21.3542 14.5833H16.1459C15.7315 14.5833 15.3341 14.4187 15.0411 14.1256C14.748 13.8326 14.5834 13.4352 14.5834 13.0208V3.64579C14.5834 3.23139 14.748 2.83396 15.0411 2.54094C15.3341 2.24791 15.7315 2.08329 16.1459 2.08329H21.3542C21.7686 2.08329 22.1661 2.24791 22.4591 2.54094C22.7521 2.83396 22.9167 3.23139 22.9167 3.64579V13.0208Z" />
        </svg>
    )
}

export default DashboardIcon