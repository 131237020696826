import React from 'react'

function IndependentAssessmentIcon() {
    return (
        <svg width="100" height="100" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g  >
                <circle cx="54" cy="50" r="50" fill="#F0E1EB" />
            </g>
            <g clipPath="url(#clip0_713_5301)">
                <path d="M42.4583 53.1682C42.6323 53.3514 42.8771 53.4568 43.1297 53.4572H43.1311C43.3833 53.4572 43.6277 53.3526 43.8022 53.1701L44.9965 51.9189C45.2758 51.6263 45.265 51.1626 44.9724 50.8833C44.6797 50.604 44.2161 50.6147 43.9368 50.9074L43.1319 51.7506L42.9946 51.6061C42.7159 51.3128 42.2523 51.3011 41.9592 51.5796C41.6659 51.8583 41.654 52.3219 41.9326 52.6152L42.4583 53.1682Z" fill="#395061" />
                <path d="M47.6782 49.0771C47.6782 48.404 47.1306 47.8564 46.4575 47.8564H40.4729C39.7998 47.8564 39.2522 48.404 39.2522 49.0771V55.0616C39.2522 55.7348 39.7998 56.2823 40.4729 56.2823H46.4575C47.1306 56.2823 47.6782 55.7348 47.6782 55.0616V49.0771ZM46.2133 54.8176H40.717V49.3214H46.2133V54.8176Z" fill="#395061" />
                <path d="M50.5315 56.2822H60.926C61.3305 56.2822 61.6584 55.9544 61.6584 55.5498C61.6584 55.1452 61.3305 54.8174 60.926 54.8174H50.5315C50.127 54.8174 49.7991 55.1452 49.7991 55.5498C49.7991 55.9544 50.127 56.2822 50.5315 56.2822Z" fill="#395061" />
                <path d="M50.5315 53.291H60.926C61.3305 53.291 61.6584 52.9632 61.6584 52.5586C61.6584 52.154 61.3305 51.8262 60.926 51.8262H50.5315C50.127 51.8262 49.7991 52.154 49.7991 52.5586C49.7991 52.9632 50.127 53.291 50.5315 53.291Z" fill="#395061" />
                <path d="M42.4585 42.8834C42.6326 43.0666 42.8774 43.1718 43.1301 43.172H43.131C43.3833 43.172 43.6279 43.0673 43.8021 42.8849L44.9964 41.6337C45.2757 41.3411 45.265 40.8775 44.9724 40.5981C44.6798 40.3189 44.2161 40.3295 43.9368 40.6222L43.1318 41.4653L42.9946 41.3209C42.7159 41.0276 42.2523 41.0159 41.9592 41.2944C41.6659 41.573 41.654 42.0367 41.9326 42.33L42.4585 42.8834Z" fill="#395061" />
                <path d="M47.6782 38.791C47.6782 38.1179 47.1306 37.5703 46.4575 37.5703H40.4729C39.7998 37.5703 39.2522 38.1179 39.2522 38.791V44.7756C39.2522 45.4487 39.7998 45.9963 40.4729 45.9963H46.4575C47.1306 45.9963 47.6782 45.4487 47.6782 44.7756V38.791ZM46.2133 44.5314H40.717V39.0352H46.2133V44.5314Z" fill="#395061" />
                <path d="M50.5315 45.9961H60.926C61.3305 45.9961 61.6584 45.6683 61.6584 45.2637C61.6584 44.8591 61.3305 44.5312 60.926 44.5312H50.5315C50.127 44.5312 49.7991 44.8591 49.7991 45.2637C49.7991 45.6683 50.127 45.9961 50.5315 45.9961Z" fill="#395061" />
                <path d="M50.5315 43.0059H60.926C61.3305 43.0059 61.6584 42.678 61.6584 42.2734C61.6584 41.8688 61.3305 41.541 60.926 41.541H50.5315C50.127 41.541 49.7991 41.8688 49.7991 42.2734C49.7991 42.678 50.127 43.0059 50.5315 43.0059Z" fill="#395061" />
                <path d="M42.4585 63.4733C42.6326 63.6565 42.8774 63.7617 43.1301 63.7619H43.131C43.3833 63.7619 43.6279 63.6572 43.8021 63.4748L44.9964 62.2236C45.2757 61.931 45.265 61.4675 44.9724 61.1881C44.6798 60.9087 44.2161 60.9194 43.9368 61.2121L43.1318 62.0553L42.9946 61.9108C42.7159 61.6177 42.2523 61.6058 41.9592 61.8844C41.6659 62.163 41.654 62.6266 41.9326 62.9199L42.4585 63.4733Z" fill="#395061" />
                <path d="M46.4575 58.1602H40.4729C39.7998 58.1602 39.2522 58.7077 39.2522 59.3809V65.3654C39.2522 66.0386 39.7998 66.5861 40.4729 66.5861H46.4575C47.1306 66.5861 47.6782 66.0386 47.6782 65.3654V59.3809C47.6782 58.7077 47.1306 58.1602 46.4575 58.1602ZM46.2133 65.1213H40.717V59.625H46.2133V65.1213Z" fill="#395061" />
                <path d="M62.9768 65.1211H50.5315C50.127 65.1211 49.7991 65.4489 49.7991 65.8535C49.7991 66.2581 50.127 66.5859 50.5315 66.5859H62.9768C63.3813 66.5859 63.7092 66.2581 63.7092 65.8535C63.7092 65.4489 63.3813 65.1211 62.9768 65.1211Z" fill="#395061" />
                <path d="M49.7991 62.8633C49.7991 63.2679 50.127 63.5957 50.5315 63.5957H62.9768C63.3813 63.5957 63.7092 63.2679 63.7092 62.8633C63.7092 62.4587 63.3813 62.1309 62.9768 62.1309H50.5315C50.127 62.1309 49.7991 62.4588 49.7991 62.8633Z" fill="#395061" />
                <path d="M67.3655 65.7266C66.961 65.7266 66.6331 66.0544 66.6331 66.459V71.0809C66.6331 71.5155 66.2794 71.8691 65.8447 71.8691H37.1167C36.682 71.8691 36.3284 71.5155 36.3284 71.0809V31.795C36.3284 31.3604 36.682 31.0067 37.1167 31.0067H43.3069V32.802C43.3069 33.8802 44.1841 34.7573 45.2624 34.7573H57.6991C58.7773 34.7573 59.6546 33.8802 59.6546 32.802V31.0066H61.5808C61.9853 31.0066 62.3133 30.6788 62.3133 30.2742C62.3133 29.8696 61.9853 29.5418 61.5808 29.5418H59.6546V28.216C59.6546 27.1378 58.7773 26.2605 57.6991 26.2605H55.2483V25.5781C55.2483 24.3406 54.2416 23.334 53.0042 23.334H49.9573C48.7199 23.334 47.7131 24.3407 47.7131 25.5781V26.2604H45.2624C44.1841 26.2604 43.3069 27.1377 43.3069 28.2159V29.5417H37.1167C35.8743 29.5417 34.8635 30.5525 34.8635 31.7948V71.0807C34.8635 72.323 35.8743 73.3338 37.1167 73.3338H65.8448C67.0872 73.3338 68.098 72.3229 68.098 71.0807V66.459C68.0979 66.0544 67.77 65.7266 67.3655 65.7266ZM44.7717 28.216C44.7717 27.9454 44.9919 27.7254 45.2624 27.7254H48.0721C48.6819 27.7254 49.178 27.2294 49.178 26.6196V25.5781C49.178 25.1484 49.5276 24.7988 49.9573 24.7988H53.0042C53.4338 24.7988 53.7835 25.1484 53.7835 25.5781V26.6195C53.7835 27.2293 54.2796 27.7253 54.8893 27.7253H57.6991C57.9696 27.7253 58.1897 27.9454 58.1897 28.2159V32.8018C58.1897 33.0723 57.9696 33.2923 57.6991 33.2923H45.2624C44.9919 33.2923 44.7717 33.0722 44.7717 32.8018V28.216Z" fill="#395061" />
                <path d="M72.0971 33.9024H70.6883V28.3699C70.6883 27.0265 69.5954 25.9336 68.252 25.9336H66.5072C65.1639 25.9336 64.0709 27.0265 64.0709 28.3699C64.0709 28.3699 64.0716 54.7969 64.0717 54.8019C64.0716 54.807 64.0709 54.8121 64.0709 54.8172V57.5238C64.0709 57.993 64.167 58.4484 64.3566 58.8775L66.1901 63.0296C66.4012 63.5077 66.857 63.8048 67.3796 63.8048C67.9023 63.8048 68.358 63.5077 68.5692 63.0296L70.4027 58.8776C70.5922 58.4483 70.6883 57.9929 70.6883 57.5237V54.8172C70.6883 54.812 70.6876 54.807 70.6875 54.8019C70.6876 54.7969 70.6883 50.0254 70.6883 50.0254C70.6883 49.6208 70.3604 49.293 69.9559 49.293C69.5514 49.293 69.2235 49.6208 69.2235 50.0254V54.0543H65.5359V32.836H69.2235V46.9785C69.2235 47.3831 69.5514 47.7109 69.9559 47.7109C70.3604 47.7109 70.6883 47.3831 70.6883 46.9785V35.3673H72.097C72.1239 35.3673 72.1458 35.3893 72.1458 35.4161V43.6226C72.1458 44.0271 72.4737 44.355 72.8782 44.355C73.2827 44.355 73.6107 44.0271 73.6107 43.6226V35.4161C73.6108 34.5814 72.9318 33.9024 72.0971 33.9024ZM69.2235 57.5238C69.2235 57.788 69.1694 58.0443 69.0627 58.286L67.3797 62.0972L65.6967 58.2859C65.59 58.0443 65.536 57.788 65.536 57.5239V55.5497H69.2236V57.5238H69.2235ZM65.5359 31.3712V28.3699C65.5359 27.8343 65.9716 27.3984 66.5073 27.3984H68.2521C68.7877 27.3984 69.2236 27.8343 69.2236 28.3699V31.3711H65.5359V31.3712Z" fill="#395061" />
            </g>
            <defs>
                <filter id="filter0_d_713_5301" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_713_5301" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_713_5301" result="shape" />
                </filter>
                <clipPath id="clip0_713_5301">
                    <rect width="50" height="50" fill="white" transform="translate(29.2371 23.334)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IndependentAssessmentIcon