import Swal from "sweetalert2"

export const cLog = (data) => {
    console.log(data)
}
export const logJson = (data) => {
    console.log(JSON.stringify(data, null, 3))
}

export const cAlert = (data) => {
    Swal.fire(data)
}
export const alertJson = (data) => {
    Swal.fire(JSON.stringify(data, null, 3))
}