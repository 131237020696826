import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ActiveTable from '../teacherTable/activeTab/ActiveTable';
import DeactiveTable from '../teacherTable/deactiveTab/DeactiveTable';
import "../../../css/themeTab.css"
import DeactiveRequestList from '../teacherTable/deactiveRequest/DeactiveRequestList';
function ActiveDeactiveTeacherTab() {
    const [activeTableSearchValue, setActiveTableSearchValue] = useState("")
    const [deactiveTableSearchValue, setDeactiveTableSearchValue] = useState("")
    const [DeactiveRequestListSearchValue, setDeactiveRequestListSearchValue] = useState("")
    const [isShow, setIsShow] = useState("ActiveTable")

    return (
        <div className="px-4">
            <div className='py-5'>
                <div className="py-5 bg-white rounded-3">
                    <div className="px-lg-4 theme-tab">
                        <Tab.Container defaultActiveKey="active_tab" mountOnEnter>
                            <Row>
                                <Col sm={12} >
                                    <div className="d-flex justify-content-between">


                                        <div className={`d-inline-block bg-light rounded-1`}>
                                            <Nav variant="pills">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="active_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => { setIsShow("ActiveTable"); setActiveTableSearchValue("") }}>Active</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="deactive_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => { setIsShow("DeactiveTable"); setDeactiveTableSearchValue("") }}>Deactive</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="deactive_request_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => { setIsShow("DeactiveRequestList"); setDeactiveRequestListSearchValue("") }}>Deactive Request</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                        </div>
                                        {/* value={activeTableSearchValue}value={deactiveTableSearchValue}value={DeactiveRequestListSearchValue} */}
                                        {isShow == "ActiveTable" ?
                                            <input type={"search"}
                                                className="input py-1" placeholder='Search by name' onChange={(e) => setActiveTableSearchValue(e.target.value)} />
                                            : ""}
                                        {isShow == "DeactiveTable" ?
                                            <input type={"search"}
                                                className="input py-1" placeholder='Search by name' onChange={(e) => setDeactiveTableSearchValue(e.target.value)} />
                                            : ""}
                                        {isShow == "DeactiveRequestList" ?
                                            <input type={"search"}
                                                className="input py-1" placeholder='Search by name' onChange={(e) => setDeactiveRequestListSearchValue(e.target.value)} />
                                            : ""}


                                    </div>

                                </Col>
                                <div className="py-3"></div>
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="active_tab">
                                            <ActiveTable onSearch={activeTableSearchValue} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="deactive_tab">
                                            <DeactiveTable onSearch={deactiveTableSearchValue} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="deactive_request_tab">
                                            <DeactiveRequestList onSearch={DeactiveRequestListSearchValue} />
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveDeactiveTeacherTab