import BootstrapModal from 'components/BootstrapModal';
import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import BellOutlineIcon from 'svgIcons/BellOutlineIcon';
import FbIcon from 'svgIcons/FbIcon';
import InstaIcon from 'svgIcons/InstaIcon';
import LinkdinIcon from 'svgIcons/LinkdinIcon';
import TwitterIcon from 'svgIcons/TwitterIcon';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import sidebarAndDashboardStyles from '../../../../moduleCss/sidebarAndDashboard.module.css';
import viewDetailStyles from "../../../../moduleCss/viewDetail.module.css";
import Rating from 'components/Rating';
import ActiveInstructorCourseList from './instructorCourses/ActiveInstructorCourseList';
import DeactivatedInstructorCourseList from './instructorCourses/DeactivatedInstructorCourseList';
import { useRecoilState } from 'recoil';
import { instructorDataRecoilState } from 'recoil/atoms/atomsStore';
import OpenEyeIcon from 'svgIcons/OpenEyeIcon';
import CloseEyeIcon from 'svgIcons/CloseEyeIcon';
import RoundOutlineRightArrowIcon from 'svgIcons/RoundOutlineRightArrowIcon';
import MyS3Component from 'components/S3';

function ViewDetail() {
    const [newName, setNewName] = useState("");
    const [lastName, setLastName] = useState("");
    const [newEmail, setNewEmail] = useState("")
    const [newAbout, setNewAbout] = useState("")
    const [newNotification, setNewNotification] = useState("")
    const [accountActivity, setAccountActivity] = useState(null)
    const [rating, setRating] = useState("")
    const [studentCount, setStudentCount] = useState("")
    const [showChangeNameModal, setShowChangeNameModal] = useState(false);
    const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showChangeAboutModal, setShowChangeAboutModal] = useState(false);
    const [showSendNotificationModal, setSendNotificationModal] = useState(false);
    const [reason, setReason] = useState("");
    const [cashInfo, setcashInfo] = useState({ totalEarnings: "", totalCourses: "" })
    const [payout, setPayout] = useState("")
    const [isReadMore, setReadMore] = useState(false)
    const [instructorDataRecoilAtom, setInstructorDataRecoilAtom] = useRecoilState(instructorDataRecoilState)
    const [teacherDetail, setTeacherDetail] = useState("")
    const [isTextN, setTextN] = useState(false)
    const [isTextC, setTextC] = useState(false)
    const [changePassword, setChangePassword] = useState({
        password: '',
        confirmPassword: ''
    });
    const params = useParams();


    // ---api 1st use effect-----
    useEffect(() => {
        getInstructorById();
        getInstructorTotalEarnings();
        getInstructorTotalCourse();
        getTeacherRatings();
        getStudentCount();
        getPayout();

    }, [])
    const getInstructorById = async () => {
        try {
            const resp = await axiosFetch(API.GET_INSTRUCTOR_BY_ID + `?_id=${params.teacherId}`);
            setTeacherDetail(resp.data);
            setInstructorDataRecoilAtom(resp.data)
        } catch (error) {

        }
    }

    const getPayout = async () => {
        try {
            const jsonData = { "userType": "ADMIN", "instructorId": params.teacherId }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_TOTAL_PAYOUT, jsonData);
            setPayout(resp.data);
        } catch (error) {

        }

    }
    const getTeacherRatings = async () => {
        try {
            const jsonData = { "userType": "ADMIN", "userId": params.teacherId }
            const resp = await axiosFetch.post(API.GET_TEACHER_RATING, jsonData);
            setRating(resp.data);
        } catch (error) {

        }

    }
    const getInstructorTotalEarnings = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.INSTRUCTOR_TOTAL_EARNINGS + `?instructorId=${params.teacherId}`, jsonData);
            setcashInfo((prev) => ({ ...prev, totalEarnings: resp.data }))


        } catch (error) {

        }

    }
    const getInstructorTotalCourse = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.INSTRUCTOR_TOTAL_COURSES + `?instructorId=${params.teacherId}`, jsonData);
            setcashInfo((prev) => ({ ...prev, totalCourses: resp.data.allCoursesLength }))

        } catch (error) {

        }

    }
    const getStudentCount = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_NO_OF_STUDENTS + `?instructorId=${params.teacherId}`, jsonData);
            setStudentCount(resp.data)

        } catch (error) {

        }

    }
    const getAccountActivity = async () => {
        if (!accountActivity) {
            try {
                const jsonData = { "email": teacherDetail?.instructorEmail }
                const resp = await axiosFetch.post(API.TEACHER_ACCOUNT_DETAILS, jsonData);
                setAccountActivity(resp.data)
            } catch (error) {

            }
        }
    }

    const onChangeName = async (e) => {
        e.preventDefault();

        // {/* const [lastName, setLastName] = useState(""); */}

        try {
            const jsonData = {
                "userType": "ADMIN",
                // "newName": newName + " " + lastName,
                "firstName": newName,
                "middleName": "",
                "lastName": lastName,
            }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_CHANGE_NAME + `?_id=${params.teacherId}`, jsonData);
            getInstructorById();
            toast.success("Name was changed successfully");
            setShowChangeNameModal(false);
        } catch (error) {

        }

    }
    const onChangeEmail = async (e) => {
        e.preventDefault();

        try {
            const jsonData = { "userType": "ADMIN", "newEmail": newEmail }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_CHANGE_EMAIL + `?_id=${params.teacherId}`, jsonData);
            getInstructorById();
            toast.success("Email was changed successfully");
            setShowChangeEmailModal(false)
        } catch (error) {

        }

    }
    const onChangeAbout = async (e) => {
        e.preventDefault();

        try {
            const jsonData = { "userType": "ADMIN", "bioText": newAbout }
            const resp = await axiosFetch.post(API.TEACHER_CHANGE_BIO + `?_id=${params.teacherId}`, jsonData);
            getInstructorById();
            toast.success("About was changed successfully");
            setShowChangeAboutModal(false)
        } catch (error) {

        }

    }
    const onSendNotification = async (e) => {
        e.preventDefault();
        try {
            const jsonData = { "userType": "ADMIN", "content": newNotification }
            const resp = await axiosFetch.post(API.ADMIN_TEACHERS_SEND_NOTIFICATION + `?instructorId=${params.teacherId}`, jsonData);
            toast.success("Notification was sended successfully");
            setSendNotificationModal(false)
        } catch (error) {

        }

    }
    const onChangePassword = async (e) => {
        e.preventDefault();

        {/* const [changePassword, setChangePassword] = useState({
                password: '',
            confirmPassword: ''
            }); */}

        try {
            const jsonData = { "userType": "ADMIN", "newPassword": changePassword.password, "confirmPassword": changePassword.confirmPassword }
            const resp = await axiosFetch.post(API.ADMIN_TEACHER_RESET_PASSWORD + `?_id=${params.teacherId}`, jsonData);
            toast.success("Password was changed successfully");
            setShowChangePasswordModal(false)
        } catch (error) {

        }

    }

    return (
        <div className='px-4'>
            <JsonViewer data={studentCount} />
            <div className="bg-white rounded-2 px-5 py-4">
                <div className="row">
                    <div className="col-lg-8 my-lg-auto">
                        {teacherDetail?.profileImgPath ?
                            <MyS3Component src={teacherDetail?.profileImgPath} className={viewDetailStyles.userPic} /> :
                            <img src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"} className={viewDetailStyles.userPic} />
                        }
                        <div className="py-2"></div>
                        <div className="d-flex  fw-500 fz-14px text-prime"><Rating ratingCount={rating?.noofratings} /> <span className='mx-1'>({rating?.noofratings}) </span> {studentCount} Students | {teacherDetail?.yearsOfExperience} Years Experience | Available In {teacherDetail?.availablePerHours} Hrs / Week</div>
                    </div>
                    <div className="col-lg-4 my-lg-auto text-lg-end">
                        <button className='border-0 fz-13px fw-500 text-prime py-2 px-4 rounded-2 bg-pink-light' onClick={() => setSendNotificationModal(true)}><BellOutlineIcon width={"18"} height={"15"} /><span className='mx-1'></span> Send Notifications</button>
                    </div>
                </div>
                <div className="py-3"></div>
                <div className="d-flex align-items-center">
                    <b className='fw-500 fz-14px me-2'>Expert In </b>
                    {teacherDetail?.categories?.length ?
                        teacherDetail?.categories?.map((catData, indx) => (
                            <div key={indx + 2} className='fz-13px bg-prime text-white py-1 px-3 me-2 rounded-2'>{catData}</div>
                        ))
                        : ""}

                </div>
                <div className="py-3"></div>
                <div className="table-responsive">
                    <table className={`${viewDetailStyles.table} bg-light-300 rounded`}>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{teacherDetail?.fullName}</td>
                                <td><span className={viewDetailStyles.highlight} role={"button"} onClick={() => setShowChangeNameModal(true)}>Change Name <RoundOutlineRightArrowIcon /></span></td>

                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{teacherDetail?.instructorEmail}</td>
                                <td><span className={viewDetailStyles.highlight} role={"button"} onClick={() => setShowChangeEmailModal(true)}>Change Email <RoundOutlineRightArrowIcon /></span></td>

                            </tr>
                            <tr>
                                <td>Password</td>
                                <td>{"******"}</td>
                                <td><span className={viewDetailStyles.highlight} role={"button"} onClick={() => setShowChangePasswordModal(true)}>Change Password <RoundOutlineRightArrowIcon /></span></td>

                            </tr>
                            <tr>
                                <td>Account activity</td>
                                <td >{teacherDetail?.fullName}</td>
                                <td><span className={viewDetailStyles.highlight} role={"button"} onClick={() => { getAccountActivity(); setShowViewModal(true) }}>View <RoundOutlineRightArrowIcon /></span></td>

                            </tr>
                            <tr>
                                <td>About</td>
                                <td className='white-space-pre-wrap'>{teacherDetail?.aboutYou?.length > 120 && !isReadMore ? <>{teacherDetail?.aboutYou.substring(120, 0)}...  <small role={"button"} onClick={() => setReadMore(true)}>Read more</small></> : teacherDetail?.aboutYou}</td>
                                <td><span className={viewDetailStyles.highlight} role={"button"} onClick={() => setShowChangeAboutModal(true)}>Change <RoundOutlineRightArrowIcon /></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* ==== */}
                <div className="py-3"></div>
                <h6 className='text-prime fz-15px'>Social Link</h6>

                <div className="bg-light-300 rounded-2 pe-4 my-2 py-4 socIcon">
                    <div className='d-flex my-3'>
                        <FbIcon />
                        <div className='d-flex flex-column w-100'>
                            <input readOnly defaultValue={teacherDetail?.facebook} type="text" className='bg-white py-2 px-2 fz-14px rounded-1 border-0' />

                        </div>
                    </div>

                    <div className='d-flex my-3'>

                        <InstaIcon />
                        <div className='d-flex flex-column w-100'>
                            <input readOnly defaultValue={teacherDetail?.instagram} type="text" className='bg-white py-2 px-2 fz-14px rounded-1 border-0 mb-2' />


                        </div>
                    </div>

                    <div className='d-flex my-3'>

                        <LinkdinIcon />
                        <div className='d-flex flex-column w-100'>
                            <input readOnly defaultValue={teacherDetail?.linkedin} type="text" className='bg-white py-2 px-2 fz-14px rounded-1 border-0 mb-2' />


                        </div>
                    </div>

                    <div className='d-flex my-3'>
                        <TwitterIcon />
                        <div className='d-flex flex-column w-100'>
                            <input readOnly defaultValue={teacherDetail?.twitter} type="text" className='bg-white py-2 px-2 fz-14px rounded-1 border-0 mb-2' />


                        </div>
                    </div>

                </div>

                {/* -------------------- */}

            </div>
            {/* --- */}
            <div className="px-5 py-4">

                {/* ---cards */}
                <div className="row g-5">
                    <div className="col-lg-4">
                        <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4 h-100`}>
                            <div className="row g-0">
                                <div className="col-lg-3">
                                    <div className={`${sidebarAndDashboardStyles.bgViolet} ${sidebarAndDashboardStyles.line}`}></div>
                                </div>
                                <div className="col">
                                    <h6 className='fz-15px mb-3'>Total Revenue</h6>
                                    <h6>₹{cashInfo?.totalEarnings}</h6>
                                </div>
                                <div className="col-4 text-start">
                                    <Link to={`/teacher/earn-history/${params.teacherId}`} className=" text-decoration-none"> <h6 className='fz-13px fw-500 text-prime mb-3' role={"button"}>View history</h6></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4 h-100`}>
                            <div className="row g-0">
                                <div className="col-lg-3">
                                    <div className={`${sidebarAndDashboardStyles.bgLightInfo} ${sidebarAndDashboardStyles.line}`}></div>
                                </div>
                                <div className="col">
                                    <h6 className='fz-15px mb-3'>Total Payout</h6>
                                    <h6>₹{payout}</h6>
                                </div>
                                <div className="col-4 text-start">
                                    <Link to={`/teacher/instructor-payout-history/${params.teacherId}`} className=" text-decoration-none"> <h6 className='fz-13px fw-500 text-prime mb-3' role={"button"}>View history</h6></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">

                        <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4 h-100`}>
                            <div className="row g-0">
                                <div className="col-lg-3">
                                    <div className={`bg-prime ${sidebarAndDashboardStyles.line}`}></div>
                                </div>
                                <div className="col">
                                    <h6 className='fz-15px mb-3'>Total Courses</h6>
                                    <h6>{cashInfo?.totalCourses}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3"></div>

                {/* --- */}
                <h6 className='text-prime fz-15px mb-3'>Courses</h6>

                <div className="theme-tab">
                    <Tab.Container defaultActiveKey="active_tab" id="hr-tabs-example" animation={true} mountOnEnter unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="d-inline-block">
                                            <Nav variant="pills" className='bg-light rounded-1'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="active_tab" className='rounded-1 fz-14px px-5 py-2'>Active</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="de_active_tab" className='rounded-1 fz-14px px-5 py-2'>Deactive</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>

                                </div>

                            </Col>
                            <div className="py-3"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="active_tab">
                                        <ActiveInstructorCourseList instructorId={params.teacherId} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="de_active_tab">
                                        <DeactivatedInstructorCourseList instructorId={params.teacherId} />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
            {/* ============================Modals============================--- */}
            {/* ------Change name modal--------- */}
            <BootstrapModal
                isShowModal={showChangeNameModal}
                setIsModal={setShowChangeNameModal}
                title={

                    <h6 className={`fw-600 fz-18px text-start px-5`}>Change Name</h6>
                }
                body={
                    <div className='px-5 pb-3'>
                        <form onSubmit={onChangeName}>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>First Name</label>
                            <input className='input w-100 py-2 my-3 text-dark' onChange={(e) => setNewName(e.target.value.trim())} required />

                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>Last Name</label>
                            <input className='input w-100 py-2 my-3 text-dark' onChange={(e) => setLastName(e.target.value.trim())} required />

                            <button className='w-100 fw-500 border-0 py-2 rounded bg-prime text-white fz-14px' type='submit'>Change Name</button>
                        </form>
                    </div>
                }
            />
            {/* ------Change email modal--------- */}
            <BootstrapModal
                isShowModal={showChangeEmailModal}
                setIsModal={setShowChangeEmailModal}
                title={

                    <h6 className={`fw-600 fz-18px text-start px-5`}>Change Email</h6>
                }
                body={
                    <div className='px-5 pb-3'>
                        <form onSubmit={onChangeEmail}>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>Type New Email</label>
                            <input className='input w-100 py-2 my-3' onChange={(e) => setNewEmail(e.target.value.trim())} required />
                            <button className='w-100 fw-500 border-0 py-2 rounded bg-prime text-white fz-14px' type='submit'>Change Email</button>
                        </form>
                    </div>
                }
            />
            {/* ------Change password modal--------- */}



            <BootstrapModal
                isShowModal={showChangePasswordModal}
                setIsModal={setShowChangePasswordModal}
                title={
                    <h6 className={`fw-600 fz-18px text-start px-5`}>Change Password</h6>
                }
                body={
                    <div className='px-5 pb-3'>
                        <form onSubmit={onChangePassword}>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>New Password</label>
                            <div className=' position-relative'>
                                <input className='input w-100 py-2 my-3' type={isTextN ? "text" : "password"} onChange={e => setChangePassword(data => ({ ...data, password: e.target.value }))} required />
                                <button type='button' className='bg-transparent border-0 position-absolute top-0 bottom-0 end-0 pe-3' onClick={() => setTextN(!isTextN)}>{isTextN ? <OpenEyeIcon /> : <CloseEyeIcon />}</button>
                            </div>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>Confirm New Password</label>
                            <div className=' position-relative'>
                                <input className='input w-100 py-2 my-3' type={isTextC ? "text" : "password"} onChange={e => setChangePassword(data => ({ ...data, confirmPassword: e.target.value }))} required />
                                <button type='button' className='bg-transparent border-0 position-absolute top-0 bottom-0 end-0 pe-3' onClick={() => setTextC(!isTextC)}>{isTextC ? <OpenEyeIcon /> : <CloseEyeIcon />}</button>
                            </div>
                            <button className='w-100 fw-500 border-0 py-2 rounded bg-prime text-white fz-14px' type='submit'>Change Password</button>
                        </form>
                    </div>
                }
            />
            {/* ------view modal--------- */}
            <BootstrapModal
                isShowModal={showViewModal}
                setIsModal={setShowViewModal}
                title={

                    <h6 className={`fw-600 fz-18px text-start px-5`}>Account activity</h6>
                }
                body={
                    <div className='px-5 pb-3  '>
                        {accountActivity ? accountActivity?.map((userActivity, i) => (
                            <div key={i} className="row brd-btm  py-2 my-3">
                                <div className="col-lg-2">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="50" height="50" rx="10" fill="#F0E1EB" />
                                        <g clipPath="url(#clip0_2080_4170)">
                                            <path d="M23.8422 21.2852C23.4645 21.2852 23.1582 21.5914 23.1582 21.9691V25.691C23.1582 26.7523 24.0215 27.6156 25.0828 27.6156H27.5641C27.9418 27.6156 28.248 27.3094 28.248 26.9316C28.248 26.5539 27.9418 26.2477 27.5641 26.2477H25.0828C24.7758 26.2477 24.5262 25.998 24.5262 25.691V21.9691C24.5262 21.591 24.2199 21.2852 23.8422 21.2852Z" fill="#395061" />
                                            <path d="M31.8742 18.403C30.0379 16.567 27.5965 15.5557 24.9996 15.5557C23.5824 15.5557 22.1957 15.8619 20.9258 16.4483L21.1273 15.933C21.2648 15.5811 21.091 15.1846 20.7395 15.0471C20.3875 14.9096 19.991 15.0834 19.8535 15.435L19.0102 17.5924C18.916 17.833 18.9262 18.1045 19.0379 18.3377C19.1496 18.5705 19.3551 18.7487 19.6016 18.826L21.8117 19.519C21.8797 19.5405 21.9488 19.5506 22.0164 19.5506C22.3074 19.5506 22.577 19.3635 22.6688 19.0713C22.7816 18.7108 22.5813 18.3272 22.2207 18.2143L21.1297 17.8721C22.3184 17.2498 23.6422 16.9241 24.9992 16.9241C27.2309 16.9241 29.3285 17.7932 30.9066 19.3709C32.4844 20.9491 33.3535 23.0467 33.3535 25.2783C33.3535 27.51 32.4844 29.608 30.9066 31.1858C29.3285 32.7635 27.2309 33.6326 24.9992 33.6326C22.7676 33.6326 20.6695 32.7635 19.0918 31.1858C17.5137 29.6076 16.6449 27.51 16.6449 25.2783C16.6449 23.951 16.9469 22.6823 17.5422 21.5073C17.7129 21.1705 17.5781 20.7588 17.2414 20.5881C16.9047 20.4174 16.493 20.5522 16.3223 20.8889C15.6289 22.2569 15.2773 23.7334 15.2773 25.2776C15.2773 27.8744 16.2887 30.3158 18.125 32.1522C19.9613 33.9885 22.4027 34.9998 24.9996 34.9998C27.5965 34.9998 30.0379 33.9885 31.8742 32.1522C33.7106 30.3158 34.7219 27.8744 34.7219 25.2776C34.7219 22.6807 33.7106 20.2393 31.8742 18.403Z" fill="#395061" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2080_4170">
                                                <rect width="20" height="20" fill="white" transform="translate(15 15)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className={`${userActivity?.time ? "" : "m-auto"} col px-lg-0`}>
                                <p className='mb-0 fz-15px'><b className='fw-600'> {userActivity.header}</b></p>
                                    <p className='fz-14px pt-1'>{moment(userActivity.time).format("DD MMMM , YYYY")} at {moment(userActivity.time).format('h:mm A')}</p>
                                    {/* <p className='mb-0 fz-15px'><b className='fw-600'> {userActivity.header}</b></p> */}
                                    {/* <p className='fz-14px pt-1'>{moment(userActivity.time).format("DD MMMM , YYYY")}</p> */}
                                    {/* {userActivity?.time ? <p className='fz-14px'>{userActivity.data}  {moment(userActivity?.time).format("DD MMM, YYYY hh:mm A")}</p> : ""} */}

                                </div>

                            </div>
                        )) : <p className='text-center fw-500 '>Loading...</p>}
                        <JsonViewer data={accountActivity} />
                    </div>
                }
            />
            {/* ------Change about modal--------- */}
            <BootstrapModal
                isShowModal={showChangeAboutModal}
                setIsModal={setShowChangeAboutModal}
                title={

                    <h6 className={`fw-600 fz-18px text-start px-5`}>Change About</h6>
                }
                body={
                    <div className='px-5 pb-3'>
                        <form onSubmit={onChangeAbout}>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'>About</label>
                            <textarea rows={"4"} className='input w-100 py-2 my-3 white-space-pre-wrap' onChange={(e) => setNewAbout(e.target.value)} required />
                            <button className='w-100 fw-500 border-0 py-2 rounded bg-prime text-white fz-14px' type='submit'>Change About</button>
                        </form>
                    </div>
                }
            />
            {/* ------Send Notification modal--------- */}
            <BootstrapModal
                isShowModal={showSendNotificationModal}
                setIsModal={setSendNotificationModal}
                title={

                    <h6 className={`fw-600 fz-18px text-start px-5`}>Send Notification</h6>
                }
                body={
                    <div className='px-5 pb-3'>
                        <form onSubmit={onSendNotification}>
                            <label className='d-block fw-500 text-dark fz-15px m-0 p-0'> Message</label>
                            <textarea rows={"4"} className='input w-100 py-2 my-3' onChange={(e) => setNewNotification(e.target.value.trim())} required />
                            <button className='w-100 fw-500 border-0 py-2 rounded bg-prime text-white fz-14px' onClick={() => setSendNotificationModal(true)}>Send Email </button>
                        </form>
                    </div>
                }
            />
            {/* ============================Modal END============================ */}
        </div >

    )
}

export default ViewDetail