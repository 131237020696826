import { io } from "socket.io-client";
export const socket = io("wss://api.uat.qlearning.academy/ticket");
export const API = {
    LOGIN: '/login',
    GET_ALL_DEACTIVATE_REQUESTED_INSTRUCTOR: "/getAllDeactivateRequestedInstructor",
    GET_ALL_DEACTIVATE_REQUESTED_STUDENT: "/getAllDeactivateRequestedStudent",
    ADMIN_SEARCH: '/v1/admin/adminSearch',
    NEW_VIDEOS: '/v1/admin/inReviewLessonDetails',
    GET_ALL_CATEGORY: '/getAllCategory',
    GET_SUB_CATEGORY_BY_CATEGORY_CODE: '/getSubCategorybyCategoryCode',
    GET_ALL_CHAPTER: '/getActiveandApprovedLessonbyCourse',
    GET_ALL_LIVE_CLASS_BY_COURSE_CODE: '/getAllLiveClassByCourseCode',
    APPROVE_LESSON: '/approveLesson',
    REJECT_LESSON: '/rejectLesson',
    APPROVE_ASSESSMENT: '/approveAssesment',
    REJECT_ASSESSMENT: '/rejectAssesment',
    ACTIVATE_COURSE: '/activateCourse',
    DEACTIVATE_COURSE: '/deactivateCourse',
    APPROVE_COURSE: '/approveCourse',
    REJECT_COURSE: '/rejectCourse',
    LEARNERS_LIST_BY_COURSE_CODE: '/v1/admin/learnersListByCourseCode',
    GET_COURSE_OVERVIEW: '/getCourseOverview',
    GET_ALL_FAQ: '/getInreviewFaqByCourseCode',
    GET_DASHBOARD_COUNTERS: '/api/v1/admin/getDashboardCounters',
    ADMIN_TEACHER_ALL_TRANSACTIONS: "/api/v1/admin/adminTeacherAllTransactions",
    GET_ALL_SUB_CATEGORY: '/getAllSubCategory',
    GET_MANAGE_CATEGORIES: '/getManageCategories',
    GET_PAYMENT_FEES_DATA: '/api/v1/admin/getPaymentFeesData',
    SUBMIT_FEES: '/api/v1/admin/submitFees',
    DELETE_PAYMENT_FEES_DATA: '/api/v1/admin/deletePaymentFeesData',
    EDIT_PAYMENT_FEES_DATA: '/api/v1/admin/editPaymentFeesData',
    CREATE_CATEGORY_SUB_CATEGORY: '/createCategorySubCategory',
    DELETE_CATEGORY_AND_SUB_CATEGORY: '/deleteCategoryAndSubCategory',
    EDIT_CATEGORY_SUB_CATEGORY: '/editCategorySubCategory',
    GET_LIVE_COURSE_BY_COURSE_CODE: '/getLiveCourseByCourseCode',
    ACTIVATE_LIVE_COURSE: 'activateLiveCourse',
    DEACTIVATE_LIVE_COURSE: 'deactivateLiveCourse',
    LIVE_COURSE_DURATION_REJECT: '/rejectLiveCourse',
    LIVE_COURSE_DURATION_APPROVE: '/approveLiveCourse',
    GET_INREVIEW_ASSESSMENT_BY_LIVECOURSE_CODE: 'getInreviewAssessmentByLiveCourseCode',
    REJECT_LIVE_COURSE_ASSESSMENT: 'rejectLiveCourseAssessment',
    APPROVE_LIVE_COURSE_ASSESSMENT: 'approveLiveCourseAssessment',
    REJECT_COURSE_FAQ: '/rejectCourseFaq',
    APPROVE_COURSE_FAQ: '/approveCourseFaq',
    GET_ALL_IN_REVIEW_ASSESSMENT: '/getAllInReviewAssessment',
    GET_ALL_ACTIVATED_ASSESSMENT: '/getAllActivatedAssessment2',
    GET_ALL_DEACTIVATED_ASSESSMENT: '/getAllDeactivatedAssessment',
    APPROVE_IND_ASSESSMENT: "/approveIndAssessment",
    REJECT_IND_ASSESSMENT: "/rejectIndAssessment",
    ASSESSMENT_DETAILS: "/assessmentDetails",
    ACTIVATE_IND_ASSESSMENT: "/approveIndAssessment",
    DEACTIVATE_IND_ASSESSMENT: "/rejectIndAssessment",
    LOGOUT: '/logout',
    GET_RECORDED_COURSE_TICKETS: "/api/v1/ticketManagement/getCourseTickets",
    GET_ACTIVE_TEACHERS: "/api/v1/admin/getActiveTeachers",
    GET_DEACTIVE_TEACHERS: "/api/v1/admin/getInActiveTeachers",
    GET_INSTRUCTOR_BY_ID: "/getInstructorById",
    INSTRUCTOR_TOTAL_EARNINGS: "/api/v1/admin/adminTeacherTotalEarnings",
    INSTRUCTOR_TOTAL_COURSES: "/api/v1/admin/getTeacherTotalCourses",
    GET_ASSESSMENT_TICKETS: "/api/v1/ticketManagement/getAssessmentTickets",
    ADMIN_TEACHER_CHANGE_NAME: "/api/v1/admin/adminTeacherChangeName",
    ADMIN_TEACHER_CHANGE_EMAIL: "/api/v1/admin/adminTeacherChangeEmail",
    // TEACHER_ACCOUNT_DETAILS: "/api/v1/admin/teacherAccountDetails",
    TEACHER_ACCOUNT_DETAILS: "userAccountActivityByEmail",
    TEACHER_CHANGE_BIO: "/api/v1/admin/teacherChangeBio",
    ADMIN_TEACHERS_SEND_NOTIFICATION: "/api/v1/admin/adminTeacherSendNotification",
    GET_TEACHER_RATING: "/api/v1/admin/getTeacherRating",
    ADMIN_TEACHER_NO_OF_STUDENTS: "/api/v1/admin/adminTeacherNoOfStudents",
    GET_TEACHER_ACTIVE_COURSES: "/api/v1/admin/getTeacherActiveCourses",
    GET_TEACHER_DEACTIVE_COURSES: "/api/v1/admin/getTeacherDeActiveCourses",
    ADMIN_TEACHER_ACTIVATE_COURSE: "/api/v1/admin/adminTeacherActivateCourse",
    ADMIN_TEACHER_DEACTIVATE_COURSE: "/api/v1/admin/adminTeacherDeActivateCourse",
    ADMIN_TEACHER_EARNINGS_HISTORY: "/api/v1/admin/adminTeacherEarningsHistory",
    GET_ALL_WITHDRAW_REQUEST: "v1/withdraw/getAllWithdrawRequest",
    ACTIVATE_TEACHER: "/api/v1/admin/activateTeacher",
    DEACTIVATE_TEACHER: "/api/v1/admin/deactivateTeacher",
    DEACTIVATE_STUDENT: "/api/v1/admin/deactivateStudent",
    GET_TRANSACTION_TICKETS: "/api/v1/ticketManagement/getTransactionTickets",
    WITHDRAW_APPROVAL: "/api/v1/admin/getWithDrawApproval",
    GET_TEACHER_BANK_ACCOUNT_INFO: "/api/v1/admin/getTeacherBankAccountInfo",
    ADMIN_TEACHER_TOTAL_PAYOUT: "/api/v1/admin/adminTeacherTotalPayout",
    ADMIN_TEACHER_PAYOUT_HISTORY: "/api/v1/admin/adminTeacherPayoutHistory",
    GET_ALL_DISCOUNT_REQUEST: "v1/courseDiscount/getAllDiscountRequest",
    DISCOUNT_APPROVED_BY_ADMIN: "v1/courseDiscount/discountApprovedByAdmin",
    GET_DISCOUNT_TICKETS: "api/v1/ticketManagement/getDiscountTickets",
    ADMIN_TEACHER_RESET_PASSWORD: "/api/v1/admin/adminTeacherResetPassword",
    // Learner api's
    GET_ACTIVE_LEARNERS: "/api/v1/admin/getLearnersActiveList",
    GET_DEACTIVE_LEARNERS: "/api/v1/admin/getLearnersDeActiveList",
    GET_STUDENT_DETAILS: "/api/v1/admin/getStudentDetails",
    ACTIVATE_STUDENT: "/api/v1/admin/activateStudent",
    DEACTIVATE_STUDENT: "/api/v1/admin/deactivateStudent",
    GET_STUDENT_TOTALBUY: "/api/v1/admin/getStudentTotalBuy",
    GET_STUDENT_TOTAL_COURSES: "/api/v1/admin/getStudentTotalCourses",
    GET_STUDENT_ALL_COURSES: "/api/v1/admin/getStudentAllCourses",
    CHANGE_STUDENT_NAME: "/api/v1/admin/adminStudentChangeName",
    CHANGE_STUDENT_EMAIL: "/api/v1/admin/adminStudentChangeEmail",
    CHANGE_STUDENT_PASSWORD: "/api/v1/admin/adminStudentResetPassword",
    CHANGE_STUDENT_BIO: "/api/v1/admin/studentChangeBio",
    STUDENT_ACCOUNT_DETAILS: "/api/v1/admin/studentAccountDetails",
    SEND_STUDENT_NOTIFICATION: "/api/v1/admin/adminStudentSendNotification",
    STUDENT_BUYING_HISTORY: "/api/v1/admin/adminStudentBuyingHistory",
    COURSE_STUDENT_LIST: "/api/v1/admin/viewCourseStudentList",
    GENERATE_TEACHER_INVOICE: "api/v1/admin/createTeacherInvoice"
}


