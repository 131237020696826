import React from 'react';
import Html from 'react-pdf-html';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

const invoiceHtml = (props) => {
    // console.log("-------------------html------")
    // console.log(data)
    return (
        <html>
            <body>
                <style>
                    {`
           body {
            font-family: Helvetica;
          }
           .pdfTable {
      
            border-collapse: collapse;
            width: 100%;
          }
          
          .pdfTable td, .pdfTable th {
            border: 1px solid #ddd;
            padding: 8px;
     
            text-align: center;
          }
          
          .pdfTable tr:nth-child(even){background-color: #f2f2f2;}
          
          .pdfTable tr:hover {background-color: #ddd;}
          th,td{
            font-size: 16px;
          }
       
          .pdfTable th {
            padding-top: 12px;
       
    padding-bottom: 12px;
    text-align: center;
    background-color: #3a5062;
    color: white;
    font-weight: 400;
          }
            `}
                </style>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontSize: '25px', color: '#A9A9A9' }}>
                        Payout History
                    </span>
                    <div style={{ backgroundColor: '#36454F', padding: '15px' }}>
                        <img
                            src="https://ik.imagekit.io/42vct06fb/web_site/Group_1_7bAeFZnvQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1674044447460"
                            alt="qlearning"
                            style={{ width: '80px' }}
                        />
                    </div>
                </div>
                <div style={{ margin: '25px' }}>

                </div>
                <table className="pdfTable">
                    <tr>
                        <th>Timestamp</th>
                        <th>Amount</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td>{moment(props?.data?.requestedDate).format('DD MMMM, YYYY')}</td>

                        <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                            <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}> {props?.data?.requestedAmount}</span></td>
                        <td>Bank Transaction</td>
                        <td>{props?.data?.requestedstatus}</td>
                    </tr>
                </table>
            </body>
        </html>
    );
}

const html = (props) => {
    return ReactDOMServer.renderToStaticMarkup(invoiceHtml(props))
};

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '30px'
    }
});

const PdfDownloadFormat = (props) => {
    return (
        <Document>

            <Page size="A4" style={styles.page} orientation='landscape'>
                <Html>{html(props)}</Html>
            </Page>
        </Document>
    )
};

export default PdfDownloadFormat