import AWS from "aws-sdk";
import React, { useEffect, useState, forwardRef } from "react";
import VideoPlayer from "components/VideoPlayer";


const {
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_KEY,
  REACT_APP_API_REGION,
  REACT_APP_S3_BUCKETNAME,
} = process.env;

const MyS3Component = forwardRef((value, ref) => {
  const [mediaURL, setMediaURL] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    console.log("Generating signed URL...", value?.src);
    const s3 = new AWS.S3({
      accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_AWS_SECRET_KEY,
      region: REACT_APP_API_REGION,
    });

    const bucketName = REACT_APP_S3_BUCKETNAME;
    const objectKey = value?.src
      ?.toString()
      ?.split("/")
      ?.slice(3)
      ?.join("/");
    const fileExtension = objectKey?.split(".").pop();

    let contentType;
    if (fileExtension === "mp4") {
      contentType = "video/mp4";
      setMediaType("video");
    } else if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      contentType = `image/${fileExtension}`;
      setMediaType("image");
    } else {
      console.error("Unsupported file type");
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: 300, // URL expiration time in seconds
      ResponseContentType: contentType,
    };

    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating signed URL:", err);
      } else {
        console.log("Signed URL:", url);
        setMediaURL(url);
      }
    });
  }, [value?.src]);
  if (mediaURL && value.isVideoPlayer) {
    console.log("sanjay", mediaURL);
    return <VideoPlayer url={mediaURL} />;
  }

  if (mediaURL && mediaType === "image" && !value.isVideoPlayer) {
    return (
      <img
        src={mediaURL}
        className={value?.className ? value?.className : "rounded-3"}
        alt={value?.alt ? value?.alt : ""}
        style={value?.style ? value?.style : {}}
        ref={ref}
        onError={value?.onError}
      />
    );
  }

  if (mediaURL && mediaType === "video" && !value.isVideoPlayer) {
    return (
      <video className="video-brd m-0" controls>
        <source type="video/mp4" src={mediaURL} />
      </video>
    );
  }

  return null;
});

export default MyS3Component;
