import LayoutWrapper from 'components/LayoutWrapper';
import NotFound from 'pages/404/NotFound';
import Dashboard from 'pages/adminDashboard/dashboard/Dashboard';
import LearnersList from 'pages/allCourses/active/LearnersList';
import ClickedAssessment from 'pages/allCourses/inReview/viewApprove/independentAssessments/ClickedAssessment';
import ClickedLiveVideo from 'pages/allCourses/inReview/viewApprove/liveVideos/ClickedLiveVideo';
import ClickedAssessmentUploaded from 'pages/allCourses/inReview/viewApprove/uploadedVideos/ClickedAssessmentUploaded';
import ClickedChapter from 'pages/allCourses/inReview/viewApprove/uploadedVideos/ClickedChapter';
import ClickedLesson from 'pages/allCourses/inReview/viewApprove/uploadedVideos/ClickedLesson';
import ViewApprove from 'pages/allCourses/inReview/viewApprove/ViewApprove';
import Courses from 'pages/allCourses/mainPageCourse/Courses';
import ViewApproveIndependentAssessment from 'pages/assessmentIndependent/assessmentApprove/ViewApproveIndependentAssessment';
import IndependentAssessment from 'pages/assessmentIndependent/indexIndependentAssessment/IndependentAssessment';
import CreateCategories from 'pages/manageCategories/createCategories/CreateCategories';
import EditCategory from 'pages/manageCategories/editCategory/EditCategory';
import ManageCategories from 'pages/manageCategories/Index/ManageCategories';
import DiscountMainPage from 'pages/manageDiscount/index/DiscountMainPage';
import DiscountTicketChat from 'pages/manageDiscount/ticketChat/DiscountTicketChat';
import ManageFees from 'pages/manageFees/index/ManageFees';
import WithdrawMainPage from 'pages/manageWithdraw/index/WithdrawMainPage';
import TransactionTicketChat from 'pages/manageWithdraw/withdrawTicket/TransactionTicketChat';
import ActiveDeactiveTeacherTab from 'pages/teachers/teacherMainPage/ActiveDeactiveTeacherTab';
import ViewEarnHistory from 'pages/teachers/teacherTable/totalEarnHistory/ViewEarnHistory';
import ViewPayoutHistory from 'pages/teachers/teacherTable/totalPayoutHistory/ViewPayoutHistory';
import ViewDetail from 'pages/teachers/teacherTable/viewInstructorDetail/ViewDetail';
import TicketMessage from 'pages/ticketManagement/chat/TicketMessage';
import TicketMessageAssessment from 'pages/ticketManagement/chat/TicketMessageAssessment';
import TicketManagement from 'pages/ticketManagement/mainIndex/TicketManagement';
import ActiveDeactiveStudentTab from 'pages/students/studentMainPage/ActiveDeactiveStudentTab';
import ViewLearnerDetail from 'pages/students/studentTable/viewStudentDetail/ViewDetail';
import ViewBuyingHistoryHistory from 'pages/students/studentTable/totalBuyingHistory/ViewBuyingHistoryHistory';
import ViewLearnersList from 'pages/students/studentTable/totalLearnersList/ViewLearnersList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// Lazy load try 
// const Dashboard = lazy(() => import('pages/adminDashboard/dashboard/Dashboard'));
// const NotFound = lazy(() => import('pages/404/NotFound'));
// const IndependentAssessment = lazy(() => import('pages/assessmentIndependent/indexIndependentAssessment/IndependentAssessment'));
// const Courses = lazy(() => import('pages/allCourses/mainPageCourse/Courses'));
// const TicketManagement = lazy(() => import('pages/ticketManagement/mainIndex/TicketManagement'));
// const CreateCategories = lazy(() => import('pages/manageCategories/createCategories/CreateCategories'));
// const ManageCategories = lazy(() => import('pages/manageCategories/Index/ManageCategories'));
// const ManageFees = lazy(() => import('pages/manageFees/index/ManageFees'));
function RouterMain() {
    return (
        <BrowserRouter>
            <Routes>

                {/* These are public routes */}
                {/* <Private */}

                <Route path="/login" element={<PrivateRoute isloginUrl={true} />} />

                <Route path="/" element={<PrivateRoute />}>
                    <Route path="" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <Dashboard />
                        </LayoutWrapper>
                    } />
                    <Route path="*" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <NotFound />
                        </LayoutWrapper>
                    } />
                    <Route path="/teachers" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ActiveDeactiveTeacherTab />
                        </LayoutWrapper>
                    } />
                    <Route path="/teachers/view-Detail/:teacherId" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewDetail />
                        </LayoutWrapper>
                    } />
                    <Route path="/teacher/instructor-payout-history/:teacherId" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewPayoutHistory />
                        </LayoutWrapper>
                    } />
                    <Route path="/teacher/earn-history/:teacherId" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewEarnHistory />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <Courses />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/active/learners-list" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <LearnersList />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/view-approve" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewApprove />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/uploaded/chapter" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ClickedChapter />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/uploaded/chapter/lesson" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ClickedLesson />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/uploaded/chapter/assessment" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ClickedAssessmentUploaded  />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/view-approve/live-video" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ClickedLiveVideo />
                        </LayoutWrapper>
                    } />
                    <Route path="/all-courses/in-review/view-approve/assessment" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ClickedAssessment />
                        </LayoutWrapper>
                    } />
                    <Route path="/independent-assessment" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <IndependentAssessment />
                        </LayoutWrapper>
                    } />



                    <Route path="/independent-assessment/view-approve/:assessmentCode" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewApproveIndependentAssessment />
                        </LayoutWrapper>
                    } />
                    <Route path="/manage-categories" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ManageCategories />
                        </LayoutWrapper>
                    } />
                    <Route path="/manage-categories/create-categories" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <CreateCategories />
                        </LayoutWrapper>
                    } />

                    <Route path="/edit-category" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <EditCategory />
                        </LayoutWrapper>
                    } />
                    <Route path="/manage-fees" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ManageFees />
                        </LayoutWrapper>
                    } />
                    <Route path="/manage-discount" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <DiscountMainPage />
                        </LayoutWrapper>
                    } />

                    <Route path="/manage-withdraw" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <WithdrawMainPage />
                        </LayoutWrapper>
                    } />
                    <Route path="/ticket-management" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <TicketManagement />
                        </LayoutWrapper>
                    } />
                    <Route path="/ticket-message" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <TicketMessage />
                        </LayoutWrapper>
                    } />
                    <Route path="/ticket-message/assessment" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <TicketMessageAssessment />
                        </LayoutWrapper>
                    } />
                    <Route path="/transaction-ticket-messages" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <TransactionTicketChat />
                            {/* <Route path="/learners" element={
                                <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                                    <ActiveDeactiveStudentTab />
                                </LayoutWrapper>
                            } />
                            <Route path="/learners/view-Detail/:teacherId" element={
                                <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                                    <ViewLearnerDetail />
                                </LayoutWrapper>
                            } />

                            <Route path="/buying-history/:student_id" element={
                                <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                                    <ViewBuyingHistoryHistory />
                                </LayoutWrapper>
                            } />



                            <Route path="/learners-list" element={
                                <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                                    <ViewLearnersList />
                                </LayoutWrapper>
                            } /> */}

                        </LayoutWrapper>
                    }></Route>

                    <Route path="/learners" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ActiveDeactiveStudentTab />
                        </LayoutWrapper>
                    } />
                    <Route path="/learners/view-Detail/:teacherId" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewLearnerDetail />
                        </LayoutWrapper>
                    } />
                    <Route path="/discount-ticket-messages" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <DiscountTicketChat />
                        </LayoutWrapper>
                    } />

                    <Route path="/buying-history/:student_id" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewBuyingHistoryHistory />
                        </LayoutWrapper>
                    } />

                    <Route path="/learners-list/:student_id" element={
                        <LayoutWrapper layoutIdentifier={"DASHBOARD"}>
                            <ViewLearnersList />
                        </LayoutWrapper>
                    } />

                </Route>
            </Routes>
        </BrowserRouter >
    )
}

export default RouterMain