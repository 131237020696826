import React from 'react'

function FbIcon() {
    return (
        <svg className='position-relative' viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0012 2.50244C8.09869 2.50244 2.50244 8.09869 2.50244 15.0012C2.50244 21.2387 7.07244 26.4087 13.0487 27.3499V18.6149H9.87369V15.0012H13.0487V12.2474C13.0487 9.11244 14.9149 7.38369 17.7687 7.38369C19.1362 7.38369 20.5687 7.62744 20.5687 7.62744V10.7012H18.9887C17.4387 10.7012 16.9537 11.6662 16.9537 12.6549V14.9987H20.4174L19.8637 18.6124H16.9537V27.3474C22.9299 26.4112 27.4999 21.2399 27.4999 15.0012C27.4999 8.09869 21.9037 2.50244 15.0012 2.50244Z" fill="#395061" />
        </svg>
    )
}

export default FbIcon