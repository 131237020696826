import React from 'react';
import Html from 'react-pdf-html';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';

const invoiceHtml = (data) => {
    const { amount, taxPersentage, discountPercentage, discountValue } = data;

    const tax = (taxPersentage / 100) * amount;
    const taxWithTotal = (amount + tax).toFixed(2);

    // For Discount
    const discountAmount = discountValue || (discountPercentage / 100) * amount;
    const subtotal = amount - discountAmount;
    const taxAmount = (taxPersentage / 100) * subtotal;
    const totalAmount = (subtotal + taxAmount).toFixed(2);

    return (
        <html>
            <body>
                <style>
                    {`
            body {
              font-family: Helvetica;
            }
            span {
              display: block;
            }
            tr {
              borderBottom: 1px solid lightgray;
            }
            tr.last:last-child {
              borderBottomWidth: 1.4px;
            }
            .courses th, .courses td {
              padding: 10px 14px;
              font-size: 14px;
            }
            .amount tr {
              border: none;
            }
            .amount th, .amount td {
              padding: 2px 14px;
              font-size: 11px;
            }
           `}
                </style>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontSize: '25px', color: '#A9A9A9' }}>
                        Invoice
                    </span>
                    <div style={{ backgroundColor: '#36454F', padding: '15px' }}>
                        <img
                            src="https://ik.imagekit.io/42vct06fb/web_site/Group_1_7bAeFZnvQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1674044447460"
                            alt="rupee"
                            style={{ width: '80px' }}
                        />
                    </div>
                </div>
                <table className='courses' style={{ width: '100%', marginTop: '20px' }}>
                    <tr style={{ backgroundColor: '#36454F', color: '#fff', borderBottom: 'none' }}>
                        <th style={{ minWidth: '75%' }}>Course Name</th>
                        <th style={{ textAlign: 'center' }}>Price</th>
                    </tr>
                    <tr>
                        <td style={{ minWidth: '75%' }}>
                            {data.courseName}
                        </td>
                        <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '22px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                            <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-5px' }}>
                                {data.amount?.toFixed(2)}
                            </span>
                        </td>
                    </tr>
                </table>



                {!discountPercentage && (
                    <table className="amount" style={{ width: '100%', marginTop: '12px' }}>
                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Subtotal</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {amount?.toFixed(2)} */}
                                    {Math.ceil(amount) + ".00"}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Tax({data.taxPersentage}%)</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {tax?.toFixed(2)} */}
                                    {Math.ceil(tax) + ".00"}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Total</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {taxWithTotal} */}
                                    {Math.ceil(taxWithTotal) + ".00"}
                                </span>
                            </td>
                        </tr>
                    </table>
                )}



                {discountPercentage && (
                    <table className="amount" style={{ width: '100%', marginTop: '12px' }}>
                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Subtotal</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {amount?.toFixed(2)} */}
                                    {Math.ceil(amount) + ".00"}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Discount {(data.discountPercentage)}%</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                                -<img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {data.discountValue?.toFixed(2)} */}
                                    {Math.ceil(data.discountValue) + ".00"}
                                </span>
                            </td>
                        </tr>


                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Subtotal after discount</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {data.currencyCode === "INR" && <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />}
                            {data.currencyCode === "USD" && "$"} */}
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {subtotal?.toFixed(2)} */}
                                    {Math.ceil(subtotal) + ".00"}
                                </span>
                            </td>
                        </tr>


                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Tax({data.taxPersentage}%)</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {taxAmount?.toFixed(2)} */}
                                    {Math.ceil(taxAmount) + ".00"}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ minWidth: '75%', textAlign: 'left' }}>Total</td>
                            <td style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://static.thenounproject.com/png/591777-200.png" style={{ width: '19px', display: 'block', padding: 0, margin: 0 }} alt="Rupee" />
                                <span style={{ display: 'block', padding: 0, margin: 0, marginLeft: '-4px' }}>
                                    {/* {totalAmount} */}
                                    {Math.ceil(totalAmount) + ".00"}
                                </span>
                            </td>
                        </tr>
                    </table>
                )}



                <div style={{ backgroundColor: '#36454F', padding: '1px', marginTop: '8px' }} />
                <span style={{ fontSize: '12px', color: 'gray', paddingTop: '10px' }}>Notes, any relevant info, terms, payment instructions, e.t.c</span>
            </body>
        </html >
    );
}

const html = (data) => {
    return ReactDOMServer.renderToStaticMarkup(invoiceHtml(data))
};

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '30px'
    }
});

const InvoicePDF = ({ data }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Html>{html(data)}</Html>
            </Page>
        </Document>
    )
};

export default InvoicePDF