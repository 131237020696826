import BootstrapModal from 'components/BootstrapModal';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import CloseEyeIcon from 'svgIcons/CloseEyeIcon';
import OpenEyeIcon from 'svgIcons/OpenEyeIcon';
import { confirmAlertSuccess } from 'utils/confirmAlert';
import axiosFetch from '../../../axios/AxiosInterceptors';
function WithDrawRequestList({ search, selectedDate }) {
    const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);
    const [accountDetail, setAccountDetail] = useState(false);
    const [visibleIndex, setVisibleIndex] = useState(null);
    const [withDrawReq, setWithDrawReq] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);

    const onViewAcc = async (wdata) => {
        setVisibleIndex(false)
        setShowAccountInfoModal(true)
        try {
            const jsonData = {
                "userType": "ADMIN",
                "instructorId": wdata?.instructorId
            }
            const resp = await axiosFetch.post(API.GET_TEACHER_BANK_ACCOUNT_INFO, jsonData);
            setAccountDetail(resp.data);

        } catch (error) {
        }
    }
    useEffect(() => {
        getAllWithDrawReq();
    }, [search, selectedDate?.from, selectedDate?.to])
    const getAllWithDrawReq = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_WITHDRAW_REQUEST + `/?instructorEmail=${search}&fromDate=${selectedDate?.from}&toDate=${selectedDate?.to}`);
            setWithDrawReq(resp.data)
        } catch (error) {
        }
    }
    const onPaid = async (wData) => {
        const isTrue = await confirmAlertSuccess({ title: "Are you sure to change the status to paid?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    "userType": "ADMIN",
                    "withdrawId": wData?._id
                }
                const resp = await axiosFetch.post(API.WITHDRAW_APPROVAL, jsonData);
                const updating = await getAllWithDrawReq();
                toast.success('Paid status updated successfully');
            } catch (error) {
            }
        }
    }
    //after fetch
    useEffect(() => {
        if (withDrawReq) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = withDrawReq.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(withDrawReq.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [withDrawReq, itemOffset, itemsPerPage])
    const handlePageClick = (event) => {

        const newOffset = (event.selected * itemsPerPage) % withDrawReq.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div>
            <div className='py-3'>
                <div className="pt-3 bg-white rounded-3">
                    <div className="px-lg-4">
                        {/* ------- */}

                        <div className="table-responsive">
                            <table className="table table-borderless align-middle">
                                <thead>
                                    <tr>
                                        <th className='fw-500 text-muted fz-15px'>Time Stamp</th>
                                        <th className='fw-500 text-muted fz-15px'>Email</th>
                                        <th className='fw-500 text-muted fz-15px'>Charge</th>
                                        <th className='fw-500 text-muted fz-15px'>Account info</th>
                                        {/* <th className='fw-500 text-muted fz-15px'>Status</th> */}
                                        <th className='fw-500 text-muted fz-15px'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !currentItems ? <tr className='text-center'>
                                            <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                                        </tr> : currentItems?.length ?
                                            currentItems?.map((wData, i) => (
                                                <tr key={wData?._id}>
                                                    <td className='fz-15px'>{moment(wData?.requestedDate).format('DD MMMM , YYYY')}</td>
                                                    {/* <td><img src="https://www.efilecabinet.com/wp-content/uploads/2019/05/g2-testimonial-male.jpg" className={`${teacherStyles.tImg}`} />{fullName}</td> */}
                                                    <td className='fz-15px'>{wData?.instructorEmail}</td>
                                                    <td className='fz-15px'>₹{wData?.requestedAmount}</td>
                                                    {/* <td className='fz-15px'>₹{wData?.requestedAmount}</td> */}
                                                    {/* <td className='fz-15px'>₹0</td> */}
                                                    <td>
                                                        <span role={"button"} className='px-4  d-inline-block  bgPinkLight fz-13px text-prime fw-500  text-center rounded-5 py-1' onClick={() => onViewAcc(wData)}> View</span>
                                                    </td>
                                                    <td style={{width:'13%'}}>
                                                        {wData?.requestedstatus == "PAID" ?
                                                            <span className={`${wData?.requestedstatus} d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1`}>{wData?.requestedstatus}</span>
                                                            :
                                                            <span role={"button"} onClick={() => onPaid(wData)} className={`${wData?.requestedstatus} d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1`}>{wData?.requestedstatus}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr className='text-center'>
                                                <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <JsonViewer data={withDrawReq} />
                        {/* ------- */}
                        <BootstrapModal
                            isShowModal={showAccountInfoModal}
                            setIsModal={setShowAccountInfoModal}
                            title={
                                <h6 className={`fw-600 fz-18px text-start px-5`}>     <div className=" bg-light d-inline-block px-3 pb-2 pt-1 rounded ">
                                    {visibleIndex ?
                                        <span onClick={() => setVisibleIndex(!visibleIndex)} role="button"><div  > <OpenEyeIcon /></div></span>
                                        :
                                        <span onClick={() => setVisibleIndex(!visibleIndex)} role="button"><div> <CloseEyeIcon /></div></span>
                                    }
                                </div> Account info </h6>
                            }
                            body={
                                <div className='px-5 pb-3'>

                                    {!accountDetail ? <Loader className="text-prime my-3" center={true} /> : accountDetail.length ?
                                        accountDetail?.map((aData, index, array) => (
                                            <div key={aData?._id} className={`acc-detail-crd mb-4 `}>

                                                <p className='mb-2'>Account name : {aData?.accountHolderName}</p>
                                                <p className='mb-2'>
                                                    Account no : {visibleIndex ? aData?.accountNumber : "************"}

                                                </p>
                                                <p className='mb-2'>Bank name : {aData?.bankName}</p>
                                                <p>
                                                    IFSC : {visibleIndex ? aData?.IFSCcode : "************"}

                                                </p>
                                            </div>
                                        ))

                                        : <h6 className='text-muted text-center'>Instructor has not added any account details</h6>
                                    }
                                    <JsonViewer data={accountDetail} />

                                </div>
                            }
                        />
                        <div className="row">
                            <div className="col-lg-8">
                                <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                            </div>
                            <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                                Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={75}>75</option>
                                    <option value={100}>100</option>
                                </select> withdraw request per page
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WithDrawRequestList