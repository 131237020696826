import axios from 'axios';
import { toast } from 'react-hot-toast';
import { errorAlert } from 'utils/errorAlert';
import { getLocalStorage } from 'utils/localStorage';

const axiosFetch = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

// ---Request-------
axiosFetch.interceptors.request.use(
    (request) => {

        const jwt = getLocalStorage("userData")?.JWT
        if (jwt) {
            request.headers['x-auth-token'] = jwt;
        }
        console.log('Request sent');
        return request;
    },
    (error) => {

        console.log("Request error")
        errorAlert({ title: error.message })
        return Promise.reject(error);
    }
);

// ----Response------
axiosFetch.interceptors.response.use(
    (response) => {
        console.log("Response");
        console.log(response.data);
        return response.data;
    },
    (error) => {
        console.log(error)

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx\
            console.log("response.message")

            if (error.response.data.message == "Token is Not Valid") {
                errorAlert({ title: "Please try to re-login", description: "You are already logged in on another device or browser!" }).then(function () {
                    localStorage.clear();
                    window.location = "/login";
                });
            } else {
                errorAlert({ title: error.response.data.message || error.message });
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("error.message")
            errorAlert({ title: error.message });
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Something went wrong!")
            errorAlert({ title: "Something went wrong!" });
        }


        return Promise.reject(error);
    }
);

export default axiosFetch;