import React from 'react'

function JsonViewer({ data }) {
    return (
        <>
            {/* <pre>{JSON.stringify(data, null, 3)}</pre> */}
        </>
    )
}

export default JsonViewer
