import axiosFetch from '../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import React, { useEffect, useState } from 'react'
import { Col, Nav, Row, Stack, Tab } from 'react-bootstrap';
import { API } from 'constants/apiEndPoints';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'utils/confirmAlert';
import { toast } from 'react-hot-toast';
import OverViewAssessment from './OverViewAssessment';
import AssessmentQA from './AssessmentQA';
function ViewApproveIndependentAssessment() {
    const [inReviewAssessmentData, setInReviewAssessmentData] = useState(null)
    const [reason, setReason] = useState("");
    const { assessmentCode } = useParams();
    const navigate = useNavigate()
    useEffect(() => {
        getAllInReviewAssessment();
    }, [])
    const getAllInReviewAssessment = async () => {
        try {
            const resp = await axiosFetch.post(API.ASSESSMENT_DETAILS + `?assessmentCode=${assessmentCode}`, { "userType": "ADMIN" });
            setInReviewAssessmentData(resp.data)
        } catch (error) {

        }
    }
    const onReject = async () => {

        const isTrue = await confirmAlert({ title: "Are you sure you want to deactivate this assessment?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    "userType": "ADMIN",
                    deactivateReason: reason
                }
                const resp = await axiosFetch.post(API.DEACTIVATE_IND_ASSESSMENT + `?assessmentCode=${assessmentCode}`, jsonData);
                toast.success('Assessment deactivated successfully');
                navigate('/independent-assessment')

            } catch (error) {

            }

        }
    }


    const onActivate = async () => {
        try {
            const jsonData = {
                "userType": "ADMIN",
            }
            const resp = await axiosFetch.post(API.ACTIVATE_IND_ASSESSMENT + `?assessmentCode=${assessmentCode}`, jsonData);
            toast.success('Assessment activated successfully');
            navigate('/independent-assessment')
        } catch (error) {

        }

    }
    return (
        <div className="px-4">
            <Stack direction="horizontal" gap={4}>

                <h6>{inReviewAssessmentData?.assessmentTitle}</h6>

                <div className="ms-auto" />

                <>
                    <textarea rows={"2"} placeholder="Deactivate reason" className={`input py-2 fz-13px w-25 px-2`} onInput={(e) => { if (e.target.value.trim() == "") { e.target.value = ""; } }} onChange={(e) => setReason(e.target.value)} />
                    <button className="textTomato fz-14px fw-500  border-0 bg-transparent " onClick={onReject} >Deactivate</button>
                    <button className="bgGreenLight textGreen fz-14px fw-500 px-5 border-0 py-2 rounded-1" onClick={onActivate}>Activate</button>

                </>


            </Stack>


            <div className="py-3"></div>
            <div className="bg-white rounded-2 py-4 mb-4">
                <div className="px-lg-4 theme-tab">
                    <JsonViewer data={inReviewAssessmentData} />
                    <Tab.Container defaultActiveKey={"Overview"} unmountOnExit>
                        <Row>
                            <Col sm={12} >

                                <div className="d-flex justify-content-between">
                                    <div className={`d-inline-block bg-light rounded-1`}>
                                        <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link eventKey="Overview" className='rounded-1 fz-14px px-5 py-2'>Overview</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item >
                                                <Nav.Link eventKey="Assessment" className='rounded-1 fz-14px px-5 py-2'>Assessment</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </Col>
                            <div className="py-3"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    {/* -------Recorded Course---------- */}
                                    <Tab.Pane eventKey="Overview">
                                        <OverViewAssessment data={inReviewAssessmentData} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Assessment">
                                        <AssessmentQA data={inReviewAssessmentData} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}
export default ViewApproveIndependentAssessment