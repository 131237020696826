import React from 'react'

function LiveCourseComment() {
    const onComment = (e) => {
        e.preventDefault()
    }
    return (
        <div>
            <form onSubmit={onComment}>
                <label className='fw-500 text-muted fz-15px mb-3'>Enter your comment</label>
                <textarea rows={"6"} className="input w-100 p-2 mb-2" onInput={(e) => { if (e.target.value.trim() == "") { e.target.value = ""; } }}
                    required />
                <div className="d-flex justify-content-end">
                    <button className='bg-prime border-0 text-white fz-14px py-2 px-5 rounded-2' type='submit'>Submit</button>
                </div>

            </form>

        </div>
    )
}

export default LiveCourseComment