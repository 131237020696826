import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import CouponRequestList from '../couponRequest/CouponRequestList';
import CouponTicketList from '../couponTicket/CouponTicketList';

function DiscountMainPage() {
    const [activeTab, setActiveTab] = useState("")
    const [searchValue, setSearchValue] = useState("")

    return (
        <div className="px-4">
            <div className=" px-5 py-5 mb-4">
                <div className="px-lg-3  theme-tab">
                    <Tab.Container defaultActiveKey="course_tab" id="hr-tabs-example" animation={true} mountOnEnter unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <div className="row">
                                    <div className="col-lg">
                                        <div className="d-inline-block">
                                            <Nav variant="pills" className='bg-light rounded-1'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="course_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setActiveTab("")}>Discount Request</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="transaction_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setActiveTab("")}>Manage Discount</Nav.Link>
                                                </Nav.Item>

                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        {activeTab == "MANAGE_DISCOUNT" ? "" : <input type={"search"} className="input" placeholder='search by coupon name' onChange={(e) => setSearchValue(e.target.value)} />}
                                    </div>
                                </div>

                            </Col>
                            <div className="py-2"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="course_tab">
                                        <CouponRequestList onSearch={searchValue} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="transaction_tab">
                                        <CouponTicketList  onSearch={searchValue}/>
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default DiscountMainPage
