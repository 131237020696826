import axiosFetch from '../../../../../../axios/AxiosInterceptors';
import Loader from 'components/Loader';
import { API } from 'constants/apiEndPoints';
import React,{useState} from 'react';
import { Stack } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useRecoilValue } from 'recoil';
import { liveCourseOverviewRecoilState } from 'recoil/atoms/atomsStore';
import { confirmAlert } from 'utils/confirmAlert';
import { getLocalStorage,setLocalStorage } from 'utils/localStorage';
import { toast } from 'react-hot-toast';

function LiveCourseFaq() {
    const liveCourseOverviewRecoilAtom = useRecoilValue(liveCourseOverviewRecoilState);
    let clickedCourse = getLocalStorage("clickedCourse");
    const [activedisable,setActive]=useState(clickedCourse.inReviewLessonFAQCount==0?true:false)

    const onReject = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to rejected this FAQ?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    courseCode: clickedCourse?.courseCode,
                }
                const resp = await axiosFetch.post(API.REJECT_COURSE_FAQ, jsonData);
                toast.success('FAQ rejected successfully!');


            } catch (error) {

            }

        }
    }

    const onApprove = async () => {
        try {
            const resp1 = await axiosFetch(API.ADMIN_SEARCH + `?courseStatus=INREVIEW&courseCode=${clickedCourse.courseCode}`);
            setLocalStorage({ key: "clickedCourse", value: resp1?.data[0] })
            clickedCourse = getLocalStorage("clickedCourse");
            if(clickedCourse.inReviewLessonFAQCount==0){
                setActive(true)
            }
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
            }
            const resp = await axiosFetch.post(API.APPROVE_COURSE_FAQ, jsonData);
            toast.success('FAQ approved successfully!');

        } catch (error) {

        }

    }

    return (
        <div className='faq-sec'>
            {!liveCourseOverviewRecoilAtom ? "" : liveCourseOverviewRecoilAtom[0]?.faqList?.length ?

                <Stack direction="horizontal" gap={4} className="mb-4">
                    <div className="textTomato fz-14px fw-500 ms-auto" onClick={onReject} role={"button"}>Reject</div>
                    <div
                   className={`bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1 ${activedisable ? 'disabled' : ''}`}
                    style={{ opacity: activedisable ? 0.6 : 1, cursor: activedisable ? 'not-allowed' : 'pointer' }}
                    onClick={!activedisable ? onApprove : undefined}
                    role="button"
                >
                Approve
                </div>  
                </Stack>
                : ""
            }
            {
                !liveCourseOverviewRecoilAtom ? <Loader className="text-prime my-3" center={true} /> : liveCourseOverviewRecoilAtom[0]?.faqList?.length ?
                    <Accordion defaultActiveKey="0" flush>
                        {liveCourseOverviewRecoilAtom[0]?.faqList?.map((item, i) => (
                            <Accordion.Item eventKey={i} key={item._id}>
                                <Accordion.Header>{item?.question}</Accordion.Header>
                                <Accordion.Body>
                                    {item?.answer}
                                </Accordion.Body>
                            </Accordion.Item>

                        ))}

                    </Accordion>
                    : <h6 className='text-muted text-center'>No data available </h6>
            }
        </div>
    )
}

export default LiveCourseFaq

