import React from 'react'

function DeleteIcon({ width, height, color, className }) {
    return (
        <svg width={width} height={height} fill={color} className={className} viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 3.33333H13.9167C13.7232 2.39284 13.2115 1.54779 12.4677 0.940598C11.7239 0.333408 10.7935 0.0012121 9.83333 0L8.16666 0C7.20649 0.0012121 6.27609 0.333408 5.53228 0.940598C4.78847 1.54779 4.27674 2.39284 4.08332 3.33333H1.49999C1.27898 3.33333 1.06701 3.42113 0.910734 3.57741C0.754454 3.73369 0.666656 3.94565 0.666656 4.16667C0.666656 4.38768 0.754454 4.59964 0.910734 4.75592C1.06701 4.9122 1.27898 5 1.49999 5H2.33332V15.8333C2.33465 16.938 2.77406 17.997 3.55517 18.7781C4.33629 19.5593 5.39533 19.9987 6.49999 20H11.5C12.6047 19.9987 13.6637 19.5593 14.4448 18.7781C15.2259 17.997 15.6653 16.938 15.6667 15.8333V5H16.5C16.721 5 16.933 4.9122 17.0893 4.75592C17.2455 4.59964 17.3333 4.38768 17.3333 4.16667C17.3333 3.94565 17.2455 3.73369 17.0893 3.57741C16.933 3.42113 16.721 3.33333 16.5 3.33333ZM8.16666 1.66667H9.83333C10.3502 1.6673 10.8543 1.82781 11.2763 2.1262C11.6984 2.42459 12.0178 2.84624 12.1908 3.33333H5.80916C5.98214 2.84624 6.30158 2.42459 6.72365 2.1262C7.14572 1.82781 7.64976 1.6673 8.16666 1.66667ZM14 15.8333C14 16.4964 13.7366 17.1323 13.2678 17.6011C12.7989 18.0699 12.163 18.3333 11.5 18.3333H6.49999C5.83695 18.3333 5.20107 18.0699 4.73222 17.6011C4.26338 17.1323 3.99999 16.4964 3.99999 15.8333V5H14V15.8333Z" fill="#F75656" />
            <path d="M7.33333 14.9997C7.55435 14.9997 7.76631 14.9119 7.92259 14.7556C8.07887 14.5993 8.16667 14.3874 8.16667 14.1663V9.16634C8.16667 8.94533 8.07887 8.73337 7.92259 8.57709C7.76631 8.42081 7.55435 8.33301 7.33333 8.33301C7.11232 8.33301 6.90036 8.42081 6.74408 8.57709C6.5878 8.73337 6.5 8.94533 6.5 9.16634V14.1663C6.5 14.3874 6.5878 14.5993 6.74408 14.7556C6.90036 14.9119 7.11232 14.9997 7.33333 14.9997Z" fill="#F75656" />
            <path d="M10.6667 14.9997C10.8877 14.9997 11.0997 14.9119 11.2559 14.7556C11.4122 14.5993 11.5 14.3874 11.5 14.1663V9.16634C11.5 8.94533 11.4122 8.73337 11.2559 8.57709C11.0997 8.42081 10.8877 8.33301 10.6667 8.33301C10.4457 8.33301 10.2337 8.42081 10.0774 8.57709C9.92114 8.73337 9.83334 8.94533 9.83334 9.16634V14.1663C9.83334 14.3874 9.92114 14.5993 10.0774 14.7556C10.2337 14.9119 10.4457 14.9997 10.6667 14.9997Z" fill="#F75656" />
        </svg>
    )
}

export default DeleteIcon