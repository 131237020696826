import React from 'react'

function QAPaperIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0"
            color="#5E5E5E"

            viewBox="0 0 24 24"
        >
            <g stroke="none">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M16 20V4H4v15a1 1 0 001 1h11zm3 2H5a3 3 0 01-3-3V3a1 1 0 011-1h14a1 1 0 011 1v7h4v9a3 3 0 01-3 3zm-1-10v7a1 1 0 002 0v-7h-2zM6 6h6v6H6V6zm2 2v2h2V8H8zm-2 5h8v2H6v-2zm0 3h8v2H6v-2z"></path>
            </g>
        </svg>
    )
}

export default QAPaperIcon