import React from 'react'

function AssessmentOutlineIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 40 51" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.4583 30.1682C8.63232 30.3514 8.87705 30.4568 9.12969 30.4572H9.13106C9.3833 30.4572 9.62774 30.3526 9.80215 30.1701L10.9965 28.9189C11.2758 28.6263 11.265 28.1626 10.9724 27.8833C10.6797 27.604 10.2161 27.6147 9.93682 27.9074L9.13194 28.7506L8.99463 28.6061C8.71592 28.3128 8.25234 28.3011 7.95918 28.5796C7.66592 28.8583 7.654 29.3219 7.93262 29.6152L8.4583 30.1682Z" />
            <path d="M13.6782 26.0771C13.6782 25.404 13.1306 24.8564 12.4575 24.8564H6.4729C5.79976 24.8564 5.2522 25.404 5.2522 26.0771V32.0616C5.2522 32.7348 5.79976 33.2823 6.4729 33.2823H12.4575C13.1306 33.2823 13.6782 32.7348 13.6782 32.0616V26.0771ZM12.2133 31.8176H6.71704V26.3214H12.2133V31.8176Z" />
            <path d="M16.5315 33.2822H26.926C27.3305 33.2822 27.6584 32.9544 27.6584 32.5498C27.6584 32.1452 27.3305 31.8174 26.926 31.8174H16.5315C16.127 31.8174 15.7991 32.1452 15.7991 32.5498C15.7991 32.9544 16.127 33.2822 16.5315 33.2822Z" />
            <path d="M16.5315 30.291H26.926C27.3305 30.291 27.6584 29.9632 27.6584 29.5586C27.6584 29.154 27.3305 28.8262 26.926 28.8262H16.5315C16.127 28.8262 15.7991 29.154 15.7991 29.5586C15.7991 29.9632 16.127 30.291 16.5315 30.291Z" />
            <path d="M8.4585 19.8834C8.63262 20.0666 8.87744 20.1718 9.13008 20.172H9.13096C9.38331 20.172 9.62793 20.0673 9.80206 19.8849L10.9964 18.6337C11.2757 18.3411 11.265 17.8775 10.9724 17.5981C10.6798 17.3189 10.2161 17.3295 9.93682 17.6222L9.13184 18.4653L8.99463 18.3209C8.71592 18.0276 8.25234 18.0159 7.95918 18.2944C7.66592 18.573 7.654 19.0367 7.93262 19.33L8.4585 19.8834Z" />
            <path d="M13.6782 15.791C13.6782 15.1179 13.1306 14.5703 12.4575 14.5703H6.4729C5.79976 14.5703 5.2522 15.1179 5.2522 15.791V21.7756C5.2522 22.4487 5.79976 22.9963 6.4729 22.9963H12.4575C13.1306 22.9963 13.6782 22.4487 13.6782 21.7756V15.791ZM12.2133 21.5314H6.71704V16.0352H12.2133V21.5314Z" />
            <path d="M16.5315 22.9961H26.926C27.3305 22.9961 27.6584 22.6683 27.6584 22.2637C27.6584 21.8591 27.3305 21.5312 26.926 21.5312H16.5315C16.127 21.5312 15.7991 21.8591 15.7991 22.2637C15.7991 22.6683 16.127 22.9961 16.5315 22.9961Z" />
            <path d="M16.5315 20.0059H26.926C27.3305 20.0059 27.6584 19.678 27.6584 19.2734C27.6584 18.8688 27.3305 18.541 26.926 18.541H16.5315C16.127 18.541 15.7991 18.8688 15.7991 19.2734C15.7991 19.678 16.127 20.0059 16.5315 20.0059Z" />
            <path d="M8.4585 40.4733C8.63262 40.6565 8.87744 40.7617 9.13008 40.7619H9.13096C9.38331 40.7619 9.62793 40.6572 9.80206 40.4748L10.9964 39.2236C11.2757 38.931 11.265 38.4675 10.9724 38.1881C10.6798 37.9087 10.2161 37.9194 9.93682 38.2121L9.13184 39.0553L8.99463 38.9108C8.71592 38.6177 8.25234 38.6058 7.95918 38.8844C7.66592 39.163 7.654 39.6266 7.93262 39.9199L8.4585 40.4733Z" />
            <path d="M12.4575 35.1602H6.4729C5.79976 35.1602 5.2522 35.7077 5.2522 36.3809V42.3654C5.2522 43.0386 5.79976 43.5861 6.4729 43.5861H12.4575C13.1306 43.5861 13.6782 43.0386 13.6782 42.3654V36.3809C13.6782 35.7077 13.1306 35.1602 12.4575 35.1602ZM12.2133 42.1213H6.71704V36.625H12.2133V42.1213Z" />
            <path d="M28.9768 42.1211H16.5315C16.127 42.1211 15.7991 42.4489 15.7991 42.8535C15.7991 43.2581 16.127 43.5859 16.5315 43.5859H28.9768C29.3813 43.5859 29.7092 43.2581 29.7092 42.8535C29.7092 42.4489 29.3813 42.1211 28.9768 42.1211Z" />
            <path d="M15.7991 39.8633C15.7991 40.2679 16.127 40.5957 16.5315 40.5957H28.9768C29.3813 40.5957 29.7092 40.2679 29.7092 39.8633C29.7092 39.4587 29.3813 39.1309 28.9768 39.1309H16.5315C16.127 39.1309 15.7991 39.4588 15.7991 39.8633Z" />
            <path d="M33.3655 42.7266C32.961 42.7266 32.6331 43.0544 32.6331 43.459V48.0809C32.6331 48.5155 32.2794 48.8691 31.8447 48.8691H3.11665C2.68198 48.8691 2.32837 48.5155 2.32837 48.0809V8.79502C2.32837 8.36035 2.68198 8.00674 3.11665 8.00674H9.30689V9.80195C9.30689 10.8802 10.1841 11.7573 11.2624 11.7573H23.6991C24.7773 11.7573 25.6546 10.8802 25.6546 9.80195V8.00664H27.5808C27.9853 8.00664 28.3133 7.67881 28.3133 7.27422C28.3133 6.86963 27.9853 6.5418 27.5808 6.5418H25.6546V5.21602C25.6546 4.13779 24.7773 3.26055 23.6991 3.26055H21.2483V2.57812C21.2483 1.34062 20.2416 0.333984 19.0042 0.333984H15.9573C14.7199 0.333984 13.7131 1.34072 13.7131 2.57812V3.26045H11.2624C10.1841 3.26045 9.30689 4.1377 9.30689 5.21592V6.5417H3.11665C1.87427 6.5417 0.863525 7.55254 0.863525 8.79482V48.0807C0.863525 49.323 1.87427 50.3338 3.11665 50.3338H31.8448C33.0872 50.3338 34.098 49.3229 34.098 48.0807V43.459C34.0979 43.0544 33.77 42.7266 33.3655 42.7266ZM10.7717 5.21602C10.7717 4.94541 10.9919 4.72539 11.2624 4.72539H14.0721C14.6819 4.72539 15.178 4.22939 15.178 3.61963V2.57812C15.178 2.14844 15.5276 1.79883 15.9573 1.79883H19.0042C19.4338 1.79883 19.7835 2.14844 19.7835 2.57812V3.61953C19.7835 4.2293 20.2796 4.72529 20.8893 4.72529H23.6991C23.9696 4.72529 24.1897 4.94541 24.1897 5.21592V9.80176C24.1897 10.0723 23.9696 10.2923 23.6991 10.2923H11.2624C10.9919 10.2923 10.7717 10.0722 10.7717 9.80176V5.21602Z" />
            <path d="M38.0971 10.9024H36.6883V5.36992C36.6883 4.02646 35.5954 2.93359 34.252 2.93359H32.5072C31.1639 2.93359 30.0709 4.02646 30.0709 5.36992C30.0709 5.36992 30.0716 31.7969 30.0717 31.8019C30.0716 31.807 30.0709 31.8121 30.0709 31.8172V34.5238C30.0709 34.993 30.167 35.4484 30.3566 35.8775L32.1901 40.0296C32.4012 40.5077 32.857 40.8048 33.3796 40.8048C33.9023 40.8048 34.358 40.5077 34.5692 40.0296L36.4027 35.8776C36.5922 35.4483 36.6883 34.9929 36.6883 34.5237V31.8172C36.6883 31.812 36.6876 31.807 36.6875 31.8019C36.6876 31.7969 36.6883 27.0254 36.6883 27.0254C36.6883 26.6208 36.3604 26.293 35.9559 26.293C35.5514 26.293 35.2235 26.6208 35.2235 27.0254V31.0543H31.5359V9.83603H35.2235V23.9785C35.2235 24.3831 35.5514 24.7109 35.9559 24.7109C36.3604 24.7109 36.6883 24.3831 36.6883 23.9785V12.3673H38.097C38.1239 12.3673 38.1458 12.3893 38.1458 12.4161V20.6226C38.1458 21.0271 38.4737 21.355 38.8782 21.355C39.2827 21.355 39.6107 21.0271 39.6107 20.6226V12.4161C39.6108 11.5814 38.9318 10.9024 38.0971 10.9024ZM35.2235 34.5238C35.2235 34.788 35.1694 35.0443 35.0627 35.286L33.3797 39.0972L31.6967 35.2859C31.59 35.0443 31.536 34.788 31.536 34.5239V32.5497H35.2236V34.5238H35.2235ZM31.5359 8.37119V5.36992C31.5359 4.83428 31.9716 4.39844 32.5073 4.39844H34.2521C34.7877 4.39844 35.2236 4.83428 35.2236 5.36992V8.37109H31.5359V8.37119Z" />
        </svg>
    )
}

export default AssessmentOutlineIcon