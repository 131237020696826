import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import { API } from 'constants/apiEndPoints';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RightArrowIcon from 'svgIcons/RightArrowIcon';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';

function UploadedVideos() {
    const [uploadedVideos, setUploadedVideos] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUploadedVideos();
    }, []);

    const fetchUploadedVideos = async () => {
        const clickedCourse = getLocalStorage("clickedCourse");
        try {
            const resp = await axiosFetch(API.GET_ALL_CHAPTER + `?courseCode=${clickedCourse?.courseCode}`);
            setUploadedVideos(resp?.data);
        } catch (error) {
            // Handle error
        }
    };

    const onClickChapter = (cData) => {
        setLocalStorage({ key: "uploadedVideoClickedChapter", value: cData });
        navigate({
            pathname: '/all-courses/in-review/uploaded/chapter',
        });
    };

    return (
        <ul className={`${lessonStyles.chapterList} list-unstyled`}>
            <JsonViewer data={uploadedVideos} />
            {
                !uploadedVideos 
                ? <Loader className="text-prime my-3" center={true} /> 
                : uploadedVideos?.length 
                ? (
                    uploadedVideos.map((chapter, i) => (
                        <li key={i}>
                            <div className="row" role="button" onClick={() => onClickChapter(chapter)} >
                                <div className="col-10">
                                    <div className='fz-15px fw-500 text-dark'>{chapter?.chapterName}</div>
                                    <p className='fz-13px mt-1 fw-500 text-muted'>{`${chapter.lessonList.filter(lesson => lesson.isAssesment).length} Assessments & ${chapter.lessonList.filter(lesson => !lesson.isAssesment).length} Lessons`}</p>
                                </div>
                                <div className="col-2 text-end m-auto">
                                    <RightArrowIcon width={8} />
                                </div>
                            </div>
                        </li>
                    ))
                )
                : <h6 className='text-muted text-center'>No data available</h6>
            }
        </ul>
    );
}

export default UploadedVideos;
