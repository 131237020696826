import axiosFetch from '../../../axios/AxiosInterceptors';
import Loader from 'components/Loader';
import { API, socket } from 'constants/apiEndPoints';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { setLocalStorage } from 'utils/localStorage';
import Paginate from 'components/Paginate';
import JsonViewer from 'components/JsonViewer';
import moment from 'moment';

function CourseTicketList({ courseType, selectedDate }) {
    const [recordedCourseTicketData, setRecordedCourseTicketData] = useState(null)
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [SECONDS] = useState(5000);
    const courseListRef = useRef(null)
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        getRecordedCourseTickets();
        socket.open();
        const timeout = setTimeout(() => {
            setRenderCount(6);
        }, 2000);
        socket.on("connection-success", async (response) => {
            console.log(`Socket connected ${response.socketId}`);

        });

        const interval = setInterval(() => {
            newMessageCount(courseListRef.current);
        }, SECONDS);


        return () => {
            socket.close();
            clearTimeout(timeout);
            socket.disconnect();
            clearInterval(interval);
            socket.off("newMessageCount")
            socket.on("disconnect", function () { });
        }

    }, [courseType, selectedDate.from, selectedDate.to, renderCount])
    const newMessageCount = (data) => {
        socket.emit("newMessageCount", { ticketType: "COURSE", userType: "ADMIN" }, async (response) => {
            console.log("======>", response)
            let apiArry = data;
            let socketArry = response;
            let combinedArry = apiArry.map(obj1 => {
                let obj2 = socketArry.find(obj2 => obj1.courseCode === obj2.courseCode);
                return Object.assign({}, obj1, obj2);
            });
            setRecordedCourseTicketData(combinedArry)
        });


    }
    const getRecordedCourseTickets = async () => {
        try {
            const resp = await axiosFetch(API.GET_RECORDED_COURSE_TICKETS + `/${courseType}`);
            if (selectedDate.from && selectedDate.to) {
                const dateFiltered = resp.data.filter((item) => {
                    const itemDate = moment(item.createdAt).format("YYYY-MM-DD");
                    return itemDate >= selectedDate.from && itemDate <= selectedDate.to;
                });
                // console.log("========Filtr date========")
                // console.log(dateFiltered)
                courseListRef.current = dateFiltered
                setRecordedCourseTicketData(dateFiltered)
                newMessageCount(dateFiltered);


            } else {
                courseListRef.current = resp.data
                setRecordedCourseTicketData(resp.data)
                newMessageCount(resp.data);
            }
        } catch (error) {

        }
    }
    
    
    //after fetch
    useEffect(() => {
        if (recordedCourseTicketData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = recordedCourseTicketData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(recordedCourseTicketData.length / Number(itemsPerPage));
            setPageCount(pageCountResult)
        }
    }, [recordedCourseTicketData, itemOffset, itemsPerPage])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * Number(itemsPerPage)) % recordedCourseTicketData.length;
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
    };
    const onSeeMessage = async (data) => {
        setLocalStorage({ key: "clickedCourseTicket", value: data })
    }


    return (
        <>

            {!currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                currentItems.map((ticketData, index) => (

                    <div className='bg-white box-sd px-4 py-4 my-4 rounded-3' key={ticketData.courseCode + ticketData.createdAt}>

                        <div className="row g-2" >
                            <div className="col-5">
                                <JsonViewer data={ticketData} />
                                {/* <h1>{index + 1}</h1> */}
                                <h6 className='mb-2 text-muted fz-15px'>Course Name</h6>
                                <p className='fz-15px fw-500'>{ticketData?.courseName.length > 40 ? ticketData.courseName.substring(0, 70) + "..." : ticketData?.courseName}</p>
                            </div>
                            <div className="col-2">
                                <h6 className='mb-2 text-muted fz-15px'>Instructor</h6>
                                <p className='fz-15px fw-500 text-capitalize'>{ticketData?.teacherName}</p>
                            </div>
                            <div className="col-3">
                                <h6 className='mb-2 text-muted fz-15px'>Instructor Email</h6>
                                <p className='fz-15px fw-500'>{ticketData?.teacherEmail.length > 20 ? ticketData.teacherEmail.substring(0, 25) + '...' : ticketData?.teacherEmail}
                                    </p>
                            </div>
                            <div className="col-2 mt-auto">
                                <Link onClick={() => onSeeMessage(ticketData)} to="/ticket-message" className='text-center d-block border-0 bgLight600 text-prime fz-13px fw-500 p-2  w-100 rounded-3 position-relative'>
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5451 9.86233C11.0529 10.1905 10.4812 10.3639 9.89173 10.3639C9.30232 10.3639 8.73061 10.1905 8.23842 9.86233L0.131722 4.45769C0.0867458 4.4277 0.0429286 4.39644 0 4.3643V13.2204C0 14.2358 0.823989 15.0416 1.8212 15.0416H17.9622C18.9776 15.0416 19.7834 14.2176 19.7834 13.2204V4.36426C19.7404 4.39648 19.6965 4.42782 19.6514 4.45784L11.5451 9.86233Z" fill="#395061" />
                                        <path d="M0.774724 3.49223L8.88142 8.89691C9.1883 9.10151 9.54 9.20379 9.89169 9.20379C10.2434 9.20379 10.5952 9.10147 10.902 8.89691L19.0087 3.49223C19.4939 3.16901 19.7835 2.62806 19.7835 2.04422C19.7835 1.04032 18.9667 0.223633 17.9629 0.223633H1.82058C0.816725 0.223671 0 1.04036 0 2.04518C0 2.62806 0.289642 3.16901 0.774724 3.49223V3.49223Z" fill="#395061" />
                                    </svg>
                                    {ticketData?.newMessageCount ?
                                        <div className='ticket-notification-badge'>
                                            {ticketData?.newMessageCount}
                                        </div> : ""}
                                    <span className='px-2'>See Messages</span>
                                </Link>

                            </div>
                        </div>
                    </div>
                )) : "No Data Available!"
            }
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
        </>
    )
}

export default CourseTicketList