import axiosFetch from '../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
import { useEffect } from 'react';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useRecoilState } from 'recoil';
import { categoryFilterRecoilState, courseFilterRecoilState, dateFilterRecoilState, searchCourseRecoilState, subCategoryFilterRecoilState } from 'recoil/atoms/atomsStore';
import { cLog } from 'utils/logger';
import ActiveList from '../active/ActiveList';
import BannedList from '../banned/BannedList';
import InReviewList from '../inReview/InReviewList';
import JsonViewer from 'components/JsonViewer';
import moment from 'moment';


function Courses() {
    const [filterCourseRecoilAtom, setFilterCourseRecoilAtom] = useRecoilState(courseFilterRecoilState)
    const [catList, setCatList] = useState([])
    const [subCatList, setSubCatList] = useState([])
    const [searchCourseRecoilAtom, setSearchCourseRecoilAtom] = useRecoilState(searchCourseRecoilState);
    const [dateFilterRecoilAtom, setDateFilterRecoilAtom] = useRecoilState(dateFilterRecoilState);
    const [categoryFilterRecoilAtom, setCategoryFilterRecoilAtom] = useRecoilState(categoryFilterRecoilState)
    const [subCategoryFilterRecoilAtom, setSubCategoryFilterRecoilAtom] = useRecoilState(subCategoryFilterRecoilState)
    useEffect(() => {
        getAllCategory();
    }, [])     
    const getAllCategory = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_CATEGORY);
            setCatList(resp.data)
        } catch (error) {
        }
    }
    const onCategoryChange = async (e) => {
        setCategoryFilterRecoilAtom({ triggerChangeKey: Math.random(), value: e.target.value })
        setSubCategoryFilterRecoilAtom({ triggerChangeKey: "", value: "" })
        try {
            const resp = await axiosFetch(API.GET_SUB_CATEGORY_BY_CATEGORY_CODE + `?categoryCode=${e.target.value}`);
            setSubCatList(resp.data)
        } catch (error) {
        }
    }

    return (
        <div className="px-4">
            {/* ------Datefilter */}
            <div className='d-flex pb-3  justify-content-end align-items-center'>

                <div className="">
                    <label className='d-  text-muted fz-13px mx-2'>From</label>
                    <input type={"date"}
                        defaultValue={dateFilterRecoilAtom.fromDate}
                        onChange={(e) =>
                            setDateFilterRecoilAtom({
                                ...dateFilterRecoilAtom,
                                triggerChangeKey: Math.random(), fromDate: e.target.value
                            })}
                        className="py-2 fz-13px text-prime input mx-2" />
                </div>
                <div className="">
                    <label className='d-  text-muted fz-13px ms-2'>To</label>
                    <input type={"date"}
                        defaultValue={dateFilterRecoilAtom.toDate}
                        onChange={(e) =>
                            setDateFilterRecoilAtom({
                                ...dateFilterRecoilAtom,
                                triggerChangeKey: Math.random(), toDate: e.target.value
                            })}
                        className="py-2 fz-13px text-prime input ms-2" />
                </div>


            </div>
            <div className="bg-white rounded-2 px-5 py-5 mb-4">
                <div className="px-lg-3  theme-tab">

                    <JsonViewer data={dateFilterRecoilAtom} />

                    <Tab.Container defaultActiveKey="in_review_tab" id="hr-tabs-example" animation={true} mountOnEnter unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="d-inline-block">
                                            <Nav variant="pills" className='bg-light rounded-1'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="in_review_tab" className='rounded-1 fz-14px px-4 py-2'>In Review</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="active_tab" className='rounded-1 fz-14px px-4 py-2'>Activated</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="banned_tab" className='rounded-1 fz-14px px-4 py-2'>Deactivated</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="row g-2">
                                            <div className='col'>

                                                <input type={"search"} className="input py-2" placeholder='Search Course Name' defaultValue={searchCourseRecoilAtom.value}
                                                    onInput={(e) => {
                                                        if (e.target.value.trim() == "") {
                                                            e.target.value = "";
                                                        }
                                                    }}
                                                    onChange={(e) =>
                                                        setSearchCourseRecoilAtom({
                                                            triggerChangeKey: Math.random(), value: e.target.value.trim()
                                                        })}
                                                />
                                            </div>

                                            <div className='col'>

                                                <select className='py-2 w-100' defaultValue={filterCourseRecoilAtom.value} onChange={(e) => setFilterCourseRecoilAtom({ triggerChangeKey: Math.random(), value: e.target.value })}>
                                                    <option value="ALL">All</option>
                                                    <option value="LIVECOURSE">LiveCourse</option>
                                                    <option value="COURSE">Recorded Course</option>

                                                </select>
                                            </div>
                                            <div className='col'>
                                                <select className='py-2 w-100' defaultValue={categoryFilterRecoilAtom.value} onChange={onCategoryChange}>

                                                    <option value="" >Select Category</option>
                                                    {
                                                        catList ? catList.map((item) => (
                                                            <option value={item?.categoryCode} key={item._id}>{item?.categoryName}</option>
                                                        ))

                                                            : ""
                                                    }

                                                </select>
                                            </div>
                                            <div className='col'>
                                                <select className='py-2 w-100' defaultValue={subCategoryFilterRecoilAtom.value} onChange={(e) => setSubCategoryFilterRecoilAtom({ triggerChangeKey: Math.random(), value: e.target.value })}>
                                                    <option value="" >Select Sub Category</option>
                                                    {
                                                        subCatList ? subCatList.map((item) => (
                                                            <option value={item?.subCategoryCode} key={item._id}>{item?.subCategoryName}</option>
                                                        ))

                                                            : ""
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <div className="py-3"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="in_review_tab">
                                        <InReviewList />

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="active_tab">
                                        <ActiveList />

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="banned_tab">
                                        <BannedList />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>

    )
}

export default Courses