import React from 'react'

function PencilIcon({ width, height, color, className }) {
    return (
        <svg width={width} height={height} fill={color} className={className} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.976666 15.9326C0.351502 16.5576 0.000188791 17.4053 0 18.2893L0 20.0001H1.71083C2.59482 19.9999 3.44252 19.6486 4.0675 19.0235L15.1867 7.90431L12.0958 4.81348L0.976666 15.9326Z" fill="#395061" />
            <path d="M19.2875 0.712066C19.0846 0.508929 18.8436 0.347779 18.5784 0.23783C18.3131 0.127881 18.0288 0.0712891 17.7417 0.0712891C17.4545 0.0712891 17.1702 0.127881 16.905 0.23783C16.6397 0.347779 16.3988 0.508929 16.1958 0.712066L13.2742 3.63456L16.365 6.7254L19.2875 3.80373C19.4906 3.60081 19.6518 3.35983 19.7617 3.09459C19.8717 2.82934 19.9283 2.54503 19.9283 2.2579C19.9283 1.97077 19.8717 1.68645 19.7617 1.42121C19.6518 1.15596 19.4906 0.914991 19.2875 0.712066Z" fill="#395061" />
        </svg>
    )
}

export default PencilIcon