import React from 'react'

function OutlinePointIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={"white"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#8C8C8C" strokeWidth="2" />
        </svg>
    )
}

export default OutlinePointIcon