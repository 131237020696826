import React, { useState, useEffect } from 'react'
import Paginate from 'components/Paginate';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
// import PrinterSVG from '../../svgs/Printer';
// import FileDownload from '../../svgs/FileDownload'

function ViewLearnersList() {
    // const [activeTeachersData, setActiveTeachersData] = useState(null);
    const [courseLearnersList, setCourseLearnersList] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const params = useParams();

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % courseLearnersList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    // const getInstructorTotalCourse = async () => {
    //     try {
    //         const jsonData = { "userType": "ADMIN" }
    //         const resp = await axiosFetch.post(API.INSTRUCTOR_TOTAL_COURSES + `?instructorId=${params.teacherId}`, jsonData);
    //         setcashInfo((prev) => ({ ...prev, totalCourses: resp.data.allCoursesLength }))

    //     } catch (error) {

    //     }

    // }

    const getCourseLearnersList = async (courseCode) => {
        try {
            const jsonData = { "userType": "ADMIN", "courseCode": courseCode }
            const resp = await axiosFetch.post(API.COURSE_STUDENT_LIST, jsonData);
            setCourseLearnersList(resp.data)
            // setcashInfo((prev) => ({ ...prev, totalCourses: resp.data.allCoursesLength }))
        } catch (error) {

        }
    }

    useEffect(() => {
        if (params.student_id) {
            getCourseLearnersList(params.student_id)
        }
    }, [params.student_id])

    return (
        <div className="px-4">
            <div className="p-3 bg-white rounded-3">
                <div className="px-lg-4 theme-tab"></div>
                {/* <button
                    className='btn'
                    style={{
                        marginLeft: 'auto',
                        display: 'block',
                        backgroundColor: '#F0E1EB'
                    }}
                >
                    <PrinterSVG /> Print
                </button> */}

                {/* <p>{JSON.stringify(courseLearnersList)}</p> */}

                <div className="table-responsive mt-4">
                    <table className="table table-borderless align-middle">
                        <thead>
                            <tr>
                                <th className='fw-500 text-muted fz-15px'>Joined Date</th>
                                <th className='fw-500 text-muted fz-15px'>Learners Name</th>
                                <th className='fw-500 text-muted fz-15px'>Email</th>
                                <th className='fw-500 text-muted fz-15px'>Total Course</th>
                            </tr>
                        </thead>
                        <tbody>


                            {courseLearnersList.map(course => (
                                <tr key={course._id} style={{ lineHeight: '45px' }}>
                                    <td className='fz-15px'>
                                        {course.joinedDate ? moment(course.joinedDate).format('DD MMMM, YYYY') : ''}
                                    </td>
                                    <td className='fz-15px d-flex align-items-center gap-2'>
                                        <img
                                            src="https://ik.imagekit.io/42vct06fb/web_site/unsplash_KIPqvvTOC1s_J7uHRc2h2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677735859165"
                                            alt="Profile pic"
                                            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                        />
                                        <span>{course.fullName}</span>
                                    </td>
                                    <td className='fz-15px'>{course.email}</td>
                                    <td className='fz-15px'>
                                        {course.total_courses < 10 ? `0${course.total_courses}` : course.total_courses}
                                    </td>
                                </tr>
                            ))}



                            {/* <tr style={{ lineHeight: '45px' }}>
                                <td className='fz-15px'>03 March , 2022</td>
                                <td className='fz-15px d-flex align-items-center gap-2'>
                                    <img
                                        src="https://ik.imagekit.io/42vct06fb/web_site/unsplash_KIPqvvTOC1s_J7uHRc2h2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677735859165"
                                        alt="Profile pic"
                                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                    />
                                    <span>MN Nahid</span>
                                </td>
                                <td className='fz-15px'>mnnahid_T @gmail.com</td>
                                <td className='fz-15px'>05</td>
                            </tr> */}

                            {/* <tr style={{ lineHeight: '45px' }}>
                                <td className='fz-15px'>03 March , 2022</td>
                                <td className='fz-15px d-flex align-items-center gap-2'>
                                    <img
                                        src="https://ik.imagekit.io/42vct06fb/web_site/unsplash_KIPqvvTOC1s_J7uHRc2h2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677735859165"
                                        alt="Profile pic"
                                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                    />
                                    <span>MN Nahid</span>
                                </td>
                                <td className='fz-15px'>mnnahid_T @gmail.com</td>
                                <td className='fz-15px'>05</td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-lg-8">
                            <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                        </div>
                        <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                            Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select> courses per page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewLearnersList