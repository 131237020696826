import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import { getLocalStorage } from 'utils/localStorage';
import Loader from 'components/Loader';
import VideoPlayer from 'components/VideoPlayer';
import MyS3Component from 'components/S3';

function OverviewRecordedCourse() {
    const clickedCourse = getLocalStorage("clickedCourse");
    const [overviewData, setOverviewData] = useState(null);
    useEffect(() => {
        fetchOverview();
    }, [])
    const fetchOverview = async () => {
        try {
            const resp = await axiosFetch(API.GET_COURSE_OVERVIEW + `?courseCode=${clickedCourse?.courseCode}`);
            setOverviewData(resp?.data)

        } catch (error) {

        }
    }
    return (
      <div>
        {!overviewData ? (
          <Loader className="text-prime my-3" center={true} />
        ) : overviewData?.length ? (
          <>
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">courseName: </h6>
              <h6 className="fw-400 mx-1"> {overviewData[0]?.courseName}</h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">courseOverview: </h6>
              <h6
                className="fw-400 mx-1"
                dangerouslySetInnerHTML={{
                  __html: overviewData[0]?.courseOverview,
                }}
              ></h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Category: </h6>
              <h6 className="fw-400 mx-1">{overviewData[0]?.categoryName}</h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">subCategory: </h6>
              <h6 className="fw-400 mx-1">
                {overviewData[0]?.subCategoryName}
              </h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Fee: </h6>
              <h6 className="fw-400 mx-1">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: overviewData[0]?.currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(overviewData[0]?.fee)}{" "}
              </h6>
            </div>

            <div className="row py-1">
              <div className="col-lg-6 my-auto">
                <MyS3Component
                  src={overviewData[0]?.thumbNailImagePath}
                  className="d-block mx-auto thumbnail"
                />
              </div>
              <div className="col-lg-6">
                <MyS3Component
                  src={overviewData[0]?.introVideoPath}
                  isVideoPlayer={true}
                />
              </div>
            </div>
          </>
        ) : (
          "No data"
        )}
        <JsonViewer data={overviewData} />
      </div>
    );
}

export default OverviewRecordedCourse