import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ActivatedAssessment from '../activeIndependentAssesment/ActivatedAssessment';
import DeactivatedAssessment from '../deActivatedIndependentAssesment/DeactivatedAssessment';
import InReviewAssessment from '../inReviewAssessment/InReviewAssessment';
import { useState } from 'react';


function IndependentAssessment() {
    const [activeTab, setActiveTab] = useState("in_review_tab")
    const [inReviewSearchValue, setInReviewSearchValue] = useState("")
    const [activatedSearchValue, setActivatedSearchValue] = useState("")
    const [deactivatedSearchValue, setDeactivatedSearchValue] = useState("")

    return (
        <div className="px-4">
            <div className="bg-white rounded-2 px-5 py-5 mb-4">
                <div className="px-lg-3  theme-tab">
                    <Tab.Container defaultActiveKey="in_review_tab" id="hr-tabs-example" animation={true} unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="d-inline-block">
                                            <Nav variant="pills" className='bg-light rounded-1'>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="in_review_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setActiveTab("in_review_tab")}>In Review</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="active_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setActiveTab("active_tab")}>Activated</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="banned_tab" className='rounded-1 fz-14px px-5 py-2' onClick={() => setActiveTab("banned_tab")}>Deactivated</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="col">

                                        {activeTab == "in_review_tab" ? <input type={"search"} className="input w-100" placeholder='search by title' onChange={(e) => setInReviewSearchValue(e.target.value)} /> : ""}
                                        {activeTab == "active_tab" ? <input type={"search"} className="input w-100" placeholder='search by title' onChange={(e) => setActivatedSearchValue(e.target.value)} /> : ""}
                                        {activeTab == "banned_tab" ? <input type={"search"} className="input w-100" placeholder='search by title' onChange={(e) => setDeactivatedSearchValue(e.target.value)} /> : ""}

                                    </div>
                                </div>

                            </Col>
                            <div className="py-3"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="in_review_tab">
                                        <InReviewAssessment onSearch={inReviewSearchValue} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="active_tab">
                                        <ActivatedAssessment onSearch={activatedSearchValue} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="banned_tab">
                                        <DeactivatedAssessment onSearch={deactivatedSearchValue} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default IndependentAssessment