import axiosFetch from '../../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'utils/confirmAlert';
import teacherStyles from "../../../../moduleCss/teacher.module.css"
import JsonViewer from 'components/JsonViewer';
import moment from 'moment';
import Paginate from 'components/Paginate';
import { cLog } from 'utils/logger';
import { toast } from 'react-hot-toast';

function DeactiveRequestList({ onSearch }) {
    const [deactiveListData, setDeactiveList] = useState(null);
    const [searchData, setSearchData] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);

    const onDeactive = async (tData) => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to Deactivate?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {

            try {
                const jsonData = { "userType": "ADMIN", "userId": tData?._id }
                const resp = await axiosFetch.post(API.DEACTIVATE_STUDENT, jsonData);
                const update = await getDeactiveReqList();
                toast.success('Student deactivated successfully');

            } catch (error) {

            }

        }
    }
    //search effect
    useEffect(() => {
        if (onSearch && searchData) {
            const dataArry = searchData
            const filteredData = dataArry.filter((item) =>
                item.fullName.toLowerCase().includes(onSearch.toLowerCase())
            );

            setDeactiveList(filteredData)
        } else {
            setDeactiveList(searchData)
        }
    }, [onSearch])
    // ---api 1st use effect-----
    useEffect(() => {
        getDeactiveReqList();
    }, [])
    const getDeactiveReqList = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_DEACTIVATE_REQUESTED_STUDENT);
            setDeactiveList(resp.data);
            setSearchData(resp.data)

        } catch (error) {

        }
    }
    //Pagination effect
    useEffect(() => {
        if (deactiveListData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = deactiveListData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(deactiveListData.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [deactiveListData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % deactiveListData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <div className="table-responsive">
            <table className="table table-borderless align-middle">
                <thead>
                    <tr>
                        <th className='fw-500 text-muted fz-15px'>Requested Date</th>
                        <th className='fw-500 text-muted fz-15px'>Student Name</th>
                        <th className='fw-500 text-muted fz-15px'>Email</th>

                        <th className='fw-500 text-muted fz-15px'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !currentItems ? <tr className='text-center'>
                            <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                        </tr> : currentItems?.length ?

                            currentItems?.map((tData, i) => (
                                <tr key={tData?.deactivateCompletionDate + i}>

                                    <td className='fz-15px'>{moment(tData?.deactivateReqDate).format('DD MMMM , YYYY')}</td>
                                    {/* <td><img src="https://www.efilecabinet.com/wp-content/uploads/2019/05/g2-testimonial-male.jpg" className={`${teacherStyles.tImg}`} />{fullName}</td> */}
                                    <td className='fz-15px'>{tData?.fullName}</td>
                                    <td className='fz-15px'>{tData?.email}</td>
                                    <td><span className={`${teacherStyles.bgTomato} ${teacherStyles.status} text-white fz-15px`} onClick={() => onDeactive(tData)}>Deactivate</span></td>
                                </tr>
                            ))

                            :
                            <tr className='text-center'>
                                <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                            </tr>
                    }

                </tbody>
            </table>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> Student per page
                </div>
            </div>
            <JsonViewer data={deactiveListData} />
        </div>

    )
}

export default DeactiveRequestList