import axiosFetch from '../../../../../../axios/AxiosInterceptors'
import JsonViewer from 'components/JsonViewer'
import { API } from 'constants/apiEndPoints'
import React from 'react'
import { Stack } from 'react-bootstrap'
import { toast, Toaster } from 'react-hot-toast'
import { useRecoilValue } from 'recoil'
import { liveCourseOverviewRecoilState } from 'recoil/atoms/atomsStore'
import ArrowLeft from 'svgIcons/ArrowLeft'
import OutlinePointIcon from 'svgIcons/OutlinePointIcon'
import { confirmAlert } from 'utils/confirmAlert'
import lessonStyles from '../../../../../../moduleCss/chapter.module.css'

function LiveCourseClickedAssessment({ setClickedAssessmentView, data }) {
    const liveCourseOverviewRecoilAtom = useRecoilValue(liveCourseOverviewRecoilState);
    const onRejectLiveCourse = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to reject this assessment?", cancelText: "Cancel", confirmText: "Reject" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    "courseCode": liveCourseOverviewRecoilAtom[0]?.courseCode,
                    "assessmentCode": data.assessmentCode
                }
                const resp = await axiosFetch.post(API.REJECT_LIVE_COURSE_ASSESSMENT, jsonData);
                toast.success('Assessment rejected successfully');


            } catch (error) {

            }

        }
    }

    const onActivateLiveCourse = async () => {
        try {
            const jsonData = {
                "courseCode": liveCourseOverviewRecoilAtom[0]?.courseCode,
                "assessmentCode": data.assessmentCode
            }
            const resp = await axiosFetch.post(API.APPROVE_LIVE_COURSE_ASSESSMENT, jsonData);
            toast.success('Assessment approved successfully');

        } catch (error) {

        }

    }

    return (
        <div className="px-4">

            {/* <JsonViewer data={data} /> */}

            {/* <h6 className='text-dark fz-14px py-3'>Total Points 10</h6> */}
            <Stack direction="horizontal" gap={4} className="mb-4">
                <h6 className='text-dark d-inline-block text-capitalize mb-4 me-auto' role={"button"} onClick={() => setClickedAssessmentView(null)}><ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} /> {data?.assessmentTitle}</h6>
                <div className="textTomato fz-14px fw-500 " onClick={onRejectLiveCourse} role={"button"}>Reject</div>
                <div className="bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1" role={"button"} onClick={onActivateLiveCourse}>Approve</div>
            </Stack>
            {data?.assessmentDetails ?
                data?.assessmentDetails?.map((assessmentData, assesQuestionIndex) => {
                    return (
                        <div key={assessmentData._id}>
                            <Stack direction="horizontal" gap={4} className="py-2">
                                <h6>  {assesQuestionIndex + 1 + "."} {assessmentData.assessmentQuestion}</h6>
                                <div className="bg-light fz-12px fw-500 px-3 ms-auto py-1 rounded-1">{assessmentData?.point} Point</div>
                            </Stack>
                            {
                                assessmentData.assessmentChoice.map((assessmentOptions, assessmentOptionKey) => {
                                    return (
                                        <div className='py-2' key={assessmentOptionKey + assessmentOptions}>
                                            <p><OutlinePointIcon width={13} /> {assessmentOptions}</p>


                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }) : "Loading..."}

            <JsonViewer data={data} />

        </div>
    )
}

export default LiveCourseClickedAssessment