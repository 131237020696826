import Swal from "sweetalert2"

export const confirmAlert = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-cancel-btn', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result

    })
}

export const confirmAlertSuccess = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-success-btn ', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result

    })
}

export const confirmLogOut = (prop) => {
    return Swal.fire({
        title: prop.title,
        showCancelButton: true,
        imageUrl: "https://cdn-icons-png.flaticon.com/512/4034/4034229.png",
        imageHeight: 80,
        imageWidth: 80,
        imageClass: 'img-fluid',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmText,
        cancelButtonText: prop.cancelText,
        reverseButtons: true,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "swalTitle",
            confirmButton: 'swal-cancel-btn', //btn reversed so css reversed
            cancelButton: 'swal-confirm-btn',
        },
    }).then((result) => {
        return result

    })
}