import axiosFetch from '../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from 'utils/localStorage';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import OpenEyeIcon from 'svgIcons/OpenEyeIcon';
import CloseEyeIcon from 'svgIcons/CloseEyeIcon';
function Login() {
    const navigate = useNavigate();
    const [isText, setText] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onLogin = async (data) => {
        try {
            const loginDetails = {
                email: data.email,
                password: data.password,
                userType: "ADMIN"
            }
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
            const resp = await axiosFetch.post(API.LOGIN, loginDetails, { headers });
            setLocalStorage({ key: "userData", value: resp.data })
            navigate("/")
        } catch (error) {
        }
    }

    return (
        <form onSubmit={handleSubmit(onLogin)} className='p-0 m-0 bg-light'>

            <div className='row g-0'>
                <div className="col-lg-6 ms-auto  my-auto  px-5">
                    <h6 className='my-4'>Welcome Back</h6>

                    <div className=" bg-white ms-auto p-5 rounded-4">
                        <label>Email Address</label>
                        <input className='input d-block my-3 py-2 w-100' name='email' type={"email"}
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Please enter your email address",
                                },
                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address",
                                }
                            }
                            )}
                        />
                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.email?.message && (errors.email?.message)}</small>

                        <label>Password</label>
                        <div className=' position-relative'>
                            <input className='input d-block py-2 w-100 my-3' type={isText ? "text" : "password"} {...register("password", {
                                required: {
                                    value: true,
                                    message: "Please enter your password",
                                }
                            }
                            )} />
                            <button type='button' className='bg-transparent border-0 position-absolute top-0 bottom-0 end-0 pe-3' onClick={() => setText(!isText)}>{isText ? <OpenEyeIcon /> : <CloseEyeIcon />}</button>
                        </div>
                        <small className='text-danger mb-2 d-block fz-12px fw-500'> {errors.password?.message && (errors.password?.message)}</small>

                        <button className='border-0  w-100 bg-prime text-white fz-14px px-4 py-2 rounded-1' type='submit'>Submit</button>
                    </div>

                </div>
                <div className="col-lg-6">
                    <img src='https://images.unsplash.com/photo-1632406898177-95f7acd8854f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80' className='img-fluid vh-100 w-100' style={{ objectFit: "cover" }} />
                </div>
            </div>
        </form>
    )
}
export default Login