import React from 'react'

function PrinterIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3958 18.75H19.2708C18.9833 18.75 18.75 18.5167 18.75 18.2292C18.75 17.9417 18.9833 17.7083 19.2708 17.7083H22.3958C23.2573 17.7083 23.9583 17.0073 23.9583 16.1458V8.85417C23.9583 7.99271 23.2573 7.29167 22.3958 7.29167H2.60417C1.74271 7.29167 1.04167 7.99271 1.04167 8.85417V16.1458C1.04167 17.0073 1.74271 17.7083 2.60417 17.7083H5.72917C6.01666 17.7083 6.25 17.9417 6.25 18.2292C6.25 18.5167 6.01666 18.75 5.72917 18.75H2.60417C1.16771 18.75 0 17.5812 0 16.1458V8.85417C0 7.41875 1.16771 6.25 2.60417 6.25H22.3958C23.8323 6.25 25 7.41875 25 8.85417V16.1458C25 17.5812 23.8323 18.75 22.3958 18.75Z" fill="#395061" />
            <path d="M15.1043 21.8747H8.85433C8.56683 21.8747 8.3335 21.6413 8.3335 21.3538C8.3335 21.0663 8.56683 20.833 8.85433 20.833H15.1043C15.3918 20.833 15.6252 21.0663 15.6252 21.3538C15.6252 21.6413 15.3918 21.8747 15.1043 21.8747Z" fill="#395061" />
            <path d="M15.1043 19.7917H8.85433C8.56683 19.7917 8.3335 19.5583 8.3335 19.2708C8.3335 18.9833 8.56683 18.75 8.85433 18.75H15.1043C15.3918 18.75 15.6252 18.9833 15.6252 19.2708C15.6252 19.5583 15.3918 19.7917 15.1043 19.7917Z" fill="#395061" />
            <path d="M10.9377 17.7087H8.85433C8.56683 17.7087 8.3335 17.4753 8.3335 17.1878C8.3335 16.9003 8.56683 16.667 8.85433 16.667H10.9377C11.2252 16.667 11.4585 16.9003 11.4585 17.1878C11.4585 17.4753 11.2252 17.7087 10.9377 17.7087Z" fill="#395061" />
            <path d="M19.271 7.29166C18.9835 7.29166 18.7502 7.05833 18.7502 6.77083V2.60417C18.7502 1.74271 18.0491 1.04167 17.1877 1.04167H7.81266C6.9512 1.04167 6.25016 1.74271 6.25016 2.60417V6.77083C6.25016 7.05833 6.01683 7.29166 5.72933 7.29166C5.44183 7.29166 5.2085 7.05833 5.2085 6.77083V2.60417C5.2085 1.16875 6.3762 0 7.81266 0H17.1877C18.6241 0 19.7918 1.16875 19.7918 2.60417V6.77083C19.7918 7.05833 19.5585 7.29166 19.271 7.29166Z" fill="#395061" />
            <path d="M17.1877 25.0003H7.81266C6.3762 25.0003 5.2085 23.8316 5.2085 22.3962V14.0628C5.2085 13.7753 5.44183 13.542 5.72933 13.542H19.271C19.5585 13.542 19.7918 13.7753 19.7918 14.0628V22.3962C19.7918 23.8316 18.6241 25.0003 17.1877 25.0003ZM6.25016 14.5837V22.3962C6.25016 23.2576 6.9512 23.9587 7.81266 23.9587H17.1877C18.0491 23.9587 18.7502 23.2576 18.7502 22.3962V14.5837H6.25016Z" fill="#395061" />
        </svg>
    )
}

export default PrinterIcon