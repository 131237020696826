import React from 'react'

function PlayIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F0E1EB" />
            <path d="M25.0996 16.6344C25.7663 17.0193 25.7663 17.9815 25.0996 18.3664L14.4496 24.5152C13.7829 24.9001 12.9496 24.419 12.9496 23.6492L12.9496 11.3516C12.9496 10.5818 13.7829 10.1007 14.4496 10.4856L25.0996 16.6344Z" fill="#395061" />
        </svg>
    )
}

export default PlayIcon