import 'bootstrap/dist/css/bootstrap.min.css';
import { Toaster } from 'react-hot-toast';
import "./index.css";
import RouterMain from './router/RouterMain';
import { useEffect } from 'react';
import { requestForToken } from './firebase/firebase';

function App() {
  useEffect(() => {
    requestForToken()
  }, [])

  return (
    <>
      <Toaster position="top-right"
        toastOptions={{
          className: 'fz-13px fw-500'
        }} />
      <RouterMain />
    </>
  )

}

export default App;
