import React from 'react'
import ReactPaginate from 'react-paginate'
import PaginationStyle from "../moduleCss/pagination.module.css"
function Paginate({ onPageChange, pageCount, isShow }) {
    return (
        <>
            {isShow ?
                <ReactPaginate
                    breakLabel="..."
                    onPageChange={onPageChange}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    previousLabel={"<- Previous"}
                    nextLabel={"Next ->"}
                    containerClassName={PaginationStyle.pagination}
                    previousLinkClassName={PaginationStyle.pagination_link}
                    nextLinkClassName={PaginationStyle.pagination_link}
                    disabledClassName={PaginationStyle.pagination_link_disabled}
                    activeClassName={PaginationStyle.pagination_link_active}
                /> : ""}
        </>
    )
}

export default Paginate