import React from 'react'
import cardStyle from "../../../../moduleCss/cards.module.css"
import { Link } from 'react-router-dom'
import { setLocalStorage } from 'utils/localStorage'
import moment from 'moment'
import MyS3Component from 'components/S3'
function LiveCourseInreviewLayout({ data }) {
    const onViewApprove = () => {
        setLocalStorage({ key: "clickedCourse", value: data })
    }

    return (
        <div className={`${cardStyle.crdHover} bg-white box-sd p-4 rounded-3 mb-4`}>
            {/* {JSON.stringify(data)} */}
            <div className="row">
                <div className="col-lg-3 m-auto">
                    <MyS3Component src={data?.thumbNailImagePath} className={`${cardStyle.crdImg} rounded-3`} />
                </div>
                <div className="col-lg-7 m-auto">
                    <h6 className='mb-3'>{data?.courseName}</h6>

                    <div className="d-flex mb-3">
                        <p><span className='fz-14px fw-500 text-muted'>By</span> <span className='fz-14px fw-600 text-prime'>{data?.instructorName}</span></p>
                        <p className='mx-4'><span className='fz-14px fw-500 px-3 py-1 rounded-5 fz-13px bgTomato text-white'>Live Courses</span></p>
                    </div>
                    <p className='fz-13px  mb-2 fw-500 text-warning'>  {data?.inReviewLiveAssessmentCount}{" Assessment Pending In Review"}</p>

                    <p className='fz-13px  mb-2 fw-500 text-prime'>{moment(data?.fromDate).format("DD-MM-YYYY")} to {moment(data?.toDate).format("DD-MM-YYYY")} ({data?.courseDuration}) </p>

                    <div className="d-flex mb-3">
                        <p className='me-3'><span className='fz-14px fw-500 text-muted'>Category</span> <span className='fz-14px fw-600 text-prime'>{data?.category}</span></p>
                        <p><span className='fz-14px fw-500 text-muted'>Sub - Category</span> <span className='fz-14px fw-600 text-prime'>{data?.subCategory}</span></p>
                    </div>

                    <h6 className='fw-600 text-prime'>₹{data?.cost}</h6>




                </div>

                <div className="col-lg-2 m-auto"> <Link to="/all-courses/in-review/view-approve"><button className={`textGreen bgGreenLight border-0 w-100 py-2 rounded fz-14px fw-500`} onClick={onViewApprove}>View & Approve</button></Link>   </div>

            </div>
        </div>
    )
}

export default LiveCourseInreviewLayout