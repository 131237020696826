import axiosFetch from '../../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import VideoPlayer from 'components/VideoPlayer';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import { getLocalStorage } from 'utils/localStorage';
import { liveCourseOverviewRecoilState } from 'recoil/atoms/atomsStore';
import { useRecoilState } from 'recoil';
import MyS3Component from 'components/S3';

function LiveCourseOverview() {
    const clickedCourse = getLocalStorage("clickedCourse");
    const [liveCourseOverviewRecoilAtom, setLiveCourseOverviewRecoilAtom] = useRecoilState(liveCourseOverviewRecoilState)

    useEffect(() => {
        fetchOverview();
    }, [])
    const fetchOverview = async () => {
        try {
            const resp = await axiosFetch(API.GET_LIVE_COURSE_BY_COURSE_CODE + `?courseCode=${clickedCourse?.courseCode}`);
            setLiveCourseOverviewRecoilAtom(resp?.data)

        } catch (error) {

        }
    }
    return (
      <div>
        {!liveCourseOverviewRecoilAtom ? (
          <Loader className="text-prime my-3" center={true} />
        ) : liveCourseOverviewRecoilAtom?.length ? (
          <>
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Course Title: </h6>
              <h6 className="fw-400 mx-1">
                {" "}
                {liveCourseOverviewRecoilAtom[0]?.courseName}
              </h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Course Overview: </h6>
              <h6
                className="fw-400 mx-1"
                dangerouslySetInnerHTML={{
                  __html: liveCourseOverviewRecoilAtom[0]?.courseOverview,
                }}
              ></h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Category: </h6>
              <h6 className="fw-400 mx-1">
                {liveCourseOverviewRecoilAtom[0]?.categoryName}
              </h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">subCategory: </h6>
              <h6 className="fw-400 mx-1">
                {liveCourseOverviewRecoilAtom[0]?.subCategoryName}
              </h6>
            </div>
            <div className="d-flex py-1">
              <h6 className="mb-2 text-muted">Fee: </h6>
              <h6 className="fw-400 mx-1">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: liveCourseOverviewRecoilAtom[0]?.currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(liveCourseOverviewRecoilAtom[0]?.fee)}{" "}
              </h6>
            </div>

            <div className="row py-1">
              <div className="col-lg-6 my-auto">
                <MyS3Component
                  src={liveCourseOverviewRecoilAtom[0]?.thumbNailImagePath}
                  className="d-block mx-auto thumbnail"
                />
              </div>
              <div className="col-lg-6">
                
                <MyS3Component
                  src={liveCourseOverviewRecoilAtom[0]?.introVideoPath}
                  isVideoPlayer={true}
                />
              </div>
            </div>
          </>
        ) : (
          "No data"
        )}
        <JsonViewer data={liveCourseOverviewRecoilAtom} />
      </div>
    );
}

export default LiveCourseOverview