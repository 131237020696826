import axiosFetch from '../../../axios/AxiosInterceptors'
import { API, socket } from 'constants/apiEndPoints'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { setLocalStorage } from 'utils/localStorage'
import JsonViewer from 'components/JsonViewer'
import Loader from 'components/Loader'
import Paginate from 'components/Paginate'
import moment from 'moment'

function TransactionTicketList({ search, selectedDate }) {
    const [transactionTicketData, setTransactionTicketData] = useState(null)
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const onSeeMessage = async (data) => {
        setLocalStorage({ key: "clickedTransactionTicketData", value: data })
    }
    const [SECONDS] = useState(5000);
    const transactionListRef = useRef(null)
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        getTransactionTickets();
        const timeout = setTimeout(() => {
            setRenderCount((prevCount) => prevCount + 1);
        }, 2000);
        const interval = setInterval(() => {
            newMessageCount(transactionListRef.current);
        }, SECONDS);
        socket.open();
        socket.on("connection-success", async (response) => {
            console.log(`Socket connected ${response.socketId}`);
        });
        return () => {
            clearTimeout(timeout);
            socket.close();
            socket.disconnect();
            clearInterval(interval);
            socket.off("newMessageCount")
            socket.on("disconnect", function () { });
        }

    }, [search, selectedDate?.from, selectedDate?.to,renderCount])
    const newMessageCount = (data) => {
        socket.emit("newMessageCount", { ticketType: "TRANSACTION", userType: "ADMIN" }, async (response) => {
            console.log("======>", response)
            let apiArry = data;
            let socketArry = response;
            let combinedArry = apiArry.map(obj1 => {
                let obj2 = socketArry.find(obj2 => obj1.transactionCode === obj2.courseCode);
                return Object.assign({}, obj1, obj2);
            });
            setTransactionTicketData(combinedArry)
        });
    }
    useEffect(() => {
        getTransactionTickets();
    }, [search, selectedDate?.from, selectedDate?.to])
    const getTransactionTickets = async () => {
        try {
            const resp = await axiosFetch(API.GET_TRANSACTION_TICKETS + `/?instructorEmail=${search}&fromDate=${selectedDate?.from}&toDate=${selectedDate?.to}`);
            // const resp = await axiosFetch(API.GET_TRANSACTION_TICKETS);
            transactionListRef.current = resp.data
            setTransactionTicketData(resp.data)
            newMessageCount(resp.data);

        } catch (error) {

        }
    }
    useEffect(() => {
        if (transactionTicketData) {
            const sortedData = [...transactionTicketData].sort((a, b) =>
            new Date(b.requestedDate) - new Date(a.requestedDate)
        );
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = sortedData.slice(itemOffset, endOffset);
        setCurrentItems(sliceItem);
        let pageCountResult = Math.ceil(sortedData.length / itemsPerPage);
        setPageCount(pageCountResult);
        }
    }, [transactionTicketData, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % transactionTicketData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <>
            {
                !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?

                    currentItems.map((tData, i) => ((
                        <div className='bg-white box-sd my-4 px-4 py-4 rounded-3' key={tData?.transactionCode}>

                            <div className="row g-2">
                                <div className="col">
                                    <h6 className='mb-2 text-muted fz-15px'>Time Stamp</h6>
                                    <p className='fz-15px fw-500'>{moment(tData?.requestedDate).format("DD MMM, YYYY")}</p>
                                </div>
                                <div className="col">
                                    <h6 className='mb-2 text-muted fz-15px'>Name</h6>
                                    <p className='fz-15px fw-500'>{tData?.teacherName}</p>
                                </div>
                                <div className="col">
                                    <h6 className='mb-2 text-muted fz-15px'>Charge</h6>
                                    <p className='fz-15px fw-500'>₹{tData?.requestedAmount}</p>
                                </div>
                                <div className="col">
                                    <h6 className='mb-2 text-muted fz-15px'>Email</h6>
                                    <p className='fz-15px fw-500'style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{tData?.teacherEmail}</p>
                                </div>
                                <div className="col my-auto">
                                    <JsonViewer data={tData} />
                                    <button className='border-0 bgLight600 text-prime fz-13px fw-500 py-2  w-100 rounded-3'>
                                        <Link onClick={() => onSeeMessage(tData)} to="/transaction-ticket-messages" className='text-center d-block border-0 bgLight600 text-prime fz-13px fw-500 p-2  w-100 rounded-3 position-relative'>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5451 9.86233C11.0529 10.1905 10.4812 10.3639 9.89173 10.3639C9.30232 10.3639 8.73061 10.1905 8.23842 9.86233L0.131722 4.45769C0.0867458 4.4277 0.0429286 4.39644 0 4.3643V13.2204C0 14.2358 0.823989 15.0416 1.8212 15.0416H17.9622C18.9776 15.0416 19.7834 14.2176 19.7834 13.2204V4.36426C19.7404 4.39648 19.6965 4.42782 19.6514 4.45784L11.5451 9.86233Z" fill="#395061" />
                                                <path d="M0.774724 3.49223L8.88142 8.89691C9.1883 9.10151 9.54 9.20379 9.89169 9.20379C10.2434 9.20379 10.5952 9.10147 10.902 8.89691L19.0087 3.49223C19.4939 3.16901 19.7835 2.62806 19.7835 2.04422C19.7835 1.04032 18.9667 0.223633 17.9629 0.223633H1.82058C0.816725 0.223671 0 1.04036 0 2.04518C0 2.62806 0.289642 3.16901 0.774724 3.49223V3.49223Z" fill="#395061" />
                                            </svg>
                                            {tData?.newMessageCount ?
                                                <div className='ticket-notification-badge-thick'>
                                                    {tData?.newMessageCount}
                                                </div> : ""}
                                            <span className='px-2'>See Messages</span>
                                        </Link>

                                    </button>

                                </div>
                            </div>

                        </div>)))
                    : <h6 className='text-muted text-center'>No data available </h6>
            }
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> tickets per page
                </div>
            </div>
            <JsonViewer data={transactionTicketData} />
        </>
    )
}

export default TransactionTicketList