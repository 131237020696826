import axiosFetch from '../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import { API } from 'constants/apiEndPoints';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PrinterIcon from 'svgIcons/PrinterIcon'
import LearnersTable from './LearnersTable'

function LearnersList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [learners, setLearners] = useState(null);
    useEffect(() => {
        learnersListByCourseCode()
    }, [])
    const learnersListByCourseCode = async () => {
        try {
            const resp = await axiosFetch(API.LEARNERS_LIST_BY_COURSE_CODE + `?courseCode=${searchParams.get("courseCode")} `);
            setLearners(resp?.data)
        } catch (error) {
        }
    }
    return (
        <div className="px-4">
            <JsonViewer data={learners} />
            <div className="bg-white rounded-2 px-4 py-5 mb-4">
                <div className="px-lg-3">
                    <div className="d-flex justify-content-end">

                        <input type={"search"} className="input " placeholder='Search Course Name' />

                        <div className="mx-3">
                            <button className='bgPinkLight rounded py-2 px-4 border-0'>
                                <PrinterIcon width={20} />
                                <span className="mx-2 fz-14px text-prime fw-500">Print</span>
                            </button>

                        </div>

                    </div>
                    {!learners ? <Loader className="text-prime my-3" center={true} /> : learners.length ? <LearnersTable data={learners} /> : <span className='text-center d-block fz-15px text-muted fw-500 mt-5'>No data available</span>}

                </div>
            </div>

        </div>
    )
}

export default LearnersList