import axiosFetch from '../../../../../axios/AxiosInterceptors';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { getLocalStorage } from 'utils/localStorage';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { confirmAlert } from 'utils/confirmAlert';

function FaqRecordedCourse() {
    const clickedCourse = getLocalStorage("clickedCourse");
    const [faqData, setFaqData] = useState(null);
    useEffect(() => {
        fetchOverview();
    }, [])
    const fetchOverview = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_FAQ + `?courseCode=${clickedCourse?.courseCode}`);
            setFaqData(resp?.data)

        } catch (error) {

        }
    }


    const onReject = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to rejected this FAQ?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    courseCode: clickedCourse?.courseCode,
                }
                const resp = await axiosFetch.post(API.REJECT_COURSE_FAQ, jsonData);
                toast.success('FAQ rejected successfully!');


            } catch (error) {

            }

        }
    }

    const onApprove = async () => {
        try {
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
            }
            const resp = await axiosFetch.post(API.APPROVE_COURSE_FAQ, jsonData);
            toast.success('FAQ approved successfully!');

        } catch (error) {

        }

    }
    // const isApproved =false|| faqData?.faqList.some(item => item.faqStatus === "APPROVED");
    // const isRejected = false||faqData?.faqList.some(item => item.faqStatus === "REJECTED");
    const isApproved = false || (faqData?.faqList && faqData.faqList.some(item => item.faqStatus === "APPROVED"));
    const isRejected = false || (faqData?.faqList && faqData.faqList.some(item => item.faqStatus === "REJECTED"));

    return (
        <div className='faq-sec'>
            <JsonViewer data={faqData} />
            {
                !faqData ? "" : faqData?.faqList?.length ?
                    <Stack direction="horizontal" gap={4} className="mb-4">
                        {/* <div 
                            className="textTomato fz-14px fw-500 ms-auto"
                            onClick={onReject}
                            disabled={isRejected}
                            role="button"
                            style={isRejected ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' } : {}}
                        >
                            Reject
                        </div> */}
                        <div 
                        className="textTomato fz-14px fw-500 ms-auto"
                        onClick={onReject}
                        disabled={isRejected || faqData?.faqList.some(item => item.faqStatus === "REJECTED")}
                        role="button"
                        style={
                            (isRejected || faqData?.faqList.some(item => item.faqStatus === "REJECTED"))
                                ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' }
                                : {}
                        }
                    >
                        Reject
                    </div>
                        {/* <div 
                            className="bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1"
                            onClick={!isApproved && onApprove}
                            role="button"
                            disabled={isApproved}
                            style={isApproved ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' } : {}}
                        >
                            Approve
                        </div> */}
                        <div 
                        className="bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1"
                        onClick={!isApproved && onApprove}
                        role="button"
                        disabled={isApproved}
                        style={isApproved ? { backgroundColor: '#ccc', color: '#666', cursor: 'not-allowed' } : {}}
                    >
                        Approve
                    </div>
                    </Stack>
                    : ""
            }
            {
                !faqData ? <Loader className="text-prime my-3" center={true} /> : faqData?.faqList?.length ?
                    <Accordion defaultActiveKey="0" flush>
                        {faqData.faqList.map((item, i) => (
                            <Accordion.Item eventKey={i} key={item._id}>
                                <Accordion.Header>{item?.question}</Accordion.Header>
                                <Accordion.Body>
                                    {item?.answer}
                                </Accordion.Body>
                            </Accordion.Item>

                        ))}

                    </Accordion>
                    : <h6 className='text-muted text-center'>No data available </h6>
            }
        </div>
    )
}

export default FaqRecordedCourse