import axiosFetch from '../../../axios/AxiosInterceptors';
import BootstrapModal from 'components/BootstrapModal';
import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useEffect, useState } from 'react';
import JsonViewer from 'components/JsonViewer';
import { toast } from 'react-hot-toast';
import Paginate from 'components/Paginate';
import { confirmAlertSuccess } from 'utils/confirmAlert';
function CouponRequestList({ onSearch }) {
    const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);
    const [discountList, setDiscountList] = useState(null);
    const [searchData, setSearchData] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const onViewAcc = () => {
        setShowAccountInfoModal(true)
    }

    useEffect(() => {
        getAllDiscountRequest();
    }, [])
    const getAllDiscountRequest = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_DISCOUNT_REQUEST);
            setSearchData(resp.data)
            setDiscountList(resp.data)
        } catch (error) {
        }
    }
    //search effect
    useEffect(() => {
        if (onSearch && searchData) {
            const dataArry = searchData
            const filteredData = dataArry.filter((item) =>
                item.couponName.toLowerCase().includes(onSearch.toLowerCase())
            );

            setDiscountList(filteredData)
        } else {
            setDiscountList(searchData)
        }
    }, [onSearch])
    const onApprove = async (dsData) => {
        const isTrue = await confirmAlertSuccess({ title: "Are you sure you want to approve this coupon?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    discountId: dsData?._id
                }
                const resp = await axiosFetch.post(API.DISCOUNT_APPROVED_BY_ADMIN, jsonData);
                const update = await getAllDiscountRequest()
                toast.success('Coupon approved successfully');
            } catch (error) {
            }
        }
    }
    //after fetch
    useEffect(() => {
        if (discountList) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = discountList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(discountList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [discountList, itemOffset, itemsPerPage])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % discountList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div>
            <div className='py-3'>
                <div className="pt-3 bg-white rounded-3">
                    <div className="px-lg-4">
                        {/* ------- */}
                        <div className="table-responsive">
                            <table className="table table-borderless align-middle">
                                <thead>
                                    <tr>
                                        <th className='text-muted fw-400 fz-15px text-center'>Time Stamp</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>Coupon name</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>Email</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>Discount</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>No. of Coupons</th>
                                        <th className='text-muted   fw-400 fz-15px text-center'> Start date</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>Expiry date</th>
                                        <th className='text-muted fw-400 fz-15px text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!currentItems ? <tr className='text-center'>
                                        <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                                    </tr> : currentItems?.length ?
                                        currentItems.map((dsData, index, array) => (
                                            <tr key={dsData?._id}>
                                                <td className='text-center'>{moment(dsData?.createdTime).format("DD MMM,YYYY")}</td>
                                                <td className='text-center'>{dsData?.couponName}</td>
                                                <td className='text-center'>{dsData?.instructorEmail}</td>
                                                <td className='text-center'>{dsData?.discountPercentage}%</td>
                                                <td className='text-center'>{dsData?.numberOfCoupon}</td>
                                                <td className='text-center'>{moment(dsData?.startDate).format("DD MMM,YYYY")}</td>
                                                <td className='text-center'>{moment(dsData?.expiryDate).format("DD MMM,YYYY")}</td>
                                                <td className='text-center'>
                                                    {dsData?.requestedstatus == "APPROVED" ?
                                                        <span style={{paddingLeft:'10px',paddingRight:'10px'}} className=' d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1' >Approved</span>
                                                        :
                                                        <span role={"button"} className={`${dsData?.requestedstatus == "REQUESTED" ? "REQUESTED" : "REQUESTED"} d-inline-block w-100 fz-14px w-100 fw-500  textGreen bgGreenLight text-center rounded-5 py-1`} onClick={() => onApprove(dsData)}>{dsData?.requestedstatus == "REQUESTED" ? "Requested" : "Requested"}</span>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        : "No data"}
                                </tbody>
                            </table>
                        </div>
                        <JsonViewer data={discountList} />
                        {/* ------- */}
                        <BootstrapModal
                            isShowModal={showAccountInfoModal}
                            setIsModal={setShowAccountInfoModal}
                            title={
                                <h6 className={`fw-600 fz-18px text-start px-5`}>Account info</h6>
                            }
                            body={
                                <div className='px-5 pb-3'>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> coupon request per page
                </div>
            </div>
        </div>
    )
}
export default CouponRequestList