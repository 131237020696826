import React from 'react'
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';
import ArrowLeft from 'svgIcons/ArrowLeft';
import OutlinePointIcon from 'svgIcons/OutlinePointIcon';
function ClickedAssessment() {
    const navigate = useNavigate();
    const back = () => {
        navigate({
            pathname: "/all-courses/in-review/view-approve",
            // search: `?${createSearchParams(params)}`
        })
    }
    return (
        <div className="px-4">
            <Stack direction="horizontal" gap={4}>
                <h6>Foundation Of UIUX Design</h6>
            </Stack>
            <div className="py-3"></div>
            <div className="bg-white rounded-3 py-4 px-5 mb-4">
                <Stack direction="horizontal" gap={4}>
                    <h6 className='text-dark' onClick={back}><ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} /> Assessment 1: Test your knowledge on UX research methods</h6>
                    <div className="bgTomatoLight textTomato ms-auto fz-14px fw-500 px-4 py-2 rounded-1"  >Deactive This Assessments</div>
                </Stack>
                <div className="py-3"></div>
                <h6 className='text-dark fz-14px py-3'>Total Points 10</h6>

                <Stack direction="horizontal" gap={4}>
                    <h6>1. What is the UX Process?</h6>
                    <div className="bg-light fz-12px fw-500 px-3 ms-auto py-1 rounded-1">1 Point</div>
                </Stack>
                <ul className='list-unstyled py-2'>
                    <li className='my-2'><OutlinePointIcon width={13} /> Option 1</li>
                    <li className='my-2'><OutlinePointIcon width={13} /> Option 2</li>
                    <li className='my-2'><OutlinePointIcon width={13} /> Option 3</li>
                    <li className='my-2'><OutlinePointIcon width={13} /> Option 4</li>
                </ul>

            </div>
        </div>
    )
}

export default ClickedAssessment