import React from 'react'

function VideoPlayIcon({ width, height, color, className, playBtnColor }) {
    return (
        <svg width={width} height={height} fill={color} className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
            <path d="M68.5 45.6699C71.8333 47.5944 71.8333 52.4056 68.5 54.3301L44.5 68.1865C41.1667 70.111 37 67.7054 37 63.8564L37 36.1436C37 32.2946 41.1667 29.889 44.5 31.8135L68.5 45.6699Z" fill={playBtnColor} />
        </svg>
    )
}

export default VideoPlayIcon