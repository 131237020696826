import sidebarAndDashboardStyles from '../moduleCss/sidebarAndDashboard.module.css'
import SidebarLinks from './SidebarLinks'

function Sidebar() {
    return (
        <div className={`sidebar ${sidebarAndDashboardStyles.overflowScrollSidebar} px-3 bg-white vh-100 ${sidebarAndDashboardStyles.sideBxSd} ${sidebarAndDashboardStyles.w16}`}>
            <SidebarLinks />

        </div>
    )
}

export default Sidebar