import React from 'react'

function InvoiceIcon() {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.2" width="35" height="35" rx="17.5" fill="#F0E1EB" />
            <g clip-path="url(#clip0_666_4883)">
                <path d="M22.5291 15.6578V11.8729C22.5291 11.7646 22.4791 11.6645 22.4083 11.5853L18.1648 7.12924C18.0856 7.04593 17.973 7 17.8605 7H11.1326C9.89056 7 8.89844 8.01288 8.89844 9.2551V21.9771C8.89844 23.2193 9.89056 24.2156 11.1326 24.2156H16.4433C17.4478 25.8829 19.2736 27 21.3536 27C24.5132 27 27.0935 24.4323 27.0935 21.2685C27.0977 18.5048 25.1135 16.1956 22.5291 15.6578ZM18.2774 8.46317L21.1244 11.4601H19.2778C18.7275 11.4601 18.2774 11.0059 18.2774 10.4557V8.46317ZM11.1326 23.3818C10.3532 23.3818 9.73218 22.7565 9.73218 21.9771V9.2551C9.73218 8.47141 10.3532 7.83374 11.1326 7.83374H17.4437V10.4557C17.4437 11.4685 18.2649 12.2939 19.2778 12.2939H21.6954V15.5493C21.5704 15.5452 21.4703 15.5327 21.362 15.5327C19.9072 15.5327 18.5691 16.0913 17.5604 16.9667H12.2665C12.0372 16.9667 11.8496 17.1543 11.8496 17.3835C11.8496 17.6128 12.0372 17.8003 12.2665 17.8003H16.7851C16.4891 18.2172 16.2431 18.6341 16.0515 19.0926H12.2665C12.0372 19.0926 11.8496 19.2801 11.8496 19.5095C11.8496 19.7386 12.0372 19.9263 12.2665 19.9263H15.7805C15.6762 20.3432 15.6221 20.8058 15.6221 21.2685C15.6221 22.0188 15.7679 22.7608 16.0305 23.3861H11.1326V23.3818ZM21.3578 26.1705C18.6567 26.1705 16.4599 23.9737 16.4599 21.2726C16.4599 18.5715 18.6525 16.3747 21.3578 16.3747C24.0631 16.3747 26.2556 18.5715 26.2556 21.2726C26.2556 23.9737 24.059 26.1705 21.3578 26.1705Z" fill="#395061" />
                <path d="M12.2665 15.712H16.489C16.7184 15.712 16.9059 15.5243 16.9059 15.2951C16.9059 15.0658 16.7184 14.8783 16.489 14.8783H12.2665C12.0371 14.8783 11.8496 15.0658 11.8496 15.2951C11.8496 15.5243 12.0371 15.712 12.2665 15.712Z" fill="#395061" />
                <path d="M23.4913 21.185L21.7782 23.0316V18.4798C21.7782 18.2504 21.5905 18.0629 21.3614 18.0629C21.132 18.0629 20.9445 18.2504 20.9445 18.4798V23.0316L19.2187 21.185C19.0603 21.0184 18.7936 21.0059 18.6268 21.1643C18.4601 21.3226 18.4475 21.5853 18.6061 21.752L21.0446 24.3739C21.1238 24.4573 21.2321 24.5073 21.3488 24.5073C21.4656 24.5073 21.5739 24.4573 21.6531 24.3739L24.0959 21.752C24.2543 21.5853 24.2459 21.3185 24.0791 21.1643C23.9082 21.0059 23.6499 21.0184 23.4913 21.185Z" fill="#395061" />
            </g>
            <defs>
                <clipPath id="clip0_666_4883">
                    <rect width="20" height="20" fill="white" transform="translate(8 7)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default InvoiceIcon