import VideoPlayer from 'components/VideoPlayer';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'svgIcons/ArrowLeft';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';
function ClickedLiveVideo() {

    const navigate = useNavigate();
    const back = () => {
        navigate({
            pathname: "/all-courses/in-review/view-approve",
            // search: `?${createSearchParams(params)}`
        })
    }
    return (
        <div className="px-4">
            <Stack direction="horizontal" gap={4}>
                <h6>Foundation Of UIUX Design</h6>
            </Stack>
            <div className="py-3"></div>
            <div className="bg-white rounded-3 py-4 px-5 mb-4">
                <Stack direction="horizontal" gap={4}>
                    <h6 className='text-dark' onClick={back}><ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} /> Live Class 1 : Topic Name</h6>
                    <div className="textTomato fz-14px fw-500 ms-auto">Delete This Video</div>
                </Stack>
                <div className="py-3"></div>
                <VideoPlayer url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"} />
            </div>
        </div>

    )
}

export default ClickedLiveVideo
