import React from 'react'

function CreateCategoryIcon({ width, height, color, className }) {
    return (
        <svg className={className} width={width} height={height} fill={color} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_682_6446)">
                <path d="M14.5833 0H8.33333C6.1232 0 4.00358 0.877974 2.44078 2.44078C0.877974 4.00358 0 6.1232 0 8.33333L0 14.5833C0 16.7935 0.877974 18.9131 2.44078 20.4759C4.00358 22.0387 6.1232 22.9167 8.33333 22.9167H14.5833C16.7935 22.9167 18.9131 22.0387 20.4759 20.4759C22.0387 18.9131 22.9167 16.7935 22.9167 14.5833V8.33333C22.9167 6.1232 22.0387 4.00358 20.4759 2.44078C18.9131 0.877974 16.7935 0 14.5833 0V0ZM18.75 14.5833C18.75 15.6884 18.311 16.7482 17.5296 17.5296C16.7482 18.311 15.6884 18.75 14.5833 18.75H8.33333C7.22827 18.75 6.16846 18.311 5.38706 17.5296C4.60565 16.7482 4.16667 15.6884 4.16667 14.5833V8.33333C4.16667 7.22827 4.60565 6.16846 5.38706 5.38706C6.16846 4.60565 7.22827 4.16667 8.33333 4.16667H14.5833C15.6884 4.16667 16.7482 4.60565 17.5296 5.38706C18.311 6.16846 18.75 7.22827 18.75 8.33333V14.5833Z" fill="#8C8C8C" />
                <path d="M14.5833 27.083H8.33334C6.1232 27.083 4.00358 27.961 2.44078 29.5238C0.877974 31.0866 0 33.2062 0 35.4163L0 41.6664C0 43.8765 0.877974 45.9961 2.44078 47.5589C4.00358 49.1217 6.1232 49.9997 8.33334 49.9997H14.5833C16.7935 49.9997 18.9131 49.1217 20.4759 47.5589C22.0387 45.9961 22.9167 43.8765 22.9167 41.6664V35.4163C22.9167 33.2062 22.0387 31.0866 20.4759 29.5238C18.9131 27.961 16.7935 27.083 14.5833 27.083ZM18.75 41.6664C18.75 42.7714 18.311 43.8312 17.5296 44.6126C16.7482 45.394 15.6884 45.833 14.5833 45.833H8.33334C7.22827 45.833 6.16846 45.394 5.38706 44.6126C4.60566 43.8312 4.16667 42.7714 4.16667 41.6664V35.4163C4.16667 34.3113 4.60566 33.2515 5.38706 32.4701C6.16846 31.6887 7.22827 31.2497 8.33334 31.2497H14.5833C15.6884 31.2497 16.7482 31.6887 17.5296 32.4701C18.311 33.2515 18.75 34.3113 18.75 35.4163V41.6664Z" fill="#8C8C8C" />
                <path d="M41.6667 27.083H35.4167C33.2065 27.083 31.0869 27.961 29.5241 29.5238C27.9613 31.0866 27.0833 33.2062 27.0833 35.4163V41.6664C27.0833 43.8765 27.9613 45.9961 29.5241 47.5589C31.0869 49.1217 33.2065 49.9997 35.4167 49.9997H41.6667C43.8768 49.9997 45.9964 49.1217 47.5593 47.5589C49.1221 45.9961 50 43.8765 50 41.6664V35.4163C50 33.2062 49.1221 31.0866 47.5593 29.5238C45.9964 27.961 43.8768 27.083 41.6667 27.083ZM45.8334 41.6664C45.8334 42.7714 45.3944 43.8312 44.613 44.6126C43.8316 45.394 42.7718 45.833 41.6667 45.833H35.4167C34.3116 45.833 33.2518 45.394 32.4704 44.6126C31.689 43.8312 31.25 42.7714 31.25 41.6664V35.4163C31.25 34.3113 31.689 33.2515 32.4704 32.4701C33.2518 31.6887 34.3116 31.2497 35.4167 31.2497H41.6667C42.7718 31.2497 43.8316 31.6887 44.613 32.4701C45.3944 33.2515 45.8334 34.3113 45.8334 35.4163V41.6664Z" fill="#8C8C8C" />
                <path d="M29.1667 14.583H35.4167V20.833C35.4167 21.3855 35.6362 21.9154 36.0269 22.3061C36.4176 22.6968 36.9475 22.9163 37.5 22.9163C38.0525 22.9163 38.5825 22.6968 38.9732 22.3061C39.3639 21.9154 39.5833 21.3855 39.5833 20.833V14.583H45.8333C46.3859 14.583 46.9158 14.3635 47.3065 13.9728C47.6972 13.5821 47.9167 13.0522 47.9167 12.4997C47.9167 11.9471 47.6972 11.4172 47.3065 11.0265C46.9158 10.6358 46.3859 10.4163 45.8333 10.4163H39.5833V4.16634C39.5833 3.61381 39.3639 3.0839 38.9732 2.6932C38.5825 2.3025 38.0525 2.08301 37.5 2.08301C36.9475 2.08301 36.4176 2.3025 36.0269 2.6932C35.6362 3.0839 35.4167 3.61381 35.4167 4.16634V10.4163H29.1667C28.6141 10.4163 28.0842 10.6358 27.6935 11.0265C27.3028 11.4172 27.0833 11.9471 27.0833 12.4997C27.0833 13.0522 27.3028 13.5821 27.6935 13.9728C28.0842 14.3635 28.6141 14.583 29.1667 14.583Z" fill="#8C8C8C" />
            </g>
            <defs>
                <clipPath id="clip0_682_6446">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CreateCategoryIcon