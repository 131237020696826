import Swal from "sweetalert2"

export const errorAlert = (prop) => {
    return Swal.fire({
        title: prop.title,
        text: prop?.description || "",
        imageUrl: "https://cdn-icons-png.flaticon.com/512/5219/5219070.png",
        showCloseButton: false,
        confirmButtonText: prop?.confirmText || "OK",
        allowOutsideClick: false,
        customClass: {
            popup: "rounded-4",
            closeButton: 'close-button-class',
            title: "errorTitle",
            image: "errorImg",
            confirmButton: 'swal-cancel-btn',
        },
    }).then((result) => {
        return result

    })
}

// function zSweetAlertImportant() {
//     swal.fire({
//       title: 'Agreement Confirmation',
//       html: 'Knowledge of Operation<br />This operation "can not be reverted"<br />Do you Confirm?',
//       showCancelButton: true,
//       cancelButtonText: 'Cancel',
//       confirmButtonText: 'Confirm',
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       backdrop: true,
//       allowOutsideClick: false,
//       allowEscapeKey: false,
//       allowEnterKey: false,
//       reverseButtons: false,
//       customClass: {
//         container: 'sweet_containerImportant',
//         title: 'sweet_titleImportant',
//         actions: 'sweet_actionsImportant',
//         confirmButton: 'sweet_confirmbuttonImportant',
//         cancelButton: 'sweet_cancelbuttonImportant',
//       }
//     })
//   }