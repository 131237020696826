import React from 'react'

function RightArrowIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 16 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.314644 28.2328L12.6634 15.8841C12.8915 15.6464 13.0189 15.3297 13.0189 15.0003C13.0189 14.6709 12.8915 14.3542 12.6634 14.1166L0.322145 1.77531L2.08965 0.00781191L14.4309 12.3491C15.1127 13.0636 15.4932 14.0133 15.4932 15.0009C15.4932 15.9886 15.1127 16.9383 14.4309 17.6528L2.08215 30.0003L0.314644 28.2328Z" fill="black" />
        </svg>

    )
}

export default RightArrowIcon