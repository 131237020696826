import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { setLocalStorage } from 'utils/localStorage';
import cardStyle from "../../../../moduleCss/cards.module.css";
import MyS3Component from 'components/S3';



function RecordedCourseInreviewLayout({ courseData }) {
    const [modalShow, setModalShow] = React.useState(false);
    const onViewApprove = () => {
        setLocalStorage({ key: "clickedCourse", value: courseData })
    }
    return (
        <>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                dialogClassName={`mW36`}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <h6 className='fw-600 fz-18px text-center'>Are you sure you want to Deactivate this Course?</h6>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body bsPrefix="pb-4 pt-3">

                    <div className="d-flex justify-content-center mt-auto">
                        <div className="bg-light text-prime px-5 py-2 mx-3 rounded fz-14px fw-500" role="button">
                            Cancel
                        </div>
                        <div className="bgTomato text-white px-5 py-2 rounded fz-14px fw-500" role="button">
                            Deactivate
                        </div>

                    </div>
                </Modal.Body>

            </Modal>
            {/* ---------- */}
            <div className={`${cardStyle.crdHover} bg-white box-sd p-4 rounded-3 mb-4`}>
                <div className="row">
                    <div className="col-lg-3 m-auto">
                        <MyS3Component src={courseData?.thumbNailImagePath} className={`${cardStyle.crdImg} rounded-3`} />
                    </div>
                    <div className="col-lg-7 m-auto">
                        <h6 className='mb-3'>{courseData?.courseName}</h6>
                        <div className="d-flex mb-3">
                            <p><span className='fz-14px fw-500 text-muted'>By  </span> <span className='fz-14px fw-600 text-prime'>{courseData?.instructorName}</span></p>
                        </div>
                        <div className="d-flex">
                            {courseData?.inReviewAssessmentCount ? <p className='fz-13px  mb-2 fw-500 text-warning'>  {courseData?.inReviewAssessmentCount}{" Assessment "}  </p> : ""}
                            {courseData?.inReviewLessonCount ? <p className='fz-13px  mb-2 fw-500 text-warning'><span className='mx-1'>&</span>{courseData?.inReviewLessonCount}{" Lesson "} </p> : ""}
                            {courseData?.inReviewAssessmentCount || courseData?.inReviewLessonCount ? <p className='fz-13px  mb-2 fw-500 text-warning mx-1'> Pending In Review</p> : ""}
                        </div>

                        <div className="d-flex mb-3">
                            <p className='me-3'><span className='fz-14px fw-500 text-muted'>Category</span> <span className='fz-14px fw-600 text-prime'>{courseData?.category}</span></p>
                            <p><span className='fz-14px fw-500 text-muted'>Sub - Category</span> <span className='fz-14px fw-600 text-prime'>{courseData?.subCategory}</span></p>
                        </div>
                        <h6 className='fw-600 text-prime'>₹{courseData?.cost}</h6>


                    </div>
                    <div className="col-lg-2 m-auto"> <Link to={`/all-courses/in-review/view-approve`} onClick={onViewApprove}><button className={`textGreen bgGreenLight border-0 w-100 py-2 rounded fz-14px fw-500`}>View & Approve</button></Link>   </div>


                </div>
            </div >
        </>
    )
}

export default RecordedCourseInreviewLayout