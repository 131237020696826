import Login from 'pages/login/Login';
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute(props) {
    let isLoggedIn = localStorage.getItem("userData");
    if (props.isloginUrl) {
        return (
            <>
                {
                    isLoggedIn ? <Navigate to="/" /> : <Login />
                }
            </>
        )
    } else {
        return (
            <>
                {
                    isLoggedIn ? <Outlet /> : <Navigate to="/login" />
                }
            </>

        )
    }
}

export default PrivateRoute