import Loader from 'components/Loader'
import React from 'react'

function OverViewAssessment({ data }) {
    return (
        <div>
            {data ?
                <>
                    <div className="d-flex py-1">
                        <h6 className='mb-2 text-muted'>Assessment Title: </h6>
                        <h6 className='fw-400 mx-1'> {data?.assessmentTitle}</h6>
                    </div>

                    <div className="d-flex py-1">
                        <h6 className='mb-2 text-muted'>Category: </h6>
                        <h6 className='fw-400 mx-1'>{data?.categoryName}</h6>
                    </div>
                    <div className="d-flex py-1">
                        <h6 className='mb-2 text-muted'>subCategory: </h6>
                        <h6 className='fw-400 mx-1'>{data?.subCategoryName}</h6>
                    </div>
                    <div className="d-flex py-1">
                        <h6 className='mb-2 text-muted'>Fee: </h6>
                        <h6 className='fw-400 mx-1'>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: data?.currency, minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(data?.fee)} </h6>
                    </div>
                </>
                : <Loader className={"text-prime"} />
            }
        </div>
    )
}

export default OverViewAssessment