import React from 'react'

function OnlineEducationIcon({ width, height, color }) {
  return (
    <svg width={width} height={height} fill={color} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.4596 3.71851H1.54043C0.691016 3.71851 0 4.40957 0 5.25898V19.7413C0 20.5907 0.691016 21.2817 1.54043 21.2817H16.0227C16.225 21.2817 16.3889 21.1177 16.3889 20.9155C16.3889 20.7132 16.225 20.5493 16.0227 20.5493H1.54043C1.09487 20.5493 0.732422 20.1868 0.732422 19.7413V5.25898C0.732422 4.81343 1.09487 4.45098 1.54043 4.45098H23.4595C23.9051 4.45098 24.2675 4.81343 24.2675 5.25898V19.7413C24.2675 20.1868 23.9051 20.5493 23.4595 20.5493H20.7196C20.5174 20.5493 20.3534 20.7132 20.3534 20.9155C20.3534 21.1177 20.5174 21.2817 20.7196 21.2817H23.4595C24.3089 21.2817 25 20.5907 25 19.7413V5.25898C25 4.40957 24.309 3.71851 23.4596 3.71851Z" />
      <path d="M18.7374 18.6523V14.2973L20.8229 13.3905C21.1039 13.2684 21.2785 13.0022 21.2785 12.6958C21.2785 12.3894 21.1039 12.1232 20.8229 12.001L13.2703 8.71725C12.7808 8.50441 12.2193 8.50441 11.7297 8.71725L4.17709 12.0009C3.89609 12.1231 3.72153 12.3893 3.72153 12.6957C3.72153 13.0021 3.89609 13.2684 4.17709 13.3905L6.48281 14.393C6.66816 14.4736 6.88398 14.3886 6.96464 14.2031C7.04531 14.0176 6.9603 13.8019 6.7748 13.7213L4.46909 12.7188C4.46166 12.7156 4.45395 12.7122 4.45395 12.6957C4.45395 12.6792 4.46166 12.6758 4.46909 12.6726L12.0217 9.38883C12.3257 9.2567 12.6743 9.25665 12.9783 9.38883L20.5309 12.6726C20.5383 12.6758 20.546 12.6792 20.546 12.6957C20.546 12.7122 20.5383 12.7155 20.5309 12.7188L17.1718 14.1793V13.0871C17.1718 12.6456 16.7047 12.3723 15.61 12.1733C14.7759 12.0216 13.6714 11.938 12.5 11.938C11.3287 11.938 10.2242 12.0215 9.39008 12.1733C8.2954 12.3723 7.82831 12.6456 7.82831 13.0871V16.2184C7.82831 16.6362 8.16816 16.976 8.58593 16.976H16.4142C16.8319 16.976 17.1718 16.6362 17.1718 16.2184V14.9779L18.005 14.6157V18.6523C17.5505 18.8055 17.2222 19.2356 17.2222 19.7411V20.9153C17.2222 21.1176 17.3861 21.2816 17.5884 21.2816H19.154C19.3563 21.2816 19.5203 21.1176 19.5203 20.9153V19.7411C19.5202 19.2356 19.1919 18.8056 18.7374 18.6523ZM12.5 12.6705C14.8167 12.6705 16.1525 12.9835 16.4393 13.1749V14.678H8.56069V13.1749C8.8475 12.9835 10.1832 12.6705 12.5 12.6705ZM16.4141 16.2436H8.58588C8.57197 16.2436 8.56069 16.2323 8.56069 16.2184V15.4104H16.4393V16.2184C16.4393 16.2323 16.428 16.2436 16.4141 16.2436ZM18.7878 20.5492H17.9545V19.7412C17.9545 19.5114 18.1414 19.3246 18.3712 19.3246C18.6009 19.3246 18.7878 19.5115 18.7878 19.7412V20.5492Z" />
      <path d="M5.03795 7.21597C5.03795 6.36655 4.34694 5.67554 3.49752 5.67554C2.64811 5.67554 1.95709 6.36655 1.95709 7.21597C1.95709 8.06538 2.64811 8.7564 3.49752 8.7564C4.34694 8.7564 5.03795 8.06538 5.03795 7.21597ZM3.49752 8.02402C3.05197 8.02402 2.68951 7.66157 2.68951 7.21602C2.68951 6.77046 3.05197 6.40801 3.49752 6.40801C3.94308 6.40801 4.30553 6.77046 4.30553 7.21602C4.30553 7.66157 3.94308 8.02402 3.49752 8.02402Z" />
      <path d="M6.2374 6.79932H10.9344C11.1366 6.79932 11.3006 6.63535 11.3006 6.43311C11.3006 6.23086 11.1366 6.06689 10.9344 6.06689H6.2374C6.03515 6.06689 5.87119 6.23086 5.87119 6.43311C5.87119 6.63535 6.03515 6.79932 6.2374 6.79932Z" />
      <path d="M6.2374 8.36499H8.58588C8.78813 8.36499 8.95209 8.20103 8.95209 7.99878C8.95209 7.79653 8.78813 7.63257 8.58588 7.63257H6.2374C6.03515 7.63257 5.87119 7.79653 5.87119 7.99878C5.87119 8.20103 6.03515 8.36499 6.2374 8.36499Z" />
    </svg>

  )
}

export default OnlineEducationIcon