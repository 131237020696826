import React from 'react'

function StarIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3161 29.2333L15 24.0293L6.68384 29.2332C6.38173 29.4247 5.99202 29.4101 5.7011 29.1989C5.41101 28.9886 5.27794 28.623 5.36548 28.2753L7.74468 18.7575L0.314286 12.4577C0.039598 12.2276 -0.0676872 11.8534 0.0430551 11.5127C0.153797 11.1711 0.461063 10.9316 0.818133 10.9067L10.5162 10.2295L14.1846 1.13056C14.4541 0.466225 15.5459 0.466225 15.8154 1.13056L19.4838 10.2295L29.1819 10.9067C29.5389 10.9316 29.8462 11.1711 29.9569 11.5127C30.0677 11.8534 29.9604 12.2276 29.6857 12.4577L22.2553 18.7577L24.6345 28.2755C24.722 28.6231 24.589 28.9887 24.2988 29.199C24.0019 29.4146 23.6133 29.4195 23.3161 29.2333Z" />
        </svg>
    )
}

export default StarIcon