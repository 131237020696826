import { API } from 'constants/apiEndPoints';
import moment from 'moment';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { confirmAlert, confirmAlertSuccess } from 'utils/confirmAlert';
import { cLog } from 'utils/logger';
import axiosFetch from '../../../../axios/AxiosInterceptors';
import cardStyle from "../../../../moduleCss/cards.module.css";
import MyS3Component from 'components/S3';
function LiveCourseActiveLayout({ data, activeTab = "", fetchCourse }) {
    const [reason, setReason] = useState("");

    const onRejectLiveCourse = async (data) => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to deactivate this course?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    courseCode: data?.courseCode,
                    commonRejectionComments: reason
                }
                const resp = await axiosFetch.post(API.DEACTIVATE_LIVE_COURSE, jsonData);
                const trigFetch = await fetchCourse();

                toast.success('Course deactivated successfully');

            } catch (error) {
            }
        }
    }
    const onActivateLiveCourse = async (data) => {
        const isTrue = await confirmAlertSuccess({ title: "Are you sure you want to activate this course?", cancelText: "Cancel", confirmText: "Activate" });
        if (isTrue.isConfirmed) {
            try {
                const jsonData = {
                    courseCode: data?.courseCode,

                }
                const resp = await axiosFetch.post(API.ACTIVATE_LIVE_COURSE, jsonData);
                const trigFetch = await fetchCourse();

                toast.success('Course activated successfully');

            } catch (error) {
            }
        }
    }

    return (
        <>

            <div className={`${cardStyle.crdHover} bg-white box-sd p-4 rounded-3 mb-4`}>
                <div className="row">
                    <div className="col-lg-3 m-auto">
                        <MyS3Component src={data?.thumbNailImagePath} className={`${cardStyle.crdImg} rounded-3`} />
                        
                    </div>
                    <div className="col-lg-7 m-auto">
                        <h6 className='mb-3'>{data?.courseName}</h6>
                        <div className="d-flex mb-3">
                            <p><span className='fz-14px fw-500 text-muted'>By</span> <span className='fz-14px fw-600 text-prime'>{data?.instructorName}</span></p>
                            <p className='mx-4'><span className='fz-14px fw-500 px-3 py-1 rounded-5 fz-13px bgTomato text-white'>Live Courses</span></p>
                        </div>

                        <p className='fz-13px  mb-2 fw-500 text-prime'>{moment(data?.fromDate).format("DD-MM-YYYY")} to {moment(data?.toDate).format("DD-MM-YYYY")} ({data?.courseDuration}) </p>
                        <div className="d-flex mb-3">
                            <p className='me-3'><span className='fz-14px fw-500 text-muted'>Category</span> <span className='fz-14px fw-600 text-prime'>{data?.category}</span></p>
                            <p><span className='fz-14px fw-500 text-muted'>Sub - Category</span> <span className='fz-14px fw-600 text-prime'>{data?.subCategory}</span></p>
                        </div>
                        <h6 className='fw-600 text-prime'>₹{data?.cost}</h6>
                    </div>
                    <div className={`col-lg-2 ${activeTab == "BANNED" ? "mt-auto" : ""}`}>


                        {activeTab == "BANNED" ?
                            <button className={`textGreen bgGreenLight  border-0 w-100 py-2 rounded-1 fz-14px fw-500`} onClick={() => onActivateLiveCourse(data)}>Activate This Course</button> :
                            <>
                                <textarea className='input mb-5 p-2 fz-13px w-100' placeholder='Deactivate reason' onChange={(e) => setReason(e.target.value)} />
                                <button className={`textTomato bgTomatoLight border-0 w-100 py-2 rounded fz-14px fw-500`} onClick={() => onRejectLiveCourse(data)}>Deactive Course</button>
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
export default LiveCourseActiveLayout