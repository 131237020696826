import axiosFetch from '../../../axios/AxiosInterceptors';
import Sidebar from 'components/Sidebar'
import TransactionTable from 'pages/adminDashboard/transactionTable/TransactionTable'
import { useEffect } from 'react';
import sidebarAndDashboardStyles from '../../../moduleCss/sidebarAndDashboard.module.css'
import { API } from 'constants/apiEndPoints';
import { useState } from 'react';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
function Dashboard() {
    const [counterData, setCounterData] = useState(null);
    useEffect(() => {
        fetchCounter();
    }, [])
    const fetchCounter = async () => {
        try {
            const resp = await axiosFetch(API.GET_DASHBOARD_COUNTERS);
            setCounterData(resp.data)

        } catch (error) {

        }
    }
    return (
        <div>

            <div className="py-3"></div>
            <div className="px-4">
                {!counterData ? <Loader className="text-prime my-3" center={true} /> : counterData.length ? <>
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`${sidebarAndDashboardStyles.bgOcean} ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Total Revenue</h6>
                                        <h6>{counterData[3].totalRevenue}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`${sidebarAndDashboardStyles.bgPink} ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Total Paid</h6>
                                        <h6>{counterData[3].totalFees}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`${sidebarAndDashboardStyles.bgTomato} ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Payment In Progress</h6>
                                        <h6>{counterData[3].paymentInProgress}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-3"></div>
                    {/* --------- */}
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`${sidebarAndDashboardStyles.bgViolet} ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Total Instructors</h6>
                                        <h6>{counterData[1].totalNoOfInstructors}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`${sidebarAndDashboardStyles.bgLightInfo} ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Total Courses</h6>
                                        <h6>{counterData[0].totalNoOfCourses}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={`bg-white ${sidebarAndDashboardStyles.dashboardCrd} py-4`}>
                                <div className="row g-0">
                                    <div className="col-lg-3">
                                        <div className={`bg-prime ${sidebarAndDashboardStyles.line}`}></div>
                                    </div>
                                    <div className="col">
                                        <h6 className='fz-15px mb-3'>Total Students</h6>
                                        <h6>{counterData[2].totalNoOfStudents}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : ""
                }
                <TransactionTable />
                <JsonViewer data={counterData} />

            </div>

        </div>

    )
}
export default Dashboard