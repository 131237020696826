import React from 'react'

function InstaIcon() {
    return (
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9987 9.22135C11.8062 9.22135 9.21994 11.8088 9.21994 15.0001C9.21994 18.1926 11.8062 20.7801 14.9987 20.7801C18.1887 20.7801 20.7774 18.1926 20.7774 15.0001C20.7774 11.8088 18.1887 9.22135 14.9987 9.22135ZM14.9987 18.7551C12.9249 18.7551 11.2437 17.0738 11.2437 15.0013C11.2437 12.9276 12.9249 11.2476 14.9987 11.2476C17.0724 11.2476 18.7512 12.9276 18.7512 15.0013C18.7512 17.0738 17.0724 18.7551 14.9987 18.7551Z" fill="#395061" />
            <path d="M21.0074 10.3563C21.7516 10.3563 22.3549 9.75305 22.3549 9.00885C22.3549 8.26464 21.7516 7.66135 21.0074 7.66135C20.2632 7.66135 19.6599 8.26464 19.6599 9.00885C19.6599 9.75305 20.2632 10.3563 21.0074 10.3563Z" fill="#395061" />
            <path d="M25.6662 7.63885C25.0799 6.1276 23.8862 4.9326 22.3749 4.34885C21.5012 4.0201 20.5774 3.84385 19.6424 3.82385C18.4387 3.77135 18.0574 3.75635 15.0049 3.75635C11.9524 3.75635 11.5612 3.75635 10.3674 3.82385C9.43494 3.8426 8.51119 4.01885 7.63744 4.34885C6.12494 4.9326 4.93119 6.1276 4.34619 7.63885C4.01744 8.51385 3.84119 9.43635 3.82244 10.3713C3.76869 11.5738 3.75244 11.9551 3.75244 15.0088C3.75244 18.0613 3.75244 18.4501 3.82244 19.6463C3.84119 20.5813 4.01744 21.5038 4.34619 22.3801C4.93244 23.8901 6.12619 25.0851 7.63869 25.6701C8.50869 26.0101 9.43244 26.2026 10.3699 26.2326C11.5737 26.2851 11.9549 26.3013 15.0074 26.3013C18.0599 26.3013 18.4512 26.3013 19.6449 26.2326C20.5787 26.2138 21.5024 26.0363 22.3774 25.7088C23.8887 25.1226 25.0824 23.9288 25.6687 22.4176C25.9974 21.5426 26.1737 20.6201 26.1924 19.6851C26.2462 18.4826 26.2624 18.1013 26.2624 15.0476C26.2624 11.9938 26.2624 11.6063 26.1924 10.4101C26.1762 9.4626 26.0012 8.52385 25.6662 7.63885ZM24.1437 19.5538C24.1349 20.2738 24.0049 20.9876 23.7549 21.6638C23.3737 22.6476 22.5974 23.4251 21.6149 23.8026C20.9462 24.0513 20.2412 24.1813 19.5274 24.1913C18.3399 24.2463 18.0049 24.2601 14.9599 24.2601C11.9124 24.2601 11.6012 24.2601 10.3912 24.1913C9.67994 24.1826 8.97244 24.0513 8.30494 23.8026C7.31869 23.4263 6.53744 22.6488 6.15619 21.6638C5.91119 20.9963 5.77869 20.2901 5.76744 19.5776C5.71369 18.3901 5.70119 18.0551 5.70119 15.0101C5.70119 11.9638 5.70119 11.6526 5.76744 10.4413C5.77619 9.72135 5.90619 9.00885 6.15619 8.3326C6.53744 7.34635 7.31869 6.5701 8.30494 6.1926C8.97244 5.9451 9.67994 5.81385 10.3912 5.80385C11.5799 5.7501 11.9137 5.7351 14.9599 5.7351C18.0062 5.7351 18.3187 5.7351 19.5274 5.80385C20.2412 5.8126 20.9462 5.94385 21.6149 6.1926C22.5974 6.57135 23.3737 7.34885 23.7549 8.3326C23.9999 9.0001 24.1324 9.70635 24.1437 10.4188C24.1974 11.6076 24.2112 11.9413 24.2112 14.9876C24.2112 18.0326 24.2112 18.3601 24.1574 19.5551H24.1437V19.5538Z" fill="#395061" />
        </svg>
    )
}

export default InstaIcon