import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import React, { useEffect, useState } from 'react'
import Loader from 'components/Loader';
import LiveCourseLayout from './courseLayouts/LiveCourseLayout';
import RecordedCourseLayout from './courseLayouts/RecordedCourseLayout';
import Paginate from 'components/Paginate';

function DeactivatedInstructorCourseList({ instructorId, InstructorName }) {
    const [deActiveCourseList, setDeActiveCourseList] = useState();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    useEffect(() => {
        getDeActiveCourses();
    }, [])
    const getDeActiveCourses = async () => {
        try {
            const jsonData = { "userType": "ADMIN" }
            const resp = await axiosFetch.post(API.GET_TEACHER_DEACTIVE_COURSES + `?instructorId=${instructorId}`, jsonData);
            setDeActiveCourseList(resp.data)


        } catch (error) {

        }

    }
    //Pagination effect
    useEffect(() => {
        if (deActiveCourseList) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = deActiveCourseList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(deActiveCourseList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [deActiveCourseList, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % deActiveCourseList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div>
            {
                !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                    <>
                        {
                            currentItems.map((cData, i) => {
                                return cData?.isLive
                                    ? <LiveCourseLayout instructorId={instructorId} activeTab="DEACTIVE" fetchCourse={getDeActiveCourses} data={cData} key={cData?.courseCode + i} />
                                    : <RecordedCourseLayout instructorId={instructorId} activeTab="DEACTIVE" fetchCourse={getDeActiveCourses} courseData={cData} key={cData?.courseCode + i} />


                            })
                        }
                    </>
                    : <h6 className='text-muted text-center'>No course available </h6>
            }
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> courses per page
                </div>
            </div>
            <JsonViewer data={deActiveCourseList} />
        </div>
    )
}

export default DeactivatedInstructorCourseList