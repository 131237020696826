import JsonViewer from 'components/JsonViewer'
import { Col, Nav, Row, Stack, Tab } from 'react-bootstrap'
import { getLocalStorage } from 'utils/localStorage'
import Assessments from './independentAssessments/Assessments'
import LiveCourseComment from './liveCourseComment/LiveCourseComment'
import LiveCourseAssessment from './liveCoursePages/liveCourseAssessment/LiveCourseAssessment'
import LiveCourseDuration from './liveCoursePages/liveCourseDuration/LiveCourseDuration'
import LiveCourseFaq from './liveCoursePages/liveCourseFaq/LiveCourseFaq'
import LiveCourseGoToLive from './liveCoursePages/liveCourseGoToLive/LiveCourseGoToLive'
import LiveCourseOverview from './liveCoursePages/liveCourseOverview/LiveCourseOverview'
import LiveCourseRejAppHeader from './liveCoursePages/rejApproveLiveCourseHeader/LiveCourseRejAppHeader'
import LiveVideos from './liveVideos/LiveVideos'
import NewVideos from './newVideos/NewVideos'
import RecordedCourseComment from './recordedCourseComment/RecordedCourseComment'
import FaqRecordedCourse from './uploadedVideos/FaqRecordedCourse'
import OverviewRecordedCourse from './uploadedVideos/OverviewRecordedCourse'
import RejectApproveHeader from './uploadedVideos/RejectApproveHeader'
import UploadedVideos from './uploadedVideos/UploadedVideos'

function ViewApprove() {
    const clickedCourse = getLocalStorage("clickedCourse")
    
    return (
        <div className="px-4">
            {clickedCourse.isLive ? <LiveCourseRejAppHeader /> : <RejectApproveHeader />}
            <div className="py-3"></div>
            <div className="bg-white rounded-2 py-4 mb-4">

                <div className="px-lg-4 theme-tab">
                    <Tab.Container defaultActiveKey={clickedCourse.isLive ? "liveCourse_overview_tab" : "overview_tab"} unmountOnExit>
                        <Row>
                            <Col sm={12} >
                                <JsonViewer data={clickedCourse} />
                                <div className="d-flex justify-content-between">


                                    <div className={`d-inline-block bg-light rounded-1`}>
                                        <Nav variant="pills">
                                            {clickedCourse.isLive ?
                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="liveCourse_overview_tab" className='rounded-1 fz-14px px-5 py-2'>Overview</Nav.Link>
                                                    </Nav.Item>
                                                    {/* <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_intro_tab" className='rounded-1 fz-14px px-5 py-2'>Pricing & Intro</Nav.Link>
                                                    </Nav.Item> */}
                                                    <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_duration_tab" className='rounded-1 fz-14px px-5 py-2'>Course Duration</Nav.Link>
                                                    </Nav.Item>
                                                    {/* <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_live_tab" className='rounded-1 fz-14px px-5 py-2'>Live</Nav.Link>
                                                    </Nav.Item> */}
                                                    <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_assessment_tab" className='rounded-1 fz-14px px-5 py-2'>Assessment</Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_faq_tab" className='rounded-1 fz-14px px-5 py-2'>FAQ</Nav.Link>
                                                    </Nav.Item>
                                                    {/* <Nav.Item >
                                                        <Nav.Link eventKey="liveCourse_comment_tab" className='rounded-1 fz-14px px-5 py-2'>Comment</Nav.Link>
                                                    </Nav.Item> */}
                                                </>
                                                :
                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="overview_tab" className='rounded-1 fz-14px px-5 py-2'>Overview</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="new_video_tab" className='rounded-1 fz-14px px-5 py-2'>New Videos</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="uploaded_video_tab" className='rounded-1 fz-14px px-5 py-2'>Uploaded Videos</Nav.Link>
                                                    </Nav.Item>
                                                    {/* <Nav.Item>
                                                        <Nav.Link eventKey="live_video_tab" className='rounded-1 fz-14px px-5 py-2'>Live Videos</Nav.Link>
                                                    </Nav.Item> */}
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="faq_tab" className='rounded-1 fz-14px px-5 py-2'>FAQ</Nav.Link>
                                                    </Nav.Item>
                                                    {/* <Nav.Item>
                                                        <Nav.Link eventKey="recCourse_comment_tab" className='rounded-1 fz-14px px-5 py-2'>Comment</Nav.Link>
                                                    </Nav.Item> */}

                                                </>
                                            }
                                        </Nav>

                                    </div>

                                </div>

                            </Col>
                            <div className="py-3"></div>
                            <Col sm={12}>
                                <Tab.Content>
                                    {/* -------Recorded Course---------- */}
                                    <Tab.Pane eventKey="overview_tab">
                                        <OverviewRecordedCourse />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="new_video_tab">
                                        <NewVideos />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="uploaded_video_tab">
                                        <UploadedVideos />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="live_video_tab">
                                        <LiveVideos />
                                    </Tab.Pane> */}
                                    <Tab.Pane eventKey="faq_tab">
                                        <FaqRecordedCourse />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="recCourse_comment_tab">
                                        <RecordedCourseComment />
                                    </Tab.Pane>
                                    {/* ---------Live Course tabs------ */}
                                    <Tab.Pane eventKey="liveCourse_overview_tab">
                                        <LiveCourseOverview />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="liveCourse_duration_tab">
                                        <LiveCourseDuration />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="liveCourse_live_tab">
                                        <LiveCourseGoToLive />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="liveCourse_assessment_tab">
                                        <LiveCourseAssessment />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="liveCourse_faq_tab">
                                        <LiveCourseFaq />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="liveCourse_comment_tab">
                                        <LiveCourseComment />
                                    </Tab.Pane>


                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default ViewApprove