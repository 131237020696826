import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

function Loader({ className, center }) {
    const [isTimeOut, setIsTimeOut] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsTimeOut(true)
        }, 16000);
    }, [])
    return (
        <div className={center ? "text-center d-block" : ""}>
            {isTimeOut ? <h6 className='text-muted'>Failed to Load!</h6> :
                <>
                    <span className={`spinner-border spinner-border-sm ${className}`} role="status" aria-hidden="true"></span>
                    <span className="visually-hidden">Loading...</span>
                </>
            }
        </div>
    )
}

export default Loader