import Loader from 'components/Loader'
import React from 'react'
import { Stack } from 'react-bootstrap'
import OutlinePointIcon from 'svgIcons/OutlinePointIcon'

function AssessmentQA({ data }) {
    return (
        <div>
            {data?.assessmentDetails?.length ?
                data?.assessmentDetails?.map((assessmentData, assesQuestionIndex) => {
                    return (
                        <div key={assessmentData._id}>
                            <Stack direction="horizontal" gap={4} className="py-2">
                                <h6>  {assesQuestionIndex + 1 + "."} {assessmentData.assessmentQuestion}</h6>
                                <div className="bg-light fz-12px fw-500 px-3 ms-auto py-1 rounded-1">{assessmentData?.point} Point</div>
                            </Stack>
                            {
                                assessmentData.assessmentChoice.map((assessmentOptions, assessmentOptionKey) => {
                                    return (
                                        <div className='py-2' key={assessmentOptionKey + assessmentOptions}>
                                            <p><OutlinePointIcon width={13} /> {assessmentOptions}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
                : <span className='  fz-15px text-muted fw-500 mt-5'>No data available</span>}
        </div>
    )
}

export default AssessmentQA