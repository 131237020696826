import { API } from 'constants/apiEndPoints'
import { Link, useNavigate } from 'react-router-dom'
import AssessmentOutlineIcon from 'svgIcons/AssessmentOutlineIcon'
import CoinIcon from 'svgIcons/CoinIcon'
import DashboardIcon from 'svgIcons/DashboardIcon'
import GroupIcon from 'svgIcons/GroupIcon'
import LogoIcon from 'svgIcons/LogoIcon'
import ManageCategoryIcon from 'svgIcons/ManageCategoryIcon'
import OnlineEducationIcon from 'svgIcons/OnlineEducationIcon'
import TeacherIcon from 'svgIcons/TeacherIcon'
import TicketManagementIcon from 'svgIcons/TicketManagementIcon'
import { confirmLogOut } from 'utils/confirmAlert'
import axiosFetch from '../axios/AxiosInterceptors'
import sidebarAndDashboardStyles from '../moduleCss/sidebarAndDashboard.module.css'
function SidebarLinks() {
    const navigate = useNavigate();

    const logoutFromDb = async () => {

        try {
            const jsonData = {}
            const resp = await axiosFetch.post(API.LOGOUT, jsonData);
            localStorage.clear();
            navigate("/login")
        } catch (error) {

        }

    }
    const onLogout = async () => {

        const isTrue = await confirmLogOut({ title: "Are you sure you want to logout?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            logoutFromDb();
        }
    }
    return (
        <>
            <div className="py-3"></div>
            <Link to="/"><LogoIcon /></Link>
            <div className="py-2"></div>
            <ul className={`list-unstyled ${sidebarAndDashboardStyles.sidebar}`}>
                <li>
                    <Link to="/" ><DashboardIcon width={23} height={23} color={"#395061"} /> <span>Dashboard</span></Link>
                </li>
                <li>
                    <Link to="/teachers"  >
                        <TeacherIcon width={23} height={23} color={"#395061"} />
                        <span> Instructors</span>
                    </Link>
                </li>
                <li>
                    <Link to="/learners"  >
                        <GroupIcon width={23} height={23} color={"#395061"} />
                        <span> Students</span>
                    </Link>
                </li>
                <li>
                    <Link to="/all-courses">
                        <OnlineEducationIcon width={23} height={23} color={"#395061"} />
                        <span> All Courses</span>
                    </Link>
                </li>
                <li>
                    <Link to="/independent-assessment">
                        <AssessmentOutlineIcon width={25} height={25} color={"#395061"} />
                        <span> Assessment</span>
                    </Link>
                </li>
                <li>
                    <Link to="/manage-categories">
                        <ManageCategoryIcon width={23} height={23} color={"#395061"} />

                        <span> Manage Categories</span>
                    </Link>
                </li>
                <li>
                    <Link to="/manage-fees">
                        <CoinIcon color="#395061" width={23} />
                        <span> Manage Fees</span>
                    </Link>
                </li>

                <li>
                    <Link to="/manage-discount">
                        {/* <TicketManagementIcon width={23} height={23} color={"#395061"} /> */}
                        <span className='fz-17px fw-600'>%</span>
                        <span> Manage Discounts</span>
                    </Link>
                </li>
                <li>
                    <Link to="/manage-withdraw">
                        {/* <TicketManagementIcon width={23} height={23} color={"#395061"} /> */}
                        <span className='fz-17px fw-500'>₹</span>
                        <span> Manage Withdraw</span>
                    </Link>
                </li>
                <li>
                    <Link to="/ticket-management">
                        <TicketManagementIcon width={23} height={23} color={"#395061"} />
                        <span> Ticket Management</span>
                    </Link>
                </li>

            </ul>
            <div className={sidebarAndDashboardStyles.lastEndContainer}>
                <button className='mt-auto border-0 bg-prime text-white fz-14px py-2 rounded-2 w-100' onClick={onLogout}>Log Out</button>
            </div>
        </>
    )
}

export default SidebarLinks