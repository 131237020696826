import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import { API } from 'constants/apiEndPoints';
import { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import CreateCategoryIcon from 'svgIcons/CreateCategoryIcon';
import DeleteIcon from 'svgIcons/DeleteIcon';
import PencilIcon from 'svgIcons/PencilIcon';
import { confirmDeleteAlert } from 'utils/confirmDeleteAlert';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import axiosFetch from '../../../axios/AxiosInterceptors';
import categoriesStyles from '../../../moduleCss/categoryCard.module.css';
function ManageCategories() {
    const [categoriesList, setCategoriesList] = useState(null)
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const onDelete = async (data) => {
        const isTrue = await confirmDeleteAlert({ title: "Are you sure you want to delete this Category?", cancelText: "Cancel", confirmText: "Delete Category" });
        const user = getLocalStorage("userData")
        if (isTrue.isConfirmed) {
            try {

                const subCategoriesCodes = data.subCategories.map((item) => (
                    item.subCategoryCode
                ))
                const jsonData = { "email": user?.Email, "categoryCode": data?.categoryCode, "subCategoryArr": subCategoriesCodes }
                const resp = await axiosFetch.delete(API.DELETE_CATEGORY_AND_SUB_CATEGORY, { data: jsonData });
                toast.success("Deleted Successfully!")
                fetchCategory();

            } catch (error) {

            }
        }

    }


    useEffect(() => {
        fetchCategory();
    }, [])
    const fetchCategory = async () => {
        try {
            const resp = await axiosFetch(API.GET_MANAGE_CATEGORIES);
            setCategoriesList(resp.data)

        } catch (error) {

        }
    }

    const onEdit = (data) => {
        setLocalStorage({ key: "onEditCategoryCard", "value": data })
    }
    useEffect(() => {
        if (categoriesList) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = categoriesList.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(categoriesList.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [categoriesList, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % categoriesList.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div className='px-lg-5 px-4 pb-4'>

            <div className="row">
                {/* --- */}
                <div className="col-lg-4 pt-3">
                    <Link to="/manage-categories/create-categories" className='text-decoration-none'>
                        <div className={`${categoriesStyles.card} bgPinkLight300 list-unstyle`}>
                            <div className="row text-center h-100">
                                <CreateCategoryIcon className={'mt-auto'} height={40} />
                                <p className='fw-500 text-muted pt-4'>Create Category</p>
                            </div>
                        </div>
                    </Link>
                </div>
                {
                    !currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                        currentItems.map((item, inx) => (
                            <div className="col-lg-4 pt-3 position-relative" key={inx * 2}>
                                <div className={`${categoriesStyles.card} bgPinkLight300 list-unstyle`} >
                                    <h6>{item?.catName}</h6>
                                    <p className='text-muted fw-500 fz-13px pt-3'>{item?.courseCount} Courses</p>
                                    <p className='text-muted fw-500 fz-14px py-3'> Sub - Category</p>
                                    <ul className={`${categoriesStyles.list} list-unstyled`}>
                                        {item?.subCategories.length ? item?.subCategories?.map((subCatItem, idx) => (

                                            <li className='fw-500 fz-14px text-prime' key={idx + 2}>{subCatItem?.subCategoryName}</li>

                                        )) :
                                            <li className='fw-500 fz-14px text-muted'>No subcategory</li>}
                                    </ul>
                                    <div className="py-3"></div>
                                    <div className={`d-flex ${categoriesStyles.actions}`}>
                                        <Link to="/edit-category" onClick={() => onEdit(item)} className='text-decoration-none'>  <div className='mx-2' role={"button"}>

                                            <PencilIcon width={15} className={categoriesStyles.adjus} />
                                            <span className='mx-2 fw-500 text-prime fz-15px'>Edit</span>
                                        </div></Link>
                                        <div role={"button"} onClick={() => onDelete(item)}>
                                            <DeleteIcon width={15} className={categoriesStyles.adjus} />
                                            <span className='ms-1 fw-500 text-prime fz-15px'>Delete</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : ""
                }


            </div >

            <JsonViewer data={categoriesList} />
            <div className="py-3"></div>
            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> category per page
                </div>
            </div>
        </div>

    )
}

export default ManageCategories