import JsonViewer from 'components/JsonViewer';
import { socket } from 'constants/apiEndPoints';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import ArrowLeft from 'svgIcons/ArrowLeft';
import AttachIcon from 'svgIcons/AttachIcon';
import FileIcon from 'svgIcons/FileIcon';
import RoundOutlineCloseIcon from 'svgIcons/RoundOutlineCloseIcon';
import SendBtnChat from 'svgIcons/SendBtnChat';
import { getLocalStorage } from 'utils/localStorage';
import { cLog } from 'utils/logger';
import chatStyles from '../../../moduleCss/chat.module.css';
const filePreview = { "position": "absolute", "zIndex": "9", "width": "100%", "textAlign": "center", "bottom": "-7px", "background": "rgb(248 248 248)", "height": "120px" }
const closeFile = {
    "position": "absolute",
    left: "5px",
    top: "8px"
}

const aln = { "right": "18px", "position": "absolute", "top": "auto", "bottom": "auto" }
// let socket = io("wss://api.dev.qlearning.academy/ticket");     
function DiscountTicketChat() {
console.log("checking socket ", socket)
    const message = useRef("");
    const [msgList, setMsgList] = useState([])
    const [currentCourseData, setCurrentCourseData] = useState(getLocalStorage("clickedDiscountTicketData"));
    const [localUserData, setLocalUserData] = useState(getLocalStorage("userData"));
    const [file, setFile] = useState({
        fileData: "",
        fileName: ""
    })
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {

        socket.open();

        socket.on("connection-success", async (response) => {
            cLog(`Socket connected ${response.socketId}`);
            cLog(socket.connected);
        });

        socket.emit('join-instructor', { courseCode: currentCourseData?.discountId, userName: "Admin" }, async res => {
            cLog(`join-instructor ${res}`);
        })

        socket.emit("getPreviousMessage", { courseCode: currentCourseData?.courseCode, ticketType: "DISCOUNT" }, (response) => {
            cLog("Previous msg")
            cLog(response)
            setMsgList(chatMessages => ([
                ...response
            ]));
        });
        // ======To reset msg count=========
        socket.emit("resetMessageCount", { courseCode: currentCourseData?.discountId, userType: "ADMIN" }, (response) => {
            console.log("Reset Message Count Ran!", response)
        });
        // ======To reset msg end=========
        socket.on("message", receiveMessages);


        return () => {
            socket.off("message", receiveMessages);
            socket.close();
            // socket.disconnect(true);
            cLog("Socket connected");
            cLog(socket.connected);

        }
    }, [])


    const receiveMessages = useCallback((response) => {
        cLog("recev live chat");
        console.log(response);
        if (response.type == "FILE") {
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: response.userName,
                    time: response.createdAt,
                    message: response.message,
                    type: response.type,
                    fileName: response.fileName,
                }
            ]));
        } else {
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: response.userName,
                    time: response.createdAt,
                    message: response.message
                }
            ]));
        }
    }, [])
    // For text msg
    const sendMsg = (e) => {

        e.preventDefault()
        if (message.current.value) {
            socket.emit("sendMessage", {
                message: message.current.value,
                courseCode: currentCourseData?.discountId,
                roomName: currentCourseData?.discountId,
                userName: "Admin",
                userId: currentCourseData?.userId,
                ticketType: "DISCOUNT",
                type: "TEXT"
            }, () => {
                cLog("sendMessage callback!")
                setMsgList(chatMessages => ([
                    ...chatMessages,
                    {
                        userName: "Admin",
                        dt: moment().format(),
                        message: message.current.value
                    }
                ]));
                setTimeout(() => {
                    message.current.value = '';
                }, 500)

            })

        }

    }
    // For file upload
    const sendFiles = (e) => {

        if (e.target.files) {
            let files = e.target.files;
            setFile({
                fileData: files[0],
                fileName: files[0].name
            });
        }
    }
    const onConfrimUpload = () => {
        setLoading(true)
        socket.emit("upload", {
            file: file?.fileData,
            fileName: file?.fileName,
            courseCode: currentCourseData?.discountId,
            roomName: currentCourseData?.discountId,
            userName: "Admin",
            userId: currentCourseData?.userId,
            ticketType: "DISCOUNT",
            type: "FILE"
        }, (res) => {
            cLog(res);
            setMsgList(chatMessages => ([
                ...chatMessages,
                {
                    userName: "Admin",
                    dt: moment().format(),
                    type: "FILE",
                    fileName: res.fileName,
                    message: res.message
                }
            ]));
            setLoading(false)
            setFile({
                fileData: "",
                fileName: ""
            });
        });

    }
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [msgList]);

    const onBack = () => {
        navigate(-1)
    }
    const onCancelUpload = () => {
        setFile({
            fileData: "",
            fileName: ""
        });
    }
    return (
        <div className="px-4">
            <div className="bg-white rounded px-5 py-5 mb-4">
                <div className="px-lg-5  theme-tab">


                    <span role={"button"} onClick={onBack}><ArrowLeft width={18} /></span>
                    {msgList.map((val, index) => (
                        <div key={index} className="py-2" ref={messagesEndRef} >

                            <p className={`${val?.userName == "Admin" ? "text-end" : "text-start"} m-0 p-0 fw-600 fz-14px`}>
                                <span className='text-muted fz-10px fw-400 '>{moment(val?.dt).calendar()}</span> {val?.userName == "Admin" ? "Me" : val?.userName}
                            </p>
                            {val?.type == "FILE" ?
                                <div className={`${val?.userName == "Admin" ? "text-end" : "text-start"}  d-block `}>
                                    <a className='text-decoration-none text-prime bgPinkLight my-2  fw-500 text-prime  fz-12px  rounded d-inline-block py-2 px-3' href={val?.message} title={val?.message?.split("/").pop()}>
                                        {val?.message?.split("/").pop().substr(0, 30)}...
                                        <img src="https://cdn-icons-png.flaticon.com/512/2820/2820279.png" className='ms-2' style={{ height: "18px", width: "18px", objectFit: "contain" }} />
                                    </a>
                                </div> :
                                <p className={`${val?.userName == "Admin" ? "text-end" : "text-start"} m-0 p-0 fw-500 fz-15px`}> {val?.message}</p>
                            }
                        </div>
                    ))}
                    <div className="py-3"></div>
                    <div className="col-12 position-relative">
                        <div className={chatStyles.attach}>
                            <input className='d-none' id="attachFile" type="file" accept="image/gif, image/jpeg, image/png"
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                                onChange={event => {
                                    sendFiles(event);
                                }} />
                            <label htmlFor="attachFile" role="button"><AttachIcon /></label>
                        </div>
                        {file?.fileName ? <div style={filePreview} className="p-3">
                            <FileIcon />
                            <div className='d-flex justify-content-center align-items-center'>
                                <p className='fz-13px m-0'>{file?.fileName}</p>
                                <button className='bg-transparent border-0' disabled={loading ? true : false} style={closeFile} onClick={onCancelUpload}>
                                    <RoundOutlineCloseIcon />
                                </button>

                                <button className='bg-transparent border-0' style={aln} onClick={onConfrimUpload} role="button" disabled={loading ? true : false} >

                                    {loading ? <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="visually-hidden">Loading...</span></> : <SendBtnChat />}
                                </button>

                            </div>
                        </div> : ""}
                        <form onSubmit={sendMsg}  >
                            <input placeholder='write a message...' className={`${chatStyles.msgPX} fz-14px text-prime border-0 box-sd rounded-2 w-100`} style={{ outline: "none" }} type={"text"} ref={message} />
                            <button className='border-0 align-arrow  fz-18px bg-transparent text-prime' type='submit'>
                                <div className={chatStyles.send}>
                                    <SendBtnChat />
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <JsonViewer data={currentCourseData} />

        </div>
    )
}

export default DiscountTicketChat
