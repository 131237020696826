import React from 'react'

function TeacherIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.8 14.15C4.19165 14.15 3.59697 13.9696 3.09116 13.6316C2.58535 13.2936 2.19115 12.8131 1.9584 12.2511C1.72565 11.689 1.66481 11.0706 1.78358 10.4739C1.90235 9.87728 2.19539 9.32926 2.62564 8.89918C3.05589 8.4691 3.60403 8.17627 4.20071 8.05773C4.7974 7.93919 5.41584 8.00027 5.97781 8.23324C6.53978 8.46621 7.02004 8.86061 7.35785 9.36654C7.69566 9.87248 7.87583 10.4672 7.8756 11.0756C7.87464 11.8909 7.55026 12.6725 6.97365 13.2489C6.39703 13.8253 5.61529 14.1493 4.8 14.15ZM4.8 9.19998C4.42904 9.19998 4.06641 9.30998 3.75797 9.51608C3.44953 9.72217 3.20913 10.0151 3.06717 10.3578C2.92521 10.7005 2.88807 11.0777 2.96044 11.4415C3.03281 11.8053 3.21144 12.1395 3.47375 12.4018C3.73605 12.6641 4.07025 12.8428 4.43409 12.9151C4.79792 12.9875 5.17504 12.9504 5.51776 12.8084C5.86048 12.6665 6.15341 12.4261 6.3595 12.1176C6.5656 11.8092 6.6756 11.4465 6.6756 11.0756C6.67528 10.5782 6.47757 10.1014 6.1259 9.74968C5.77422 9.39801 5.29734 9.2003 4.8 9.19998Z" />
            <path d="M8.40001 21.2C8.24088 21.2 8.08826 21.1367 7.97574 21.0242C7.86322 20.9117 7.80001 20.7591 7.80001 20.6V19.4C7.80001 18.6043 7.48394 17.8412 6.92133 17.2786C6.35872 16.716 5.59566 16.4 4.80001 16.4C4.00436 16.4 3.24129 16.716 2.67869 17.2786C2.11608 17.8412 1.80001 18.6043 1.80001 19.4V20.6C1.80001 20.7591 1.73679 20.9117 1.62427 21.0242C1.51175 21.1367 1.35914 21.2 1.20001 21.2C1.04088 21.2 0.888264 21.1367 0.775742 21.0242C0.66322 20.9117 0.600006 20.7591 0.600006 20.6V19.4C0.600006 18.286 1.04251 17.2178 1.83016 16.4301C2.61781 15.6424 3.6861 15.2 4.80001 15.2C5.91392 15.2 6.9822 15.6424 7.76985 16.4301C8.55751 17.2178 9.00001 18.286 9.00001 19.4V20.6C9.00001 20.7591 8.93679 20.9117 8.82427 21.0242C8.71175 21.1367 8.55914 21.2 8.40001 21.2Z" />
            <path d="M8.39999 20.432C8.28587 20.4319 8.17413 20.3993 8.0779 20.3379C7.98166 20.2766 7.90492 20.1891 7.85667 20.0856C7.80842 19.9822 7.79067 19.8672 7.8055 19.754C7.82033 19.6409 7.86712 19.5343 7.94039 19.4468L16.3404 9.41478C16.4443 9.29879 16.5892 9.22773 16.7445 9.21662C16.8998 9.20551 17.0534 9.25522 17.1727 9.35524C17.2921 9.45527 17.3679 9.59777 17.3841 9.75264C17.4003 9.90751 17.3556 10.0626 17.2596 10.1852L8.85959 20.2172C8.80332 20.2844 8.73302 20.3384 8.65361 20.3755C8.57421 20.4127 8.48764 20.4319 8.39999 20.432Z" />
            <path d="M3.6 6.80005C3.44087 6.80005 3.28826 6.73683 3.17574 6.62431C3.06321 6.51179 3 6.35918 3 6.20005V1.40005C3 1.24092 3.06321 1.08831 3.17574 0.975785C3.28826 0.863263 3.44087 0.800049 3.6 0.800049C3.75913 0.800049 3.91174 0.863263 4.02426 0.975785C4.13678 1.08831 4.2 1.24092 4.2 1.40005V6.20005C4.2 6.35918 4.13678 6.51179 4.02426 6.62431C3.91174 6.73683 3.75913 6.80005 3.6 6.80005Z" />
            <path d="M22.8 2.00005H2.40002C2.24089 2.00005 2.08828 1.93683 1.97575 1.82431C1.86323 1.71179 1.80002 1.55918 1.80002 1.40005C1.80002 1.24092 1.86323 1.08831 1.97575 0.975785C2.08828 0.863263 2.24089 0.800049 2.40002 0.800049H22.8C22.9591 0.800049 23.1118 0.863263 23.2243 0.975785C23.3368 1.08831 23.4 1.24092 23.4 1.40005C23.4 1.55918 23.3368 1.71179 23.2243 1.82431C23.1118 1.93683 22.9591 2.00005 22.8 2.00005Z" />
            <path d="M21.6 15.2001C21.4409 15.2001 21.2883 15.1368 21.1757 15.0243C21.0632 14.9118 21 14.7592 21 14.6001V1.40005C21 1.24092 21.0632 1.08831 21.1757 0.975785C21.2883 0.863263 21.4409 0.800049 21.6 0.800049C21.7591 0.800049 21.9117 0.863263 22.0243 0.975785C22.1368 1.08831 22.2 1.24092 22.2 1.40005V14.6001C22.2 14.7592 22.1368 14.9118 22.0243 15.0243C21.9117 15.1368 21.7591 15.2001 21.6 15.2001Z" />
            <path d="M22.8 15.2H15.6C15.4409 15.2 15.2883 15.1368 15.1757 15.0243C15.0632 14.9118 15 14.7591 15 14.6C15 14.4409 15.0632 14.2883 15.1757 14.1757C15.2883 14.0632 15.4409 14 15.6 14H22.8C22.9591 14 23.1117 14.0632 23.2243 14.1757C23.3368 14.2883 23.4 14.4409 23.4 14.6C23.4 14.7591 23.3368 14.9118 23.2243 15.0243C23.1117 15.1368 22.9591 15.2 22.8 15.2Z" />
            <path d="M18 5.60015H10.8C10.6409 5.60015 10.4883 5.53693 10.3757 5.42441C10.2632 5.31189 10.2 5.15928 10.2 5.00015C10.2 4.84102 10.2632 4.6884 10.3757 4.57588C10.4883 4.46336 10.6409 4.40015 10.8 4.40015H18C18.1591 4.40015 18.3117 4.46336 18.4243 4.57588C18.5368 4.6884 18.6 4.84102 18.6 5.00015C18.6 5.15928 18.5368 5.31189 18.4243 5.42441C18.3117 5.53693 18.1591 5.60015 18 5.60015Z" />
            <path d="M18 8.00005H8.40002C8.24089 8.00005 8.08828 7.93684 7.97575 7.82431C7.86323 7.71179 7.80002 7.55918 7.80002 7.40005C7.80002 7.24092 7.86323 7.08831 7.97575 6.97578C8.08828 6.86326 8.24089 6.80005 8.40002 6.80005H18C18.1591 6.80005 18.3118 6.86326 18.4243 6.97578C18.5368 7.08831 18.6 7.24092 18.6 7.40005C18.6 7.55918 18.5368 7.71179 18.4243 7.82431C18.3118 7.93684 18.1591 8.00005 18 8.00005Z" />
        </svg>

    )
}

export default TeacherIcon