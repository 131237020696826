import axiosFetch from '../../../axios/AxiosInterceptors';
import React from 'react'
import { Link } from 'react-router-dom';
import IndependentAssessmentIcon from 'svgIcons/IndependentAssessmentIcon'
import cardStyle from "../../../moduleCss/cards.module.css";
import { API } from 'constants/apiEndPoints';
import { useEffect } from 'react';
import { useState } from 'react';
import JsonViewer from 'components/JsonViewer';
import Loader from 'components/Loader';
import QAPaperIcon from 'svgIcons/QAPaperIcon';
import Paginate from 'components/Paginate';
import { confirmAlert } from 'utils/confirmAlert';
import { toast } from 'react-hot-toast';
function ActivatedAssessment({ onSearch }) {
    const [inReviewAssessmentData, setInReviewAssessmentData] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(null);
    const [reason, setReason] = useState("");
    const [restoreList, setRestoreList] = useState(null);
    console.log("aaaaaa",currentItems)
    useEffect(() => {
        getActivatedAssessment();
    }, [])
    const getActivatedAssessment = async () => {
        try {
            const resp = await axiosFetch(API.GET_ALL_ACTIVATED_ASSESSMENT);
            const sortedData = resp.data.sort((a, b) => new Date(b.updatedTime) - new Date(a.updatedTime));
            setRestoreList(sortedData)
            setInReviewAssessmentData(sortedData)
        } catch (error) {

        }
    }
    //after fetch
    useEffect(() => {
        if (inReviewAssessmentData) {
            const endOffset = itemOffset + Number(itemsPerPage);
            let sliceItem = inReviewAssessmentData.slice(itemOffset, endOffset);
            setCurrentItems(sliceItem)
            let pageCountResult = Math.ceil(inReviewAssessmentData.length / itemsPerPage);
            setPageCount(pageCountResult)
        }
    }, [inReviewAssessmentData?.length, itemOffset, itemsPerPage])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % inReviewAssessmentData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    //search effect
    useEffect(() => {
        if (onSearch && restoreList) {
            const dataArry = restoreList
            const filteredData = dataArry.filter((item) =>
                item.assessmentTitle.toLowerCase().includes(onSearch.toLowerCase())
            );
            setInReviewAssessmentData(filteredData)
        } else {
            setInReviewAssessmentData(restoreList)
        }
    }, [onSearch])
    const onReject = async (data) => {

        const isTrue = await confirmAlert({ title: "Are you sure you want to deactivate this assessment?", cancelText: "Cancel", confirmText: "Deactivate" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    "userType": "ADMIN",
                    deactivateReason: reason
                }
                const resp = await axiosFetch.post(API.DEACTIVATE_IND_ASSESSMENT + `?assessmentCode=${data.assessmentCode}`, jsonData);
                getActivatedAssessment()
                toast.success('Assessment deactivated successfully');


            } catch (error) {

            }

        }
    }
    return (
        <div>

            {!currentItems ? <Loader className="text-prime my-3" center={true} /> : currentItems.length ?
                currentItems.map((inAssessData, index, array) => (

                    <div className={`${cardStyle.crdHover} bg-white box-sd p-4 rounded-3 mb-4`} key={inAssessData?._id}>
                        <div className="row g-1">
                            <div className="col-lg-2 text-center my-auto">
                                <IndependentAssessmentIcon />
                            </div>
                            <div className="col-lg-8 my-auto">
                                <h6 className='pb-2'>{inAssessData?.assessmentTitle}</h6>
                                <p><span className='fz-14px fw-500 text-muted'>By</span> <span className='fz-14px fw-600 text-prime'>{inAssessData?.instructorName}</span></p>
                                <h6 className='pb-2'>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: inAssessData?.currency, minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(inAssessData?.fee)} </h6>
                                <h6 className={inAssessData?.assessmentDetails?.length == 0 ? "text-danger" : ""}><QAPaperIcon /> {inAssessData?.assessmentDetails?.length > 1 ? inAssessData?.assessmentDetails?.length + " Questions" : inAssessData?.assessmentDetails?.length + " Question"} </h6>

                            </div>
                            <div className="col-lg-2 m-auto">
                                <textarea className='input mb-5 p-2 fz-13px w-100' placeholder='Deactivate reason' onChange={(e) => setReason(e.target.value)} />

                                <div onClick={() => onReject(inAssessData)}><button className={`textTomato bgTomatoLight border-0 w-100 py-2 rounded fz-14px fw-500`}>Deactivate</button></div>
                            </div>
                        </div>
                    </div>
                )) : <h6 className='text-muted'>No data available </h6>}

            <div className="row">
                <div className="col-lg-8">
                    <Paginate onPageChange={handlePageClick} pageCount={pageCount} isShow={currentItems?.length} />
                </div>
                <div className="col-lg-4 fz-13px fw-500 text-muted text-end">
                    Show  <select className='px-2 py-1 ' onChange={(e) => setItemsPerPage(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select> assessments per page
                </div>
            </div>
        </div>
    )
}

export default ActivatedAssessment