import axiosFetch from '../../../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import VideoPlayer from 'components/VideoPlayer';
import { API } from 'constants/apiEndPoints';
import { Stack } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'svgIcons/ArrowLeft';
import { confirmAlert } from 'utils/confirmAlert';
import { getLocalStorage } from 'utils/localStorage';
import { cAlert, cLog } from 'utils/logger';
import lessonStyles from '../../../../../moduleCss/chapter.module.css';
import RejectApproveHeader from './RejectApproveHeader';
import MyS3Component from 'components/S3';

function ClickedLesson() {
    const clickedLsn = getLocalStorage("uploadedVideoClickedLesson")
    const clickedChapter = getLocalStorage("uploadedVideoClickedChapter");
    const clickedCourse = getLocalStorage("clickedCourse");


    const navigate = useNavigate();
    // const params = "active=uploadTab";
    const back = () => {
        navigate(-1)
    }
    const onReject = async () => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to reject this lesson?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            try {

                const jsonData = {
                    courseCode: clickedCourse?.courseCode,
                    chapterOrder: clickedChapter?.chapterOrder,
                    lessonOrder: clickedLsn?.lessonOrder
                }
                const resp = await axiosFetch.post(API.REJECT_LESSON, jsonData);
                toast.success('Lesson Rejected Successfully!');
                setTimeout(() => {
                    back()
                }, 1500);

            } catch (error) {

            }

        }
    }

    const onApprove = async () => {
        try {
            const jsonData = {
                courseCode: clickedCourse?.courseCode,
                chapterOrder: clickedChapter?.chapterOrder,
                lessonOrder: clickedLsn?.lessonOrder
            }
            const resp = await axiosFetch.post(API.APPROVE_LESSON, jsonData);
            toast.success('Lesson Approved Successfully!');
            setTimeout(() => {
                back()
            }, 1500);
        } catch (error) {

        }

    }

    return (
      <div className="px-4">
        <RejectApproveHeader />
        <div className="py-3"></div>
        <div className="bg-white rounded-2 py-4 px-5 mb-4">
          <JsonViewer data={clickedLsn} />
          <Stack direction="horizontal" gap={4}>
            <h6
              className="text-dark d-inline-block"
              role={"button"}
              onClick={back}
            >
              <ArrowLeft width={17} className={lessonStyles.adjusArrowLeft} />{" "}
              {clickedLsn?.lessonName}
            </h6>
            {/* <div className="textTomato fz-14px fw-500 ms-auto" onClick={onReject} role={"button"}>Reject</div> */}
            {clickedLsn.lessonStatus != "APPROVED" && (
              <div
                className={`textTomato fz-14px fw-500 ms-auto`}
                role="button"
                onClick={onReject}
                style={{
                  opacity: 1,
                  pointerEvents: "auto",
                }}
              >
                Reject
              </div>
            )}
            {/* <div className="bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1" role={"button"} onClick={onApprove}>Approve</div> */}
            {clickedLsn.lessonStatus != "APPROVED" && (
              <div
                className={`bgGreenLight textGreen fz-14px fw-500 px-5 py-2 rounded-1`}
                role="button"
                onClick={onApprove}
                style={{
                  opacity: 1,
                  pointerEvents: "auto",
                }}
              >
                Approve
              </div>
            )}
            {/* <div className="bgTomatoLight textTomato fz-14px fw-500 px-4 py-2 rounded-1" onClick={onDeactivate}>Deactive This Assessments</div> */}
          </Stack>

          <div className="py-3"></div>
          <MyS3Component
            src={clickedLsn?.lessonVideoPath}
            isVideoPlayer={true}
          />
        </div>
      </div>
    );
}

export default ClickedLesson