import axiosFetch from '../../../axios/AxiosInterceptors';
import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import React, { useState } from 'react'
import { useEffect } from 'react'
import teacherStyles from "../../../moduleCss/teacher.module.css"
import Loader from 'components/Loader';
import { toast, Toaster } from 'react-hot-toast';
import { confirmAlert } from 'utils/confirmAlert';


function PriceTable({ onEdit, getAllPriceTableData, tableData }) {
    useEffect(() => {
        getAllPriceTableData();
    }, [])

    const onDelete = async (data) => {

        const isTrue = await confirmAlert({ title: "Are you sure want to delete this row?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            try {
                const resp = await axiosFetch.delete(API.DELETE_PAYMENT_FEES_DATA + `?_id=${data?._id}`);
                const getUpdatedData = await getAllPriceTableData();
                toast.success('Deleted Successfully!');
            } catch (error) {

            }
        }
    }

    return (<>
        <Toaster position="top-right"
            toastOptions={{
                className: 'fz-13px fw-500'
            }} />
        <div className="table-responsive">

            <table className="table table-borderless align-middle">
                <thead>
                    <tr>
                        <th className='fw-500 text-muted fz-15px'>Category</th>
                        <th className='fw-500 text-muted fz-15px'>Sub- Category</th>
                        <th className='fw-500 text-muted fz-15px'>Videos Count</th>
                        <th className='fw-500 text-muted fz-15px'>Price</th>
                        <th className='fw-500 text-muted fz-15px'>Action</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        !tableData ? <tr className='text-center'>
                            <td colSpan={"5"} className="py-5 fw-500 text-muted fz-15px">Please wait...</td>
                        </tr> : tableData?.length ?

                            tableData?.map((tData, i) => (
                                <tr key={tData?._id}>
                                    <td>{tData?.category}</td>
                                    <td>{tData?.subCategory}</td>
                                    <td>{tData?.videoCount}</td>
                                    <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: tData?.currency, minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(tData?.price)}</td>
                                    <td className={teacherStyles.w10}><span className={`${teacherStyles.bgGreen} ${teacherStyles.status} text-white`} onClick={() => onEdit(tData)}>Edit</span></td>
                                    <td className={teacherStyles.w20}><span onClick={() => onDelete(tData)} className={`${teacherStyles.bgTomato} ${teacherStyles.status} text-white`}>Delete</span></td>

                                </tr>
                            ))

                            :
                            <tr className='text-center'>
                                <td colSpan={"5"} className="py-4 fw-500 text-muted fz-15px">No data</td>
                            </tr>
                    }



                </tbody>
            </table>
            <JsonViewer data={tableData} />
        </div>
    </>
    )
}

export default PriceTable