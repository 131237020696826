import React from 'react'
import StarIcon from 'svgIcons/StarIcon'

function Rating({ ratingCount }) {

    if (ratingCount == 0) {
        return (
            <>
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
            </>
        )
    }

    if (ratingCount == 1) {
        return (
            <>
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
            </>
        )
    }

    if (ratingCount == 2) {
        return (
            <>
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
            </>
        )
    }

    if (ratingCount == 3) {
        return (
            <>
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
            </>
        )
    }

    if (ratingCount == 4) {
        return (
            <>
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#c1c1c1"} width={"15"} />
            </>
        )
    }

    if (ratingCount == 5) {
        return (
            <>
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
                <StarIcon color={"#F7AC16"} width={"15"} />
            </>
        )
    }
}

export default Rating