import React from 'react'

function TicketManagementIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} fill={color} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1024_4633)">
                <path d="M22.3958 22.9166H2.60417C1.16875 22.9166 0 21.7478 0 20.3124V18.2291C0 17.9416 0.233333 17.7083 0.520833 17.7083H1.04167C2.19062 17.7083 3.125 16.7739 3.125 15.6249C3.125 14.476 2.19062 13.5416 1.04167 13.5416H0.520833C0.233333 13.5416 0 13.3083 0 13.0208V10.9374C0 9.502 1.16875 8.33325 2.60417 8.33325H22.3958C23.8312 8.33325 25 9.502 25 10.9374V13.0208C25 13.3083 24.7667 13.5416 24.4792 13.5416H23.9583C22.8094 13.5416 21.875 14.476 21.875 15.6249C21.875 16.1812 22.0917 16.7041 22.4854 17.0978C22.8781 17.4916 23.401 17.7083 23.9573 17.7083H24.4781C24.7656 17.7083 24.999 17.9416 24.999 18.2291V20.3124C25 21.7478 23.8312 22.9166 22.3958 22.9166ZM1.04167 18.7499V20.3124C1.04167 21.1739 1.74271 21.8749 2.60417 21.8749H22.3958C23.2573 21.8749 23.9583 21.1739 23.9583 20.3124V18.7499H23.9573C23.1229 18.7499 22.3385 18.4249 21.749 17.8353C21.1583 17.2447 20.8333 16.4593 20.8333 15.6249C20.8333 13.902 22.2354 12.4999 23.9583 12.4999V10.9374C23.9583 10.076 23.2573 9.37492 22.3958 9.37492H2.60417C1.74271 9.37492 1.04167 10.076 1.04167 10.9374V12.4999C2.76458 12.4999 4.16667 13.902 4.16667 15.6249C4.16667 17.3478 2.76458 18.7499 1.04167 18.7499Z" />
                <path d="M8.85418 10.4166C8.56668 10.4166 8.33334 10.1832 8.33334 9.89575V8.85408C8.33334 8.56658 8.56668 8.33325 8.85418 8.33325C9.14168 8.33325 9.37501 8.56658 9.37501 8.85408V9.89575C9.37501 10.1832 9.14168 10.4166 8.85418 10.4166Z" />
                <path d="M8.85418 19.2302C8.56668 19.2302 8.33334 18.9969 8.33334 18.7094V16.9479C8.33334 16.6604 8.56668 16.4271 8.85418 16.4271C9.14168 16.4271 9.37501 16.6594 9.37501 16.9479V18.7104C9.37501 18.9979 9.14168 19.2302 8.85418 19.2302ZM8.85418 14.8239C8.56668 14.8239 8.33334 14.5906 8.33334 14.3031V12.5406C8.33334 12.2531 8.56668 12.0198 8.85418 12.0198C9.14168 12.0198 9.37501 12.2531 9.37501 12.5406V14.3031C9.37501 14.5906 9.14168 14.8239 8.85418 14.8239Z" />
                <path d="M8.85418 22.9166C8.56668 22.9166 8.33334 22.6833 8.33334 22.3958V21.3541C8.33334 21.0666 8.56668 20.8333 8.85418 20.8333C9.14168 20.8333 9.37501 21.0666 9.37501 21.3541V22.3958C9.37501 22.6833 9.14168 22.9166 8.85418 22.9166Z" />
                <path d="M2.60416 9.37504C2.39271 9.37504 2.19375 9.24587 2.11666 9.0365C2.01562 8.76671 2.15208 8.4667 2.42187 8.3667L18.9844 2.18234C19.7896 1.88025 20.7354 2.33234 21.0104 3.14379L22.8885 8.6865C22.9802 8.95941 22.8344 9.2542 22.5625 9.34691C22.2927 9.43962 21.9948 9.29275 21.9021 9.02087L20.024 3.47817C19.9323 3.20733 19.6177 3.05942 19.3479 3.15733L2.78541 9.3417C2.72604 9.36462 2.66458 9.37504 2.60416 9.37504Z" />
            </g>
            <defs>
                <clipPath id="clip0_1024_4633">
                    <rect width="25" height="25" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TicketManagementIcon