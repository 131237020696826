import JsonViewer from 'components/JsonViewer';
import { API } from 'constants/apiEndPoints';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from 'svgIcons/DeleteIcon';
import PencilIcon from 'svgIcons/PencilIcon';
import { confirmAlert } from 'utils/confirmAlert';
import { getLocalStorage } from 'utils/localStorage';
import { cLog } from 'utils/logger';
import axiosFetch from '../../../axios/AxiosInterceptors';
import categoriesStyles from '../../../moduleCss/categoryCard.module.css';

function EditCategory() {
    const [subCatList, setSubCatList] = useState([{ "subCategoryCode": null, "subCategoryName": "" }]);
    const [subCat, setSubCat] = useState({ "subCategoryCode": null, "subCategoryName": "" });
    const [isDone, setIsDone] = useState(false);
    const [isSave, setIsSave] = useState(true);
    const [category, setCategory] = useState("");
    const [onEditData, setOnEditData] = useState({ "data": "", "index": "" });
    const [deletedSubcategoryCode, setDeletedSubcategoryCode] = useState([])
    const preValues = getLocalStorage("onEditCategoryCard")

    useEffect(() => {
        setCategory(preValues?.catName)
        setSubCatList(preValues?.subCategories)
    }, [])
    const navigate = useNavigate()
    const onDone = (e) => {
        e.preventDefault()
        const isDuplicate = subCatList.some((items) => {

            return items?.subCategoryName?.toLocaleLowerCase().trim() == subCat?.toLocaleLowerCase().trim();
        });

        if (isDuplicate) {
            toast.error("Entered subCategory was already exists!")
        } else {
            // ---
            if (onEditData.data) {
                subCatList[onEditData.index].subCategoryName = subCat;
                setOnEditData({ "data": "", "index": "" })
                setSubCat("")
                setIsSave(true)
                setIsDone(false)
            } else {
                setSubCatList(prevState => [...prevState, { "subCategoryCode": null, "subCategoryName": subCat }])
                setSubCat("")
                setIsSave(true)
                setIsDone(false)
            }
            // ----

        }

    }
    const onSaveChanges = async (e) => {
        e.preventDefault();
        const user = getLocalStorage("userData")
        try {

            const jsonData = { "email": user?.Email, "categoryCode": preValues?.categoryCode, "oldCategoryName": preValues?.catName, "newCategoryName": category, "subCategoryEditArr": subCatList, "deleteSubCategoryArr": deletedSubcategoryCode }
            const resp = await axiosFetch.put(API.EDIT_CATEGORY_SUB_CATEGORY, jsonData);
            toast.success("Updated Successfully!")
            setTimeout(() => {
                navigate('/manage-categories');
            }, 1000);

        } catch (error) {

        }
    }
    const handleInputChange = (e) => {
        setSubCat(e.target.value)
    }
    const onCreate = () => {
        setIsSave(false)
        setIsDone(true)
    }
    const onEdit = (data, i) => {
        setIsDone(true)
        setOnEditData({ "data": data?.subCategoryName, "index": i })
        setSubCat({ "subCategoryCode": null, subCategoryName: data?.subCategoryName })
    }
    const onDelete = async (idx) => {
        const isTrue = await confirmAlert({ title: "Are you sure you want to delete?", cancelText: "No", confirmText: "Yes" });
        if (isTrue.isConfirmed) {
            // cLog(subCatList[idx].subCategoryCode)
            setDeletedSubcategoryCode([...deletedSubcategoryCode, subCatList[idx].subCategoryCode])
            const list = [...subCatList];
            list.splice(idx, 1);
            setSubCatList(list);
        }
    }
    return (
        <div className='px-4'>
            <div className="bg-white p-5 rounded-3">
                <Toaster position="top-right"
                    toastOptions={{
                        className: 'fz-13px fw-500'
                    }} />

                <p className='text-muted fz-14px fw-500'>Category Name</p>
                <form onSubmit={onSaveChanges} id={"onSaveChanges"}>
                    <input type={"text"} onInput={(e) => {
                        if (e.target.value.trim() == "") {
                            e.target.value = "";
                        }
                    }} className="input py-10px w-100 my-3 fw-400" value={category} onChange={(e) => setCategory(e.target.value)} required />
                </form>
                <p className='text-muted fz-14px fw-500 mb-3'>Sub - Category Name</p>
                {subCatList.length ? <ul className={`${categoriesStyles.list} list-unstyled mb-1`}>{subCatList.map((item, i) => (
                    <li className='fw-500 fz-15px text-prime pb-2 d-flex  word-break' key={i * 3}>
                        {item?.subCategoryName}
                        <div className={`px-3 w-25 fz-13px d-flex ${categoriesStyles.hidden}`}>
                            {isDone ? "" : <><div className='pe-2' role={"button"} onClick={() => onEdit(item, i)}><PencilIcon className={`${categoriesStyles.topVe2px} position-relative `} width={13} height={13} /> Edit </div>
                                <div role={"button"} onClick={() => onDelete(i)}><DeleteIcon className={`${categoriesStyles.topVe2px} position-relative `} width={14} height={14} /> Delete</div></>}
                        </div>
                    </li>
                ))}</ul> : ""}
                {isDone ?
                    <form onSubmit={onDone}>
                        <input
                            name="subCategory"
                            className='input py-10px w-100 my-2'
                            defaultValue={subCat?.subCategoryName}
                            onChange={handleInputChange}
                            onInput={(e) => {
                                if (e.target.value.trim() == "") {
                                    e.target.value = "";
                                }
                            }}
                            required
                        />
                        <div className="text-end py-2">
                            <button type='button' onClick={() => { if (category) { setIsSave(true) } setSubCat(""); setIsDone(false) }} className='bg-light border-0 rounded-5 fw-500 px-4 py-1 fz-13px mx-1 text-prime'>Cancel</button>
                            <button type='submit' className='bgPinkLight border-0 rounded-5 fw-500 px-4 py-1 fz-13px text-prime'>Done</button>
                        </div>

                    </form>

                    : <div className="text-end py-2">
                        <button type='button' role={"button"} onClick={onCreate} className='bgPinkLight border-0 rounded-5 fw-500 px-4 py-1 fz-13px text-prime' >+ Create New</button>
                    </div>}

                <div className="text-end mt-4">
                    <button disabled={category && subCatList.length && isSave ? false : true} className='border-0 bg-prime text-white fw-500 px-4 py-2 rounded-2 fz-13px' type='submit' form='onSaveChanges'>Save Changes</button>
                </div>
            </div>
            <JsonViewer data={subCatList} />
        </div>
    )
}

export default EditCategory