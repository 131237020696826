import React from 'react'
import { Modal } from 'react-bootstrap'

function BootstrapModal({ isShowModal, setIsModal, title, body }) {
    return (
        <Modal
            show={isShowModal}
            onHide={() => setIsModal(false)}
            dialogClassName={`mW36`}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix="pb-4 pt-3">

                {body}
            </Modal.Body>

        </Modal>
    )
}

export default BootstrapModal